export const status = {
  DISABLED: 0,
  ENABLED: 1,
};

export const CREATE_MODE = "create";
export const EDIT_MODE = "edit";

/** https://developers.facebook.com/docs/sharing/web */
export const FacebookShareType = {
  /** 分享對話方塊，可提供用戶最多彈性。用戶可以選擇分享的目的地，包括社團和 Messenger 的私密訊息 */
  SHARE: "share",
  /** 發送對話方塊 */
  SEND: "send",
  /** 動態對話方塊 */
  FEED: "feed",
};
