<script setup>
import TinymceVue from "@tinymce/tinymce-vue";
import { computed, ref, watch } from "vue";

import * as APIs from "@/APIs";

const VITE_TINYMCE_API_KEY = import.meta.env.VITE_TINYMCE_API_KEY;

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: String,
  readonly: Boolean,
  disabled: Boolean,
});

const emit = defineEmits(["update:modelValue", "onInit"]);

const editorInstance = ref(null);
const loading = ref(true);

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const initOptions = computed(() => ({
  language: "zh_TW",
  placeholder: props.placeholder,
  plugins: [
    "autoresize",
    "code",
    "link",
    "image",
    "lists",
    "table",
    "pagebreak",
    "charmap",
    "emoticons",
    "help",
    "media",
  ],
  toolbar: [
    "undo redo print code | blocks fontfamily fontsize | bold italic underline strikethrough subscript superscript blockquote forecolor backcolor | link unlink openlink image media | alignleft aligncenter alignright alignjustify alignnone | lineheight numlist bullist outdent indent removeformat | pagebreak charmap emoticons",
    "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
  ],
  content_style:
    "body { font-family: Noto Sans TC; } body::before { opacity: 0.5; } img { max-width: 100%; }",
  content_css: [
    "//fonts.googleapis.com/css?family=Noto+Sans+TC:400,700&display=swap&subset=chinese-traditional",
  ],
  min_height: 500,
  max_height: 700,
  elementpath: false,
  branding: false,
  init_instance_callback: (editor) => {
    editorInstance.value = editor;
    loading.value = false;
    if (props.readonly) editor.mode.set("readonly");
    if (props.disabled) editor.mode.set("readonly");
    emit("onInit");
    editor.on("change", () => {
      localValue.value = editor.getContent();
    });
  },
  images_upload_handler: (blobInfo, progress) => {
    let timeout;
    const handleProgress = (p) => {
      clearTimeout(timeout);
      if (p < 99) {
        timeout = setTimeout(() => {
          progress(p);
          handleProgress(p + 3);
        }, 100);
      }
    };
    handleProgress(0);
    return APIs.common
      .uploadImage(blobInfo.blob())
      .then((res) => {
        progress(100);
        return res.result;
      })
      .finally(() => {
        clearTimeout(timeout);
      });
  },
}));

watch(
  () => props.readonly || props.disabled,
  (value) => {
    if (editorInstance.value) {
      if (value) editorInstance.value.mode.set("readonly");
      else editorInstance.value.mode.set("design");
    }
  },
);
</script>

<template>
  <TinymceVue
    v-loading="loading"
    v-model="localValue"
    :init="initOptions"
    :api-key="VITE_TINYMCE_API_KEY"
  />
</template>
