<script setup>
import {
  ElButton,
  ElCard,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElImage,
  ElInput,
  ElInputNumber,
  ElNotification,
  ElOption,
  ElSelect,
} from "element-plus";
import numeral from "numeral";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElImageUpload from "@/components/ElImageUpload.vue";
import ElOrderEditor from "@/components/ElOrderEditor.vue";
import ElStatusSwitchPrompt from "@/components/ElStatusSwitchPrompt.vue";
import TaskPreview from "@/components/Task/TaskPreview.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import EventEmitter from "@/libs/event-emitter";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";
import { useTaskStore } from "@/stores/task";

const props = defineProps({
  id: String,
});

const taskStore = useTaskStore();
const router = useRouter();
const formRef = ref();
const createMode = computed(() => props.id === enums.common.CREATE_MODE);
const loading = ref(false);
const publishStatus = ref(utils.convertTaskPublishStatus(taskStore.task));

const editable = computed(
  () =>
    createMode.value ||
    [enums.task.publishStatus.DRAFT, enums.task.publishStatus.PENDING].includes(
      publishStatus.value,
    ),
);

const brandSelected = ref(
  taskStore.brands.find((item) => item.id === taskStore.task.brand_id),
);
const brandsLoading = ref(false);
const remoteMethod = (query) => {
  if (!query) return;
  brandsLoading.value = true;
  APIs.brand
    .getBrands({
      filters: helpers.convertSearchParams({
        name: `%${query}%`,
        status: enums.common.status.ENABLED,
      }),
    })
    .then((res) => {
      taskStore.brands = res.result;
    })
    .finally(() => {
      brandsLoading.value = false;
    });
};

const formRules = computed(() => ({
  order: [
    { required: true, message: "請輸入排序" },
    { type: "number", message: "排序必須為數字" },
  ],
  name: [
    { required: true, message: "請輸入任務標題" },
    { max: 35, message: "長度不能超過 35 個字" },
  ],
  image: { required: true, message: "請上傳任務圖片" },
  description: { required: true, message: "請輸入任務簡述" },
  type: { required: true, message: "請選擇任務類別" },
  start_at: { required: true, message: "請選擇上架時間" },
  expired_at: [
    {
      required: !isInfiniteExpiredAt.value,
      message: "請選擇下架時間",
    },
    {
      validator: (rule, value, callback) => {
        if (dayjs(value).isBefore(dayjs(taskStore.task.start_at))) {
          callback(new Error("下架時間不能早於上架時間"));
        } else if (
          !createMode.value &&
          dayjs(value).isBefore(dayjs().subtract(1, "second"))
        ) {
          callback(new Error("下架時間不能早於當前時間"));
        } else callback();
      },
    },
  ],
  status: { required: true, message: "請選擇狀態" },
  limit: { required: !taskStore.task.is_unlimit, message: "請輸入總次數限制" },
  exp: { required: true, message: "請輸入培育值" },
  coin: { required: true, message: "請輸入獲取Ｖ幣數量" },
  brand_id: { required: true, message: "請選擇品牌商" },
}));

const isInfiniteExpiredAt = computed({
  get: () => !taskStore.task.expired_at,
  set: (val) => {
    if (val) taskStore.task.expired_at = null;
  },
});

const handleSubmit = () => {
  formRef.value?.validate(async (valid, rules) => {
    if (!valid)
      ElNotification({
        title: "表單驗證錯誤",
        type: "error",
        task: Object.values(rules)[0][0].task,
      });
    else {
      try {
        loading.value = true;

        const data = helpers.dataConverter(
          helpers.pick(taskStore.task, [
            "type",
            "name",
            "description",
            "content",
            "image",
            "coin",
            "exp",
            "limit",
            "is_unlimit",
            "start_at",
            "expired_at",
            "brand_id",
          ]),
          {
            // 開始時間比當前時間早 10 分鐘內 => 調整為現在時間的 10 秒後
            start_at: utils.adjustTimeBetweenNowAnd10MinutesAgo,
            // 結束時間比當前時間早 10 分鐘內 => 調整為現在時間的 20 秒後
            expired_at: (val) =>
              utils.adjustTimeBetweenNowAnd10MinutesAgo(val, 20),
          },
        );

        if (createMode.value)
          await APIs.task.createTask(data).then((res) => {
            router.replace({ params: { id: res.result.id } });
          });
        else
          await APIs.task.updateTask(
            props.id,
            helpers.omit(
              data,
              ["status", "order"].concat(
                Object.entries({
                  start_at: dayjs(data.start_at).isBefore(dayjs()), // 開始時間早於當前時間 => 忽略
                  expired_at: dayjs(data.expired_at).isBefore(dayjs()), // 結束時間早於當前時間 => 忽略
                })
                  .filter(([, value]) => value)
                  .map(([key]) => key),
              ),
            ),
          );

        // 更新 Local 任務狀態
        publishStatus.value = utils.convertTaskPublishStatus(taskStore.task);

        ElNotification({
          title: `${createMode.value ? "新增" : "更新"}成功`,
          type: "success",
        });
      } finally {
        loading.value = false;
      }
    }
  });
};

const navigateToNextPage = () => {
  if (createMode.value) {
    ElNotification({
      title: "請先儲存任務",
      type: "warning",
    });
  } else {
    router.push({
      name: enums.route.names.TASK_TASKABLES,
      params: { id: props.id },
    });
  }
};

EventEmitter.on(enums.emitter.events.TASK_FETCH_SUCCESS, () => {
  brandSelected.value = taskStore.brands.find(
    (item) => item.id === taskStore.task.brand_id,
  );
  publishStatus.value = utils.convertTaskPublishStatus(taskStore.task);
});
</script>

<template>
  <ElCard class="!rounded-xl">
    <ElForm
      v-loading="loading"
      ref="formRef"
      :model="taskStore.task"
      :rules="formRules"
      label-position="top"
      inline
      class="max-w-screen-lg"
    >
      <ElFormItem prop="order" label="排序">
        <ElOrderEditor
          v-model="taskStore.task.order"
          :prompt-mode="!createMode"
          :http-request="(order) => APIs.task.updateTask(id, { order })"
        />
      </ElFormItem>
      <ElFormItem prop="name" label="任務標題" class="w-full">
        <ElInput v-model="taskStore.task.name" :disabled="!editable" />
      </ElFormItem>
      <ElFormItem prop="image" label="任務圖片" class="w-full">
        <ElImageUpload v-model="taskStore.task.image" :disabled="!editable">
          <template #preview="{ src }">
            <img :src="src" class="aspect-square w-52 object-cover" />
          </template>
        </ElImageUpload>
      </ElFormItem>
      <ElFormItem prop="description" label="任務簡述" class="w-full">
        <ElInput
          v-model="taskStore.task.description"
          type="textarea"
          :autosize="{ minRows: 2 }"
          :disabled="!editable"
        />
      </ElFormItem>
      <ElFormItem
        v-if="taskStore.task.type !== enums.task.types.DAILY"
        prop="content"
        label="任務說明"
        class="w-full"
      >
        <ElInput
          v-model="taskStore.task.content"
          type="textarea"
          :autosize="{ minRows: 5 }"
          :disabled="!editable"
        />
      </ElFormItem>
      <ElFormItem prop="type" label="任務類別" class="min-w-[300px]">
        <ElSelect v-model="taskStore.task.type" :disabled="!editable">
          <ElOption
            v-for="(label, key) in constants.task.typeLabels"
            :key="key"
            :label="label"
            :value="key"
          />
        </ElSelect>
      </ElFormItem>
      <ElFormItem prop="status" label="狀態">
        <ElStatusSwitchPrompt
          v-model="taskStore.task.status"
          :prompt-options="{
            disabled: createMode,
          }"
          :http-request="(status) => APIs.task.updateTask(id, { status })"
          @change="
            () => {
              publishStatus = utils.convertTaskPublishStatus(taskStore.task);
            }
          "
        />
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem prop="start_at" label="上架時間">
        <ElDatePicker
          v-model="taskStore.task.start_at"
          type="datetime"
          :disabled-date="(time) => time.getTime() < Date.now()"
          :disabled="!editable"
          @change="
            (val) => {
              // 如果任務類型是每日任務，將 expired_at 設置為 start_at 當天的 23:59:59
              if (taskStore.task.type === enums.task.types.DAILY) {
                taskStore.task.expired_at = dayjs(val).endOf('day').format();
              }
            }
          "
        />
      </ElFormItem>
      <ElFormItem
        v-if="taskStore.task.type !== enums.task.types.DAILY"
        prop="expired_at"
        label="下架時間"
      >
        <ElDatePicker
          v-model="taskStore.task.expired_at"
          type="datetime"
          :disabled-date="
            (time) =>
              time.getTime() <
              [new Date(taskStore.task.start_at).getTime(), Date.now()]
                .sort()
                .pop()
          "
          :disabled="!editable"
        />
        <ElCheckbox
          v-model="isInfiniteExpiredAt"
          label="無期限"
          class="ml-3"
          :disabled="!editable"
        />
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem prop="limit" label="總次數限制">
        <ElInputNumber
          v-model="taskStore.task.limit"
          :controls="false"
          :disabled="taskStore.task.is_unlimit || !editable"
          :min="0"
          step-strictly
        />
        <ElCheckbox
          v-model="taskStore.task.is_unlimit"
          label="無限制"
          class="ml-3"
          :disabled="!editable"
        />
      </ElFormItem>
      <ElFormItem prop="coin" label="可獲取Ｖ幣數量">
        <ElInputNumber
          v-model="taskStore.task.coin"
          :controls="false"
          :min="0"
          :disabled="!editable"
          step-strictly
        />
      </ElFormItem>
      <ElFormItem label="總預算（Ｖ幣）">
        <div class="flex min-w-full gap-1">
          <span>=</span>
          <p class="shrink-0 grow border-b border-gray-300 px-1">
            {{
              taskStore.task.is_unlimit
                ? "無限制"
                : numeral(taskStore.task.limit * taskStore.task.coin).format()
            }}
          </p>
        </div>
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem prop="exp" label="培育值">
        <ElInputNumber
          v-model="taskStore.task.exp"
          :controls="false"
          :min="0"
          :disabled="!editable"
          step-strictly
        />
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem>
        <ElImage class="h-24 w-24" :src="brandSelected?.image">
          <template #error>
            <div
              class="flex h-full w-full items-center justify-center bg-gray-200"
            >
              <span class="material-symbols-outlined text-gray-400">
                imagesmode
              </span>
            </div>
          </template>
        </ElImage>
      </ElFormItem>
      <ElFormItem prop="brand_id" label="品牌商" class="min-w-[300px]">
        <ElSelect
          v-model="taskStore.task.brand_id"
          filterable
          remote
          clearable
          reserve-keyword
          :remote-method="remoteMethod"
          :loading="brandsLoading"
          :disabled="!editable"
          @change="
            (val) =>
              (brandSelected = taskStore.brands.find((item) => item.id === val))
          "
        >
          <template v-for="item in taskStore.brands" :key="item.id">
            <ElOption :value="item.id" :label="item.name">
              <div class="flex items-center">
                <img :src="item.image" class="w-5" />
                <span class="ml-2">{{ item.name }}</span>
              </div>
            </ElOption>
          </template>
        </ElSelect>
      </ElFormItem>
      <ElFormItem label="預覽" class="w-full">
        <TaskPreview :data="taskStore.task" class="w-full" />
      </ElFormItem>
      <ElFormItem class="w-full">
        <div class="flex w-full items-center justify-center">
          <ElButton
            type="info"
            plain
            @click="$router.push({ name: enums.route.names.TASKS })"
          >
            返回
          </ElButton>
          <ElButton type="primary" :disabled="!editable" @click="handleSubmit">
            儲存
          </ElButton>
          <ElButton type="warning" @click="navigateToNextPage">
            查看題目
          </ElButton>
        </div>
      </ElFormItem>
    </ElForm>
  </ElCard>
</template>

<style lang="scss" scoped></style>
