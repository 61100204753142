import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得商品列表
 * @param {PaginationParams} params
 */
export const getProducts = function (params) {
  return useErrorHandler(
    request({
      url: "/products",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一商品
 * @param {string} id 商品ID
 * @param {Object} [params]
 */
export const getProduct = function (id, params) {
  return useErrorHandler(
    request({
      url: `/products/${id}`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 新增商品
 * @param {Object} data
 */
export const createProduct = function (data) {
  return useErrorHandler(
    request({
      url: "/products",
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新商品
 * @param {string} id 商品ID
 * @param {Object} data
 */
export const updateProduct = function (id, data) {
  return useErrorHandler(
    request({
      url: `/products/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除商品
 * @param {string} id 商品ID
 */
export const deleteProduct = function (id) {
  return useErrorHandler(
    request({
      url: `/products/${id}`,
      method: "DELETE",
    }),
  );
};
