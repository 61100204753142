<script setup>
import {
  ElInputNumber,
  ElButton,
  ElTooltip,
  ElForm,
  ElFormItem,
  ElMessage,
} from "element-plus";
import { ref } from "vue";

import ElPrompt from "@/components/ElPrompt.vue";

const props = defineProps({
  modelValue: Number,
  httpRequest: Function,
  promptMode: Boolean,
});

const emits = defineEmits(["update:modelValue"]);

const reorderPromptRef = ref();
const promptFormData = ref({ order: 0 });
const promptFormRules = ref({
  order: [
    { required: true, message: "請輸入排序" },
    { type: "number", message: "排序必須為數字" },
  ],
});

const openReorderPrompt = () => {
  promptFormData.value.order = props.modelValue;
  reorderPromptRef.value
    .open({
      title: "修改排序",
      beforeResolve: () =>
        props
          .httpRequest(promptFormData.value.order)
          .then(() => true)
          .catch(() => false),
    })
    .then(() => {
      emits("update:modelValue", promptFormData.value.order);
      ElMessage.success("修改成功");
    })
    .catch((error) => {
      ElMessage.error(error.message);
    });
};
</script>

<template>
  <!-- TODO: 之後搬到 global -->
  <div class="flex items-center gap-1">
    <template v-if="promptMode">
      <span>{{ modelValue }}</span>
      <ElButton type="primary" link @click="openReorderPrompt">
        <span class="material-symbols-outlined text-xl">edit_square</span>
      </ElButton>
    </template>
    <template v-else>
      <ElInputNumber
        :model-value="modelValue"
        placeholder="請輸入排序"
        step-strictly
        :min="0"
        :controls="false"
        @change="(val) => $emit('update:modelValue', val)"
      />
      <slot name="tip">
        <ElTooltip placement="right" content="數字愈小愈上面">
          <i class="material-symbols-outlined text-primary">info</i>
        </ElTooltip>
      </slot>
    </template>
  </div>
  <ElPrompt ref="reorderPromptRef" width="400">
    <ElForm
      ref="promptFormRef"
      :model="promptFormData"
      :rules="promptFormRules"
      label-position="top"
      @submit.prevent
    >
      <ElFormItem prop="order" label="修改排序">
        <div class="flex items-center gap-1">
          <ElInputNumber
            v-model="promptFormData.order"
            placeholder="請輸入排序"
            step-strictly
            :min="0"
          />
          <slot name="tip">
            <ElTooltip placement="right" content="數字愈小愈上面">
              <i class="material-symbols-outlined text-primary">info</i>
            </ElTooltip>
          </slot>
        </div>
      </ElFormItem>
    </ElForm>
  </ElPrompt>
</template>

<style lang="scss" scoped></style>
