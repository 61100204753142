<script setup>
import { ElMessage, ElTableColumn } from "element-plus";
import { computed, ref } from "vue";

import * as APIs from "@/APIs";
import ElInfiniteScrollTable from "@/components/ElInfiniteScrollTable.vue";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const props = defineProps({
  id: String,
});

const loading = ref(false);
const list = ref([]);
const summary = ref({
  last_complete_task: "-",
  last_transaction_at: "-",
  last_action: "-",
  count_consecutive_days: "-",
});
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
  more: true,
});
const sortParams = ref({
  prop: "timestamp",
  order: "desc",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  return APIs.user
    .getUser(props.id, {
      page: query.page,
      per_page: query.per_page,
      columns: ["id"],
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      relationships: ["logs"],
    })
    .then((res) => {
      const { relation } = res.result;
      relation?.data?.forEach((item) => {
        list.value.push(item);
      });
      paginationParams.value.total = relation.total;
      paginationParams.value.more = !!relation.next_page_url;
      paginationParams.value.page++;

      summary.value.last_complete_task = dayjs(res.result.last_complete_task).format();
      summary.value.last_transaction_at = dayjs(res.result.last_transaction_at).format();
      summary.value.last_action = dayjs(res.result.last_action).format();
      summary.value.count_consecutive_days = res.result.count_consecutive_days;
    })
    .finally(() => {
      loading.value = false;
    });
};

const copyID = async (id) => {
  await helpers.copyToClipboard(id);
  ElMessage.success("已複製紀錄ID");
};

const onScrollBottom = async () => {
  await handleFetch(params.value);
};
</script>

<template>
  <table class="w-full border-separate border-spacing-3 text-left">
    <thead>
      <tr>
        <th>最近一次活動時間</th>
        <th>連續活躍天數</th>
        <th>最近完成任務時間</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-primary">{{ summary.last_action }}</td>
        <td class="text-primary">{{ summary.count_consecutive_days }} 天</td>
        <td class="text-primary">{{ summary.last_transaction_at }}</td>
      </tr>
    </tbody>
  </table>
  <ElInfiniteScrollTable
    :data="list"
    height="400"
    :disable="!paginationParams.more"
    :on-reach-bottom="onScrollBottom"
  >
    <ElTableColumn width="50">
      <template #default="{ row }">
        <button class="text-primary" @click="copyID(row.id)">
          <span class="material-symbols-outlined">content_copy</span>
        </button>
      </template>
    </ElTableColumn>
    <ElTableColumn
      prop="timestamp"
      label="時間"
      width="180"
      :formatter="({ timestamp }) => dayjs(timestamp).format()"
    />
    <ElTableColumn prop="description" label="歷程" min-width="300" />
  </ElInfiniteScrollTable>
</template>
