<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElNotification,
  ElOption,
  ElSelect,
  ElTag,
} from "element-plus";
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";

/** Components */
import * as APIs from "@/APIs";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ElImageUpload from "@/components/ElImageUpload.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";

const router = useRouter();

const props = defineProps({
  id: String,
});

const formRef = ref();
const createMode = computed(() => props.id === enums.common.CREATE_MODE);
const loading = ref(false);

const formData = ref({
  tax_id: "",
  name: "",
  description: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  address: "",
  status: enums.common.status.ENABLED,
  created_by: "",
  updated_by: "",
  created_at: "",
  updated_at: "",
});

const formRules = reactive({
  name: { required: true, message: "請輸入供應商名稱" },
  tax_id: [
    { required: true, message: "請輸入統編" },
    { pattern: /^[0-9]+$/, message: "統編格式錯誤" },
  ],
  contact_email: { type: "email", message: "信箱格式錯誤" },
  status: { required: true, message: "請選擇狀態" },
});

const handleSubmit = () => {
  formRef.value?.validate(async (valid, rules) => {
    if (!valid)
      ElNotification({
        title: "表單驗證錯誤",
        type: "error",
        message: Object.values(rules)[0][0].message,
      });
    else {
      try {
        loading.value = true;
        if (createMode.value)
          await APIs.supplier.createSupplier(formData.value).then((res) => {
            router.replace({ params: { id: res.result.id } });
          });
        else await APIs.supplier.updateSupplier(props.id, formData.value);
        ElNotification({
          title: `${createMode.value ? "新增" : "更新"}成功`,
          type: "success",
        });
      } finally {
        loading.value = false;
      }
    }
  });
};

if (!createMode.value) {
  loading.value = true;
  APIs.supplier
    .getSupplier(props.id)
    .then((res) => {
      formData.value = res.result;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: `${$route.meta.title}列表`,
          to: { name: enums.route.names.SUPPLIERS },
        },
        { label: createMode ? `${$route.meta.title}` : formData.name },
      ]"
    />
    <ElCard v-loading="loading" class="!rounded-xl">
      <h1 class="mb-3 text-2xl font-bold">
        {{ createMode ? "新增" : $route.meta.title }}
      </h1>
      <ElForm
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <div class="grid max-w-screen-lg gap-x-5 sm:grid-cols-2">
          <ElFormItem prop="image" class="col-span-full">
            <ElImageUpload v-model="formData.image">
              <template #preview="{ src }">
                <img :src="src" class="aspect-square w-52 object-cover" />
              </template>
            </ElImageUpload>
          </ElFormItem>
          <ElFormItem prop="name" label="獎勵供應商名稱">
            <ElInput v-model="formData.name" />
          </ElFormItem>
          <ElFormItem prop="tax_id" label="統編">
            <ElInput v-model="formData.tax_id" />
          </ElFormItem>
          <ElFormItem prop="address" label="地址" class="col-span-full">
            <ElInput v-model="formData.address" />
          </ElFormItem>
          <ElFormItem prop="contact_name" label="聯絡人姓名">
            <ElInput v-model="formData.contact_name" />
          </ElFormItem>
          <ElFormItem prop="contact_email" label="聯絡人電子郵件">
            <ElInput v-model="formData.contact_email" />
          </ElFormItem>
          <ElFormItem prop="contact_phone" label="聯絡人電話號碼">
            <ElInput v-model="formData.contact_phone" />
          </ElFormItem>
          <ElFormItem prop="status" label="狀態">
            <ElSelect v-model="formData.status">
              <ElOption
                v-for="(label, key) in constants.common.statusLabels"
                :key="key"
                :label="label"
                :value="Number(key)"
              >
                <ElTag :type="constants.common.statusColors[key]">
                  {{ label }}
                </ElTag>
              </ElOption>
            </ElSelect>
          </ElFormItem>
          <ElFormItem prop="description" label="備註" class="col-span-full">
            <ElInput
              v-model="formData.description"
              type="textarea"
              :autosize="{ minRows: 3 }"
            />
          </ElFormItem>
          <ElFormItem class="col-span-full">
            <div class="flex w-full items-center justify-center gap-3">
              <RouterLink :to="{ name: enums.route.names.SUPPLIERS }">
                <ElButton type="info" plain>返回</ElButton>
              </RouterLink>
              <ElButton type="primary" @click="handleSubmit">儲存</ElButton>
            </div>
          </ElFormItem>
        </div>
      </ElForm>
    </ElCard>
  </div>
</template>

<style lang="scss" scoped></style>
