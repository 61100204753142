<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElOption,
  ElPopover,
  ElSelect,
  ElTableColumn,
  ElTag,
} from "element-plus";
import numeral from "numeral";
import { computed, h, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElOrderEditor from "@/components/ElOrderEditor.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElRemoteSearchSelect from "@/components/ElRemoteSearchSelect.vue";
import ElTagsGroup from "@/components/ElTagsGroup.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

defineExpose({
  onBeforeTabLeave: () => {
    const modified = list.value.some((item) => item.modified);
    if (modified) {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(`尚有未保存的修改，確定離開嗎？`, "警告", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(resolve)
          .catch(reject);
      });
    }
    return Promise.resolve();
  },
});

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "order",
  order: "asc",
});
const searchParams = ref({
  name: "",
  status: "",
  brand_id: "",
  supplier_id: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const handleFetch = (query) => {
  loading.value = true;
  APIs.draw
    .getDraws({
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams({
        ...constants.draw.publishStatusFilters[searchParams.value.status],
        ...helpers.omit(searchParams.value, ["status"]),
      }),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      relationships: ["tags", "brand", "supplier", "tickets", "tickets.count"],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = (res.result.data || []).map((item) =>
        helpers.dataConverter(item, {
          status: utils.convertDrawPublishStatus(item),
          created_at: (val) => dayjs(val).format(),
          start_at: (val) => val && dayjs(val).format(),
          end_at: (val) => val && dayjs(val).format(),
          drawn_at: (val) => (val ? dayjs(val).format() : "-"),
          ticket_price: (val) => (val && numeral(val).format()) || "-",
          draw_threshold: (val) => (val && numeral(val).format()) || "-",
          tickets_count: (val) => (val && numeral(val).format()) || "-",
          tags: (val) => val.map((tag) => tag.name),
          rest_quantity: () =>
            numeral(item.prize_quantity || 0)
              .subtract(item.tickets_count || 0)
              .format(),
        }),
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const deleteDraws = () => {
  loading.value = true;
  Promise.all(
    multipleSelection.value.map((item) => APIs.draw.deleteDraw(item.id)),
  )
    .then(() => handleFetch(params.value))
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(params.value);
});
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem label="狀態:">
        <ElSelect v-model="searchParams.status" clearable>
          <ElOption
            v-for="(label, key) in constants.draw.publishStatusLabels"
            :key="key"
            :label="label"
            :value="key"
          >
            <ElTag :type="constants.draw.publishStatusColors[key]">
              {{ label }}
            </ElTag>
          </ElOption>
        </ElSelect>
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem label="供應商:">
        <ElRemoteSearchSelect
          v-model="searchParams.supplier_id"
          :http-request="
            (query) =>
              APIs.supplier
                .getSuppliers({
                  filters: helpers.convertSearchParams({
                    name: `%${query}%`,
                    status: enums.common.status.ENABLED,
                  }),
                })
                .then((res) =>
                  res.result.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                  })),
                )
          "
        >
          <template #option="item">
            <div class="flex items-center">
              <img :src="item.image" class="w-5" />
              <span class="ml-2">{{ item.name }}</span>
            </div>
          </template>
        </ElRemoteSearchSelect>
      </ElFormItem>
      <ElFormItem label="品牌商:">
        <ElRemoteSearchSelect
          v-model="searchParams.brand_id"
          :http-request="
            (query) =>
              APIs.brand
                .getBrands({
                  filters: helpers.convertSearchParams({
                    name: `%${query}%`,
                    status: enums.common.status.ENABLED,
                  }),
                })
                .then((res) =>
                  res.result.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                  })),
                )
          "
        >
          <template #option="item">
            <div class="flex items-center">
              <img :src="item.image" class="w-5" />
              <span class="ml-2">{{ item.name }}</span>
            </div>
          </template>
        </ElRemoteSearchSelect>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '上架時間', value: 'start_at' },
            { label: '下架時間', value: 'end_at' },
            { label: '建立時間', value: 'created_at' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <div class="mb-3 flex justify-end gap-2">
      <RouterLink
        :to="{
          name: enums.route.names.DRAW,
          params: { id: enums.common.CREATE_MODE },
        }"
      >
        <ElButton type="success">＋新增</ElButton>
      </RouterLink>
      <ElButton
        type="danger"
        :disabled="
          !multipleSelection.length ||
          multipleSelection.length > constants.common.MAX_MULTIPLE_DELETE
        "
        @click="deleteDraws"
      >
        <span class="material-symbols-outlined">delete</span>&nbsp;刪除
      </ElButton>
    </div>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn type="selection" width="55" />
      <ElTableColumn prop="order" label="排序" width="90" sortable>
        <template #default="{ row }">
          <ElOrderEditor
            v-model="row.order"
            prompt-mode
            :http-request="
              (order) =>
                APIs.draw
                  .updateDraw(row.id, { order })
                  .then(() => handleFetch(params))
            "
          />
        </template>
      </ElTableColumn>
      <ElTableColumn prop="name" label="獎品名稱" min-width="200" />
      <ElTableColumn prop="image" label="獎品圖片" min-width="100">
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <img :src="row.image" class="aspect-square w-10" />
            </template>
            <img :src="row.image" class="w-60" />
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="tags" label="抽獎方式" min-width="150">
        <template #default="{ row }">
          <ElTagsGroup
            :tags="
              row.tags.map((tagName) => ({
                name: tagName,
                color: constants.draw.tagColors[tagName],
              }))
            "
          />
        </template>
      </ElTableColumn>
      <ElTableColumn
        prop="ticket_price"
        label="抽獎金額(Ｖ幣/次)"
        min-width="150"
      />
      <ElTableColumn
        prop="tickets_count"
        label="累積抽獎次數"
        min-width="150"
      />
      <ElTableColumn prop="draw_threshold" label="達標上限" min-width="150" />
      <ElTableColumn
        prop="start_at"
        label="上架時間"
        sortable
        min-width="270"
        :formatter="
          ({ start_at, end_at }) =>
            start_at && end_at ? start_at + ' ~ ' + end_at : '-'
        "
      />
      <ElTableColumn prop="status" label="狀態" min-width="100">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElPopover
              placement="top-start"
              title="狀態說明"
              trigger="hover"
              width="fit-content"
            >
              <template #reference>
                <button class="material-symbols-outlined">info</button>
              </template>
              <div class="grid grid-cols-[auto,1fr] items-center gap-1">
                <template
                  v-for="status in enums.draw.publishStatus"
                  :key="status"
                >
                  <ElTag :type="constants.draw.publishStatusColors[status]">
                    {{ constants.draw.publishStatusLabels[status] }}
                  </ElTag>
                  <span class="whitespace-nowrap">
                    {{ constants.draw.publishStatusDescription[status] }}
                  </span>
                </template>
              </div>
            </ElPopover>
          </div>
        </template>
        <template #default="{ row }">
          <ElTag :type="constants.draw.publishStatusColors[row.status]">
            {{ constants.draw.publishStatusLabels[row.status] }}
          </ElTag>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="drawn_at" label="開獎時間" min-width="150" />
      <ElTableColumn prop="created_at" label="創立時間" min-width="150" />
      <ElTableColumn
        prop="created_at"
        label="建立時間"
        min-width="150"
        sortable
      />
      <ElTableColumn label="名單" min-width="80" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{
              name: enums.route.names.DRAW_RESULTS,
              params: { id: row.id },
            }"
          >
            <ElButton type="primary" link>查看</ElButton>
          </RouterLink>
        </template>
      </ElTableColumn>
      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{ name: enums.route.names.DRAW, params: { id: row.id } }"
          >
            <ElButton
              :type="
                [
                  enums.draw.publishStatus.DRAFT,
                  enums.draw.publishStatus.PENDING,
                ].includes(row.status)
                  ? 'primary'
                  : 'info'
              "
              link
            >
              {{
                [
                  enums.draw.publishStatus.DRAFT,
                  enums.draw.publishStatus.PENDING,
                ].includes(row.status)
                  ? "編輯"
                  : "檢視"
              }}
            </ElButton>
          </RouterLink>
          <ElButton
            v-if="row.status !== enums.draw.publishStatus.OFFLINE"
            :type="
              row.status === enums.draw.publishStatus.DRAFT
                ? 'success'
                : 'danger'
            "
            link
            @click="
              () => {
                const isDraft = row.status === enums.draw.publishStatus.DRAFT;
                ElMessageBox.confirm(
                  h('p', null, [
                    h('span', null, `確定要將「`),
                    h('strong', null, row.name),
                    h('span', null, `」`),
                    h(
                      'span',
                      isDraft
                        ? { style: 'color: var(--color-success)' }
                        : { style: 'color: var(--color-danger)' },
                      isDraft ? '上線' : '下線',
                    ),
                    h('span', null, `嗎？`),
                  ]),
                  '警告',
                  {
                    confirmButtonText: '確定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                      const status = isDraft
                        ? enums.draw.publishStatus.ONLINE
                        : enums.draw.publishStatus.OFFLINE;
                      if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        APIs.draw
                          .updateDraw(row.id, {
                            status,
                          })
                          .then(() => {
                            row.status = status;
                            done();
                          })
                          .finally(() => {
                            instance.confirmButtonLoading = false;
                          });
                      } else {
                        done(false);
                      }
                    },
                  },
                ).catch(() => {});
              }
            "
          >
            {{
              row.status === enums.draw.publishStatus.DRAFT ? "上線" : "下線"
            }}
          </ElButton>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </ElCard>
</template>
