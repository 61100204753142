<script setup>
import { computed } from "vue";

import * as constants from "@/constants";
import * as helpers from "@/libs/helpers";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
});

const _URL = computed(() => {
  try {
    return new URL(props.src);
  } catch {
    return new URL(import.meta.env.VITE_PLACEHOLDER_IMAGE);
  }
});

const _bgColor = computed(
  () =>
    _URL.value.pathname.split("/")[1] ||
    helpers.getRandomPicks(constants.common.placeholderPreDefineColors)[0],
);
const _color = computed(
  () =>
    _URL.value.pathname.split("/")[2] ||
    helpers.getRandomPicks(constants.common.placeholderPreDefineColors)[0],
);
const _text = computed(() => _URL.value.searchParams.get("text") || "");
</script>

<template>
  <svg
    class="aspect-square"
    :style="{ backgroundColor: `#${_bgColor}`, color: `#${_color}` }"
    viewBox="0 0 128 128"
  >
    <foreignObject x="0" y="0" width="100%" height="100%" :fill="`#${_color}`">
      <div class="flex h-full w-full items-center justify-center p-1">
        <p
          class="whitespace-pre-line break-all text-center !leading-normal"
          v-text="_text"
        />
      </div>
    </foreignObject>
  </svg>
</template>

<style lang="scss" scoped></style>
