<script setup>
import { ElOption, ElSelect } from "element-plus";
import { ref } from "vue";

import * as helpers from "@/libs/helpers";

const props = defineProps({
  modelObject: {
    type: Object,
    required: true,
  },
  keyOptions: {
    type: Array,
    required: true,
    validator: (val) => {
      return val.every((item) => {
        return item.value && item.label;
      });
    },
  },
});

const emit = defineEmits(["update:modelObject", "key-changed"]);

const key = ref(props.keyOptions[0].value);

const deleteKey = (key) => {
  emit("update:modelObject", helpers.omit(props.modelObject, [key]));
};

const deleteKeyWhenValueIsEmpty = (value) => {
  if (value === undefined || value === null || value === "") {
    deleteKey(key.value);
  }
};

const handleKeyChanged = (val) => {
  deleteKey(key.value); // delete the old key
  emit("key-changed", val);
  key.value = val;
};
</script>

<template>
  <div class="grid min-w-full grid-cols-3 items-center gap-1">
    <ElSelect
      class="backgrounded min-w-[10em]"
      :model-value="key"
      @change="handleKeyChanged"
    >
      <ElOption
        v-for="item in keyOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </ElSelect>
    <div class="col-span-2">
      <slot
        :key="key"
        :delete-key-when-value-is-empty="deleteKeyWhenValueIsEmpty"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.backgrounded :deep(.el-select__wrapper) {
  background: #ebeff4;
}
</style>
