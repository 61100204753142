import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得亞種列表
 * @param {PaginationParams} params
 */
export const getSubspeciesList = function (params) {
  return useErrorHandler(
    request({
      url: "/subspecies",
      method: "GET",
      params,
    }),
  );
};

/**
 * 更新亞種
 * @param {string} id 亞種ID
 * @param {Object} data
 */
export const updateSubspecies = function (id, data) {
  return useErrorHandler(
    request({
      url: `/subspecies/${id}`,
      method: "PATCH",
      data,
    }),
  );
};
