<script setup>
import { ElButton, ElCard, ElMessageBox, ElNotification } from "element-plus";
import { computed, nextTick, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import TaskableForm from "@/components/Task/TaskableForm.vue";
import TaskablePreview from "@/components/Task/TaskablePreview.vue";
import TaskPreview from "@/components/Task/TaskPreview.vue";
import * as enums from "@/enums";
import EventEmitter from "@/libs/event-emitter";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";
import { useTaskStore } from "@/stores/task";

const props = defineProps({
  id: String,
});

const taskStore = useTaskStore();
const taskableFormRefs = ref({});
const initialized = ref(false);
const list = ref([]);

const publishStatus = computed(() =>
  utils.convertTaskPublishStatus(taskStore.task),
);

const editable = computed(() =>
  [enums.task.publishStatus.DRAFT, enums.task.publishStatus.PENDING].includes(
    publishStatus.value,
  ),
);

const init = () => {
  if (initialized.value) return;
  list.value = taskStore.taskables.map((item) => ({
    type: item.type,
    data: item,
    editable: false,
    loading: false,
  }));
  initialized.value = true;
};

const toggleEditable = (index, status) => {
  const item = list.value[index];

  // 新增的題目，編輯狀態下取消編輯，直接刪除
  if (!item.data.id) {
    list.value.splice(index, 1);
    return;
  }

  item.editable = status;
  if (status) {
    nextTick(() => {
      taskableFormRefs.value[index]?.initFormData?.(item.data);
    });
  }
};

const submit = async (index) => {
  const taskableData = list.value[index];
  const taskableId = taskableData.data.id;
  const created = !!taskableId;
  const formRef = taskableFormRefs.value[index];
  try {
    taskableData.loading = true;
    const { type: taskableType, data: formData } = await formRef.validate();

    if (created) {
      await APIs.taskable.updateTaskable(taskableType, taskableId, formData);
    } else {
      await APIs.taskable.createTaskable(taskableType, formData).then((res) => {
        // 綁定 taskable 的 id，以便後續更新
        taskableData.data.id = res.result.id;
      });
      await APIs.task.assignTaskables(props.id, [
        {
          id: taskableData.data.id,
          type: `${taskableType}s`,
          order: formData.order ?? index + 1,
        },
      ]);
    }

    Object.assign(taskableData.data, helpers.omit(formData, ["id"]));
    taskableData.type = taskableType;

    ElNotification({
      title: "更新成功",
      type: "success",
    });

    taskableData.editable = false;
  } finally {
    taskableData.loading = false;
  }
};

const deleteTaskable = (index) => {
  const item = list.value[index];
  ElMessageBox.confirm(`確定要刪除「${item.data.title}」嗎？`, "提示", {
    type: "warning",
  }).then(async () => {
    try {
      item.loading = true;
      await APIs.task.unbindTaskables(props.id, item.type, item.data.id);
      list.value.splice(index, 1);
      ElNotification({ title: "刪除成功", type: "success" });
    } finally {
      item.loading = false;
    }
  });
};

EventEmitter.on(enums.emitter.events.TASK_FETCH_SUCCESS, init);

onMounted(() => {
  if (taskStore.taskables.length) {
    init();
  }
});
</script>

<template>
  <div class="mb-80 max-w-screen-lg">
    <button
      @click="
        $router.push({
          name: enums.route.names.TASK_INFO,
          params: { id },
        })
      "
      class="my-5 flex items-center gap-1"
    >
      <span class="material-symbols-outlined">arrow_back</span>
      <span>返回</span>
    </button>
    <ElCard
      v-for="(item, i) in list"
      :key="item.data.id"
      v-loading="item.loading"
      class="mb-5"
    >
      <div v-if="editable" class="mb-5 flex justify-end">
        <template v-if="item.editable">
          <ElButton type="info" @click="toggleEditable(i, false)">
            取消
          </ElButton>
          <ElButton type="success" @click="submit(i)">
            {{ item.data.id ? "更新" : "建立" }}
          </ElButton>
        </template>
        <template v-else>
          <ElButton type="primary" @click="toggleEditable(i, true)">
            編輯
          </ElButton>
          <ElButton type="danger" @click="deleteTaskable(i)">刪除</ElButton>
        </template>
      </div>
      <TaskableForm
        v-if="item.editable"
        :ref="(el) => (taskableFormRefs[i] = el)"
        :type="item.type"
        :taskable-type-changeable="!item.data.id"
        :available-taskable-types="
          Object.entries({
            [enums.taskable.taskableTypes.SURVEY]: true,
            [enums.taskable.taskableTypes.QUESTION]: true,
            [enums.taskable.taskableTypes.VIDEO]: true,
            [enums.taskable.taskableTypes.CLICK]: list.length <= 1,
          })
            .filter(([, availability]) => availability)
            .map(([type]) => type)
        "
      />
      <TaskPreview
        v-else-if="item.type === enums.taskable.taskableTypes.CLICK"
        :data="{ ...taskStore.task, url: item.data.url }"
      />
      <TaskablePreview v-else :type="item.type" :data="item.data" />
    </ElCard>
    <ElButton
      v-if="
        editable &&
        list.filter(
          (item) =>
            item.editable || // 限制同時只能編輯一個題目
            item.type === enums.taskable.taskableTypes.CLICK, // 限制只能新增一個導連任務
        ).length === 0
      "
      type="success"
      plain
      class="!block"
      :class="{
        'mx-auto': list.length > 0,
      }"
      @click="
        () => {
          list.push({
            type: '',
            data: {},
            editable: true,
            loading: false,
          });
        }
      "
    >
      ＋新增題目
    </ElButton>
  </div>
</template>

<style lang="scss" scoped></style>
