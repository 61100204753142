<script setup>
import { ElColorPicker, ElForm, ElFormItem } from "element-plus";
import { computed, onMounted, ref, watch } from "vue";

import ColorBlockViewer from "@/components/ColorBlockViewer.vue";
import * as constants from "@/constants";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  text: String,
});

const emit = defineEmits(["update:src"]);

const bgColor = ref(
  helpers.getRandomPicks(constants.common.placeholderPreDefineColors)[0],
);
const color = ref(
  helpers.getRandomPicks(constants.common.placeholderPreDefineColors)[0],
);
const width = ref(128);
const extension = ref(constants.common.placeholderExtensions[0]);

const imageSrc = computed(
  () =>
    [
      import.meta.env.VITE_PLACEHOLDER_IMAGE,
      `${bgColor.value.replaceAll("#", "")}`,
      `${color.value.replaceAll("#", "")}`,
      `${width.value}x${width.value}.${extension.value}`,
    ].join("/") +
    "?" +
    [`text=${encodeURIComponent(props.text)}`].join("&"),
);

watch(
  () => imageSrc.value,
  () => emit("update:src", imageSrc.value),
);

onMounted(() => {
  if (utils.containsDomain(props.src, import.meta.env.VITE_PLACEHOLDER_IMAGE)) {
    // props.src: https://placehold.jp/000000/ffffff/128x128.png?text=Hello%20World
    const { pathname } = new URL(props.src);
    const [_bgColor, _color, _width, _extension] = [
      pathname.split("/")[1],
      pathname.split("/")[2],
      pathname.split("/")[3]?.split(".")[0].split("x")[0],
      pathname.split("/")[3]?.split(".")[1],
    ];
    if (_color) color.value = `#${_color}`;
    if (_bgColor) bgColor.value = `#${_bgColor}`;
    if (_width) width.value = _width;
    if (_extension) extension.value = _extension;
  }

  emit("update:src", imageSrc.value);
});
</script>

<template>
  <ElForm label-position="right" label-width="75">
    <div class="flex flex-wrap gap-2">
      <ColorBlockViewer :src="imageSrc" class="w-32" />
      <div>
        <ElFormItem label="文字顏色" class="!mb-2">
          <ElColorPicker
            v-model="color"
            color-format="hex"
            :predefine="constants.common.placeholderPreDefineColors"
          />
        </ElFormItem>
        <ElFormItem label="背景顏色" class="!mb-2">
          <ElColorPicker
            v-model="bgColor"
            color-format="hex"
            :predefine="constants.common.placeholderPreDefineColors"
          />
        </ElFormItem>
      </div>
    </div>
  </ElForm>
</template>

<style lang="scss" scoped></style>
