<script setup>
import { ElCard, ElTabPane, ElTabs } from "element-plus";
import { ref } from "vue";
import { RouterView, useLink, useRouter } from "vue-router";

import * as constants from "@/constants";
import * as enums from "@/enums";

const router = useRouter();

const tabs = ref([
  {
    label: constants.product.typeLabels[enums.product.types.GOODS],
    link: useLink({
      to: {
        name: enums.route.names.PRODUCTS,
        params: {
          typeId: enums.product.types.GOODS,
        },
      },
    }),
  },
]);
const childrenRef = ref(null);

const onBeforeTabLeave = () => {
  // return childrenRef.value?.onBeforeTabLeave() ?? Promise.resolve();
  return Promise.resolve();
};

if (!tabs.value.some((tab) => tab.link.isActive)) {
  router.replace(tabs.value[0].link.href);
}
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElTabs
      :model-value="$route.path"
      :before-leave="onBeforeTabLeave"
      @tab-change="$router.push"
    >
      <ElTabPane
        v-for="tab in tabs"
        :key="tab.link.route.path"
        :name="tab.link.route.path"
        :label="tab.label"
      />
    </ElTabs>
    <RouterView v-slot="{ Component }">
      <component ref="childrenRef" :is="Component" />
    </RouterView>
  </ElCard>
</template>

<style lang="scss" scoped></style>
