<script setup>
import { ElMessage, ElTableColumn } from "element-plus";
import numeral from "numeral";
import { computed, ref } from "vue";

import * as APIs from "@/APIs";
import ElInfiniteScrollTable from "@/components/ElInfiniteScrollTable.vue";
import IconSprite from "@/components/IconSprite.vue";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const props = defineProps({
  id: String,
});

const loading = ref(false);
const list = ref([]);
const summary = ref({
  balance: "-",
  totalDepositRecord: "-",
  totalWithdrawRecord: "-",
});
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
  more: true,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  return APIs.user
    .getUser(props.id, {
      page: query.page,
      per_page: query.per_page,
      columns: ["id", "created_at"],
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      relationships: ["transactions"],
    })
    .then((res) => {
      const { relation } = res.result;
      relation?.data?.forEach((item) => {
        list.value.push(item);
      });
      paginationParams.value.total = relation.total;
      paginationParams.value.more = !!relation.next_page_url;
      paginationParams.value.page++;

      summary.value.balance = numeral(res.result.wallet.balance).format();
      summary.value.totalDepositRecord = numeral(res.result.transaction_summary.deposit.total).format();
      summary.value.totalWithdrawRecord = numeral(res.result.transaction_summary.withdraw.total).format();
    })
    .finally(() => {
      loading.value = false;
    });
};

const copyID = async (id) => {
  await helpers.copyToClipboard(id);
  ElMessage.success("已複製紀錄ID");
};

const onScrollBottom = async () => {
  await handleFetch(params.value);
};
</script>

<template>
  <table class="w-full border-separate border-spacing-3 text-left">
    <thead>
      <tr>
        <th>累計獲得</th>
        <th>目前持有</th>
        <th>已使用</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-primary">{{summary.balance}}</td>
        <td class="text-primary">{{summary.totalDepositRecord}}</td>
        <td class="text-primary">{{summary.totalWithdrawRecord}}</td>
      </tr>
    </tbody>
  </table>
  <ElInfiniteScrollTable
    :data="list"
    height="400"
    :disable="!paginationParams.more"
    :on-reach-bottom="onScrollBottom"
  >
    <ElTableColumn width="50">
      <template #default="{ row }">
        <button class="text-primary" @click="copyID(row.id)">
          <span class="material-symbols-outlined">content_copy</span>
        </button>
      </template>
    </ElTableColumn>
    <ElTableColumn
      prop="created_at"
      label="時間"
      width="180"
      :formatter="({ created_at }) => dayjs(created_at).format()"
    />
    <ElTableColumn
      prop="description"
      label="歷程"
      min-width="300"
      :formatter="
        ({ description, meta }) =>
          [description, meta.name, meta.id].filter((item) => item).join('｜')
      "
    />
    <ElTableColumn prop="vcoin" label="Ｖ幣" min-width="150">
      <template #default="{ row }">
        <div class="flex items-center gap-2">
          <IconSprite id="vcoin-outlined" class="aspect-square w-5 text-yellow-400" />
          <span>{{ numeral(row.amount).format() }}</span>
        </div>
      </template>
    </ElTableColumn>
  </ElInfiniteScrollTable>
</template>
