import { defineStore } from "pinia";
import { ref } from "vue";

import * as APIs from "@/APIs";
import { defaultService, setAuthorization } from "@/libs/axios";
import { deleteCookie, setCookie } from "@/libs/cookie";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(null);

  const login = (email, password) => {
    return APIs.auth.login({ email, password }).then((res) => {
      const token = `${res.result.token_type} ${res.result.access_token}`;
      setAuthorization(defaultService, token);
      setCookie("token", token, 1);
    });
  };

  const getUserInfo = () => {
    return APIs.auth
      .getUserInfo()
      .then((res) => {
        user.value = res.result;
        return Promise.resolve(true);
      })
      .catch(() => {
        user.value = null;
        deleteCookie("token");
        return Promise.reject(false);
      });
  };

  const logout = () => {
    deleteCookie("token"); // 清除 cookie
    setAuthorization(defaultService, ""); // 清除 Authorization header
    user.value = null;
  };

  const hasRoles = (roles = []) => {
    if (!user.value) return false;
    if (!roles.length) return true;
    return roles.some(
      (role) => user.value.roles?.some((r) => r.name === role) ?? false,
    );
  };

  return {
    user,
    login,
    logout,
    getUserInfo,
    hasRoles,
  };
});
