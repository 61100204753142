export const names = {
  LOGIN: "LoginView",
  HOME: "HomeView",
  DASHBOARD: "DashboardView",
  ADMIN: "AdminView",
  MANAGERS: "ManagersView",
  USERS: "UsersView",
  USER: "UserView",
  FAQS_TABS: "FAQsTabsView",
  FAQS: "FAQsView",
  MESSAGES: "MessagesView",
  MESSAGE: "MessageView",
  VENDORS: "VendorsView",
  BRANDS: "BrandsView",
  BRAND: "BrandView",
  SUPPLIERS: "SuppliersView",
  SUPPLIER: "SupplierView",
  TASKS_TABS: "TasksTabsView",
  TASKS_CHECK_IN: "TasksCheckInView",
  TASKS: "TasksView",
  TASK_INFO: "TaskInfoView",
  TASK_TASKABLES: "TaskTaskablesView",
  TASKABLES_TABS: "TaskablesTabsView",
  TASKABLES: "TaskablesView",
  TASKABLE: "TaskableView",
  CULTIVATE: "CultivateView",
  SPECIES_LIST: "SpeciesListView",
  SUBSPECIES_LIST: "SubspeciesListView",
  AWARD: "AwardView",
  PRODUCTS_TABS: "ProductsTabsView",
  PRODUCTS: "ProductsView",
  PRODUCT: "ProductView",
  ORDERS: "OrdersView",
  LOTTERY: "LotteryView",
  DRAWS: "DrawsView",
  DRAW: "DrawView",
  DRAW_RESULTS: "DrawResultsView",
  PRIZES: "PrizesView",
  PRIZE_GROUPS: "PrizeGroupsView",
  PRIZE_RECORDS: "PrizeRecordsView",
  PLAYGROUND: "PlaygroundView",
  TOOLBOX: "ToolboxView",
  COIN_POOL: "CoinPoolView",
};
