import { request, useErrorHandler } from "@/libs/axios";
import * as helpers from "@/libs/helpers";

/**
 * 取得所有會員資料
 * @param {PaginationParams} params
 */
export const getUsers = function (params) {
  return useErrorHandler(
    request({
      url: "/users",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得會員資料
 * @param {string} id
 * @param {PaginationParams} [params]
 */
export const getUser = function (id, params) {
  return useErrorHandler(
    request({
      url: `/users/${id}`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 刪除會員資料
 * @param {string} id
 */
export const deleteUser = function (id) {
  return useErrorHandler(
    request({
      url: `/users/${id}`,
      method: "DELETE",
    }),
  );
};

/**
 * 更新會員資料
 * @param {string} id
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.roles
 * @param {string} data.note
 */
export const updateUser = function (id, data) {
  return useErrorHandler(
    request({
      url: `/users/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 新增會員/註冊（帳號／密碼）
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.password_confirmation
 * @param {string} data.name 姓名
 * @param {string} [data.nickname] 暱稱
 * @param {string} [data.referred_by] 邀請碼
 */
export const register = function (data) {
  return useErrorHandler(
    request({
      url: "/register",
      method: "POST",
      data,
    }),
  );
};

/**
 * Perform a transaction.
 * @param {object} data - The transaction data.
 * @param {string} data.user_id - The user ID.
 * @param {number} data.amount - The transaction amount.
 * @param {string} data.reason - The reason for the transaction.
 * @param {number} [data.type] - The transaction type (0: transfer, 1: withdrawal).
 */
export const transaction = function (data) {
  const url = `/users/${
    data.amount >= 0 ? "increase-coins" : "decrease-coins"
  }`;
  if (data.amount > 0 && data.type !== undefined) {
    delete data.type;
  }
  return useErrorHandler(
    request({
      url,
      method: "POST",
      data: helpers.dataConverter(data, {
        amount: (val) => Math.abs(val),
      }),
    }),
  );
};
