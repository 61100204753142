export default {
  /**
   * @param {import("vue").App} VueApp
   * @param {object} options
   * @param {string} options.appId
   * @param {string} options.version
   * @param {boolean} [options.autoLogAppEvents]
   * @param {boolean} [options.xfbml]
   * @example
   * import FacebookSDK from "./plugins/facebook-sdk";
   * createApp(App).use(FacebookSDK, {
   *  appId: "123456789",
   *  version: "v18.0",
   *  autoLogAppEvents: true,
   *  xfbml: true,
   * });
   * @see https://developers.facebook.com/docs/javascript/quickstart
   */
  install: (VueApp, options) => {
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-sdk");

    window.fbAsyncInit = function onSDKInit() {
      window.FB.init(options);
      window.FB.AppEvents.logPageView();
      VueApp.FB = window.FB;
      window.dispatchEvent(new Event("fb-sdk-ready"));
    };
  },
};