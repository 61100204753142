<script setup>
import {
  ElButton,
  ElCard,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElMessage,
  ElMessageBox,
  ElOption,
  ElPopover,
  ElSelect,
  ElSwitch,
  ElTableColumn,
  ElTag,
} from "element-plus";
import numeral from "numeral";
import { computed, h, ref } from "vue";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElPrompt from "@/components/ElPrompt.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const loading = ref(false);
const tableData = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({});

const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

// prompt
const promptType = ref(""); // vcoin | status
const promptRef = ref();
const promptFormRef = ref();
const promptFormData = ref({
  vcoin: {
    delta: 0,
    reason: "",
    type: enums.wallet.TransactionType.WITHDRAW,
  },
  status: {
    reason: "",
  },
});
const promptFormRules = {
  vcoin: {
    delta: [
      { required: true, message: "請輸入數字" },
      {
        validator: (rule, value, callback) => {
          if (value === 0) callback(new Error("Ｖ幣數量不可為0"));
          else callback();
        },
      },
    ],
    reason: { required: true, message: "請輸入原因" },
  },
  status: {
    reason: { required: true, message: "請輸入原因" },
  },
};

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  APIs.user
    .getUsers({
      page: query.page,
      per_page: query.per_page,
      relationships: ["roles", "wallet"],
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      tableData.value = res.result.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

const updateUser = (id, data) => {
  loading.value = true;
  return APIs.user
    .updateUser(id, data)
    .then(() => {
      const user = tableData.value.find((user) => user.id === id);
      Object.assign(user, data);
    })
    .finally(() => {
      loading.value = false;
    });
};

const updateUserStatus = ({ id, status, nickname }) => {
  promptType.value = "status";
  const enabled = status === enums.common.status.ENABLED;
  return new Promise((resolve) => {
    promptRef.value
      .open({
        title: `確定要${
          enabled
            ? constants.common.statusLabels[enums.common.status.DISABLED]
            : constants.common.statusLabels[enums.common.status.ENABLED]
        }${nickname}嗎？`,
        beforeResolve: () =>
          promptFormRef.value
            .validate()
            .then(async () => {
              const { reason } = promptFormData.value.status;
              await updateUser(id, {
                status: enabled
                  ? enums.common.status.DISABLED
                  : enums.common.status.ENABLED,
                reason,
              });
              return true;
            })
            .catch(() => false),
      })
      .then(() => {
        ElMessage({
          message: `已${
            enabled
              ? constants.common.statusLabels[enums.common.status.DISABLED]
              : constants.common.statusLabels[enums.common.status.ENABLED]
          }${nickname}`,
          type: enabled ? "info" : "success",
        });
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      })
      .finally(() => {
        promptFormData.value.status.reason = "";
        promptFormRef.value.resetFields();
      });
  });
};

const updateUserCoin = ({ id, nickname, wallet }) => {
  promptType.value = "vcoin";
  return new Promise((resolve) => {
    promptRef.value
      ?.open({
        title: `修改${nickname}的Ｖ幣`,
        message: h("span", [
          h("span", null, "目前持有Ｖ幣數量："),
          h("br"),
          h(
            "strong",
            {
              style:
                "color: var(--color-info); padding-left: 1em; line-height: 2; font-size: 1.5em;",
            },
            numeral(wallet.balance).format(),
          ),
        ]),
        beforeResolve: () =>
          promptFormRef.value
            .validate()
            .then(async () => {
              const { delta, reason, type } = promptFormData.value.vcoin;
              await APIs.user
                .transaction({
                  user_id: id,
                  amount: delta,
                  reason,
                  type,
                })
                .then(() => {
                  const user = tableData.value.find((user) => user.id === id);
                  user.wallet.balance = numeral(user.wallet.balance)
                    .add(delta)
                    .value();
                });
              return true;
            })
            .catch(() => false),
      })
      .then(() => {
        ElMessageBox.alert(
          `已修改${nickname}的Ｖ幣，請通知會員`,
          "修改Ｖ幣成功",
          { type: "success" },
        );
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      })
      .finally(() => {
        promptFormData.value.vcoin.delta = 0;
        promptFormData.value.vcoin.reason = "";
        promptFormRef.value.resetFields();
      });
  });
};

const copyID = async (id) => {
  await helpers.copyToClipboard(id);
  ElMessage.success("已複製會員ID");
};

handleFetch(params.value);
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '會員ID', value: 'id' },
            { label: '暱稱', value: 'nickname' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElInput
              v-model="searchParams[key]"
              :formatter="(val) => val.replaceAll('%', '')"
              :parser="(val) => `%${val.replaceAll('%', '')}%`"
              @input="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[{ label: '會員狀態', value: 'status' }]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElSelect
              v-model="searchParams[key]"
              clearable
              @change="deleteKeyWhenValueIsEmpty"
            >
              <template v-if="key === 'status'">
                <ElOption
                  v-for="(label, key) in constants.common.statusLabels"
                  :key="key"
                  :label="label"
                  :value="Number(key)"
                >
                  <ElTag :type="constants.common.statusColors[key]">
                    {{ label }}
                  </ElTag>
                </ElOption>
              </template>
            </ElSelect>
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '最近使用Ｖ幣時間', value: 'last_transaction_at' },
            { label: '加入時間', value: 'created_at' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <ElButton type="info" class="ml-auto !block" @click="() => {}">
      <span class="material-symbols-outlined">download</span>
      匯出
    </ElButton>
    <ElPaginationTable
      :data="tableData"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
    >
      <ElTableColumn prop="id" label="會員ID" min-width="120">
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <span>{{
                row.id.slice(0, 4) + "****" + row.id.slice(row.id.length - 4)
              }}</span>
            </template>
            {{ row.id }}
            <button
              class="whitespace-nowrap text-primary"
              @click="copyID(row.id)"
            >
              <span class="material-symbols-outlined">content_copy</span>
              複製
            </button>
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="nickname" label="暱稱" min-width="180" />
      <ElTableColumn prop="mobile" label="手機" min-width="150" />
      <ElTableColumn prop="email" label="信箱" min-width="300" />
      <ElTableColumn label="狀態" min-width="110">
        <template #default="{ row }">
          <ElSwitch
            :model-value="row.status"
            :active-text="
              constants.common.statusLabels[enums.common.status.ENABLED]
            "
            :inactive-text="
              constants.common.statusLabels[enums.common.status.DISABLED]
            "
            :active-value="enums.common.status.ENABLED"
            :inactive-value="enums.common.status.DISABLED"
            inline-prompt
            :before-change="() => updateUserStatus(row)"
          />
        </template>
      </ElTableColumn>
      <ElTableColumn
        prop="last_transaction_at"
        label="最近一次使用Ｖ幣"
        min-width="180"
        sortable
        :formatter="
          ({ last_transaction_at }) =>
            last_transaction_at ? dayjs(last_transaction_at).format() : '-'
        "
      />
      <ElTableColumn prop="" label="持有Ｖ幣" min-width="100">
        <template #default="{ row }">
          <div class="flex items-center">
            <span class="line-clamp-1">{{
              numeral(row.wallet?.balance).format()
            }}</span>
            <ElButton type="primary" link @click="updateUserCoin(row)">
              <span class="material-symbols-outlined text-xl">edit_square</span>
            </ElButton>
          </div>
        </template>
      </ElTableColumn>
      <ElTableColumn
        prop="created_at"
        label="加入時間"
        min-width="180"
        sortable
        :formatter="({ created_at }) => dayjs(created_at).format()"
      />
      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{
              name: enums.route.names.USER,
              params: { id: row.id },
            }"
          >
            <ElButton type="primary" link>查看</ElButton>
          </RouterLink>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </ElCard>
  <ElPrompt ref="promptRef" width="500">
    <ElForm
      ref="promptFormRef"
      :model="promptFormData"
      :rules="promptFormRules"
      label-position="top"
      @submit.prevent
    >
      <template v-if="promptType === 'vcoin'">
        <ElFormItem prop="vcoin.delta" label="修改Ｖ幣（輸入「-」為扣除）">
          <ElInputNumber
            v-model="promptFormData.vcoin.delta"
            placeholder="請輸入欲調整之Ｖ幣數量"
            step-strictly
            :controls="false"
          />
        </ElFormItem>
        <ElFormItem prop="vcoin.reason" label="調整原因">
          <ElInput
            v-model="promptFormData.vcoin.reason"
            placeholder="請輸入調整原因"
            type="textarea"
            :autosize="{ minRows: 2 }"
          />
        </ElFormItem>
        <ElFormItem v-if="promptFormData.vcoin.delta < 0" prop="vcoin.type">
          <ElCheckbox
            v-model="promptFormData.vcoin.type"
            :true-value="enums.wallet.TransactionType.TRANSFER"
            :false-value="enums.wallet.TransactionType.WITHDRAW"
          >
            是否返回總資金池
          </ElCheckbox>
        </ElFormItem>
      </template>
      <template v-if="promptType === 'status'">
        <ElFormItem prop="status.reason" label="修改原因">
          <ElInput
            v-model="promptFormData.status.reason"
            placeholder="請輸入調整原因"
            type="textarea"
            :autosize="{ minRows: 2 }"
          />
        </ElFormItem>
      </template>
    </ElForm>
  </ElPrompt>
</template>

<style lang="scss" scoped>
:deep(.el-switch:not(.is-checked) .is-text) {
  @apply text-black;
}
</style>
