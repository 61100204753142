import * as enums from "@/enums";

export const roleLabels = {
  [enums.user.roles.SUPER_ADMIN]: "超級管理者",
  [enums.user.roles.ADMIN]: "管理者",
  [enums.user.roles.EDITOR]: "營運",
  [enums.user.roles.PART_TIME]: "兼職",
  [enums.user.roles.USER]: "玩家",
};

export const levelLabels = {
  [enums.user.level.INITIAL]: "初始會員",
  [enums.user.level.BRONZE]: "黃銅",
  [enums.user.level.SILVER]: "白銀",
  [enums.user.level.GOLD]: "黃金",
  [enums.user.level.PLATINUM]: "白金",
  [enums.user.level.DIAMOND]: "鑽石",
};

export const genderLabels = {
  [enums.user.genders.MALE]: "男",
  [enums.user.genders.FEMALE]: "女",
  [enums.user.genders.NOT_SPECIFIED]: "未指定",
};
