import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得種族列表
 * @param {PaginationParams} params
 */
export const getSpeciesList = function (params) {
  return useErrorHandler(
    request({
      url: "/species",
      method: "GET",
      params,
    }),
  );
};

/**
 * 更新種族
 * @param {string} id 種族ID
 * @param {Object} data
 */
export const updateSpecies = function (id, data) {
  return useErrorHandler(
    request({
      url: `/species/${id}`,
      method: "PATCH",
      data,
    }),
  );
};
