<script setup>
import {
  ElAvatar,
  ElCard,
  ElDivider,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
} from "element-plus";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import RecursiveMenu from "@/components/RecursiveMenu/ElRecursiveMenu.vue";
import useBreakpoints from "@/composables/useBreakpoints";
import defaultMenu, { devMenu } from "@/constants/menu";
import * as enums from "@/enums";
import { useAuthStore } from "@/stores/auth";

const router = useRouter();
const authStore = useAuthStore();
const $breakpoints = useBreakpoints();
const collapsed = ref(true);
const sidebarWidth = ref(300);
const isDesktop = computed(() => $breakpoints.lg.value);

const menuList = ref(defaultMenu);

const logout = () => {
  authStore.logout();
  router.replace({ name: enums.route.names.LOGIN });
};

watch(
  () => isDesktop.value,
  (val) => {
    collapsed.value = !val;
  },
);

collapsed.value = !isDesktop.value;

if (import.meta.env.DEV) {
  devMenu.forEach((item) => {
    menuList.value.unshift(item);
  });
}
</script>

<template>
  <header
    class="fixed left-0 top-0 z-50 col-span-full flex h-20 w-full items-center gap-2 bg-white py-2 pl-2 pr-5 shadow"
  >
    <RouterLink
      :to="{ name: enums.route.names.HOME }"
      class="flex items-center justify-center max-lg:!w-0"
      :style="{
        width: sidebarWidth + 'px',
      }"
    >
      <img src="@/assets/images/logo.png" alt="logo" class="w-1/2" />
    </RouterLink>
    <button @click="collapsed = !collapsed">
      <span class="material-symbols-outlined !text-3xl text-gray-400">
        {{ collapsed ? "menu" : "menu_open" }}
      </span>
    </button>
    <div class="flex flex-1 items-center justify-end gap-1">
      <ElDropdown>
        <ElAvatar
          :src="authStore.user?.avatar"
          class="!h-10 !w-10 !bg-transparent"
        >
          <button
            class="material-symbols-outlined !text-4xl text-primary outline-none"
          >
            account_circle
          </button>
        </ElAvatar>
        <template #dropdown>
          <ElDropdownMenu>
            <ElDropdownItem @click="logout">登出</ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>
      <small> Hi, {{ authStore.user?.name }} </small>
    </div>
  </header>
  <aside
    class="fixed left-0 top-20 bg-opacity-20 py-5 pl-5 transition-all duration-300"
    style="height: calc(100vh - 5rem)"
    :style="{
      width: sidebarWidth + 'px',
    }"
    :class="{
      'translate-x-0': !collapsed,
      '-translate-x-full': collapsed,
    }"
  >
    <ElCard
      class="max-h-full !overflow-auto !rounded-xl"
      :body-style="{
        padding: '0',
      }"
    >
      <RecursiveMenu
        :data="menuList"
        mode="vertical"
        class="mb-[30vh]"
        @select="
          () => {
            if (!isDesktop) collapsed = true;
          }
        "
      />
    </ElCard>
  </aside>
  <section
    class="mt-20 p-5 transition-all duration-300"
    :style="{
      marginLeft: collapsed ? '0' : sidebarWidth + 'px',
    }"
  >
    <main class="min-h-[75vh]">
      <RouterView />
    </main>
    <ElDivider>
      <footer class="flex items-center justify-center gap-2 whitespace-nowrap">
        <img src="/favicon.ico" alt="favicon" class="w-6" />
        <small>Copyright <sup>&copy;</sup> AiCreate360</small>
      </footer>
    </ElDivider>
  </section>
</template>
