<script setup>
import { onMounted, ref } from "vue";

import { devConsole } from "@/libs/helpers";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({
      method: "share",
    }),
  },
});

const emit = defineEmits(["shared"]);

const isFBReady = ref(false);
const loading = ref(false);

const shareToFacebook = () => {
  if (!isFBReady.value) {
    alert("FB SDK is not installed or ready yet.");
    return;
  }
  loading.value = true;
  window.FB.ui(props.params, (response) => {
    loading.value = false;
    emit("shared", response);
    if (!response.error_message) {
      devConsole.log("Posting completed.", { response });
    } else {
      devConsole.log("Error while posting.", { response });
      alert("Error while posting.");
    }
  });
};

onMounted(() => {
  // check if FB SDK is loaded
  isFBReady.value = !!window.FB; // 如果已經載入過 FB SDK，則直接設定為 true
  window.addEventListener("fb-sdk-ready", () => {
    isFBReady.value = true; // 如果是第一次載入 FB SDK，則設定為 true
  });
});
</script>

<template>
  <button :disabled="!isFBReady" @click="shareToFacebook">
    <slot
      :ready="isFBReady"
      :loading="loading"
      :share-to-facebook="shareToFacebook"
    />
  </button>
</template>
