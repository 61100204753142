<script setup>
import { ElCard, ElTabPane, ElTabs } from "element-plus";
import { ref } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";

import * as APIs from "@/APIs";
import * as enums from "@/enums";

const router = useRouter();
const route = useRoute();

const loading = ref(true);
const tabs = ref([]);
const childrenRef = ref(null);

APIs.FAQ.getFAQcategories()
  .then((res) => {
    tabs.value = res.result.map((item) => ({
      label: item.name,
      name: item.id,
    }));
    if (!route.params.categoryId) {
      router.push({
        name: enums.route.names.FAQS,
        params: { categoryId: tabs.value[0].name },
      });
    }
  })
  .finally(() => {
    loading.value = false;
  });

const onBeforeTabLeave = () => {
  return childrenRef.value?.onBeforeTabLeave() ?? Promise.resolve();
};
</script>

<template>
  <ElCard v-loading="loading" class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElTabs
      v-if="route.params.categoryId"
      :model-value="route.params.categoryId"
      :before-leave="onBeforeTabLeave"
      @tab-change="
        (categoryId) => {
          $router.push({
            name: enums.route.names.FAQS,
            params: { categoryId },
          });
        }
      "
    >
      <ElTabPane
        v-for="tab in tabs"
        :key="tab.name"
        :name="tab.name"
        :label="tab.label"
      />
    </ElTabs>
    <RouterView v-slot="{ Component }">
      <component ref="childrenRef" :is="Component" />
    </RouterView>
  </ElCard>
</template>

<style lang="scss" scoped></style>
