import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得資金池水位資訊（system）
 */
export const getPoolInfo = function () {
  return useErrorHandler(
    request({
      url: "/users/system/vcoin-info",
      method: "GET",
    }),
  );
}

/**
 * 添加資金池水位
 * @param {Object} data
 * @param {Number} data.coins - 水位金額
 * @param {String} data.reason - 原因
 */
export const addPool = function (data) {
  return useErrorHandler(
    request({
      url: "/wallets/increase-system-coins",
      method: "POST",
      data,
    }),
  );
}