<script setup>
import { ElDialog, ElMessage, ElProgress, ElUpload } from "element-plus";
import { ref } from "vue";

import * as APIs from "@/APIs";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  limit: {
    type: Number,
    default: 5,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "change"]);

const progress = ref(0);
const previewURL = ref("");
const abortController = ref();
const dialogVisible = ref(false);
const actionVisible = ref(false);

/**
 * @param {File} file
 */
const beforeUpload = (file) => {
  if (file.size > props.limit * 1024 * 1024) {
    ElMessage.error(`檔案大小超過 ${props.limit}MB`);
    return false;
  }
  return true;
};

const httpRequest = (options) => {
  const { file } = options;

  previewURL.value = URL.createObjectURL(file);
  abortController.value = new AbortController();

  const interval = setInterval(() => {
    if (progress.value < 99) {
      progress.value += 3;
      options.onProgress({
        percent: progress.value,
      });
    } else clearInterval(interval);
  }, 100);

  return APIs.common
    .uploadImage(file, {
      signal: abortController.value.signal,
      // onUploadProgress: (e) => {
      //   progress.value = Math.round((e.loaded / e.total) * 100);
      // },
    })
    .then((res) => {
      progress.value = 100;
      const { result } = res;
      emit("update:modelValue", result);
      emit("change", result);
    })
    .catch(options.onError)
    .finally(() => {
      clearInterval(interval); // 停止 interval 的執行
      previewURL.value = "";
      setTimeout(() => {
        progress.value = 0;
      }, 1000);
    });
};

const cancelUpload = () => {
  abortController.value?.abort();
};
</script>

<template>
  <div
    class="relative"
    @mouseenter="actionVisible = true"
    @mouseleave="actionVisible = false"
  >
    <template v-if="previewURL || modelValue">
      <slot name="preview" :src="previewURL || modelValue">
        <img :src="previewURL || modelValue" class="w-full" />
      </slot>
      <Transition name="el-fade-in-linear">
        <div
          v-if="progress > 0"
          class="absolute left-0 top-0 h-full w-full bg-[#fff] bg-opacity-50"
        >
          <ElProgress
            :percentage="progress"
            :status="progress >= 100 ? 'success' : ''"
            type="circle"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </Transition>
      <Transition name="el-fade-in-linear">
        <div
          v-if="actionVisible"
          class="absolute left-0 top-0 flex h-full w-full items-center justify-center gap-3 bg-black bg-opacity-50"
        >
          <button
            v-if="modelValue"
            type="button"
            class="text-2xl text-white outline-none transition hover:opacity-70"
            @click.prevent="dialogVisible = true"
          >
            <span class="material-symbols-outlined">zoom_in</span>
          </button>
          <button
            v-if="!disabled"
            type="button"
            class="text-2xl text-white outline-none transition hover:opacity-70"
            :disabled="disabled"
            @click="
              () => {
                if (previewURL) cancelUpload();
                else emit('update:modelValue', '');
              }
            "
          >
            <span class="material-symbols-outlined">delete</span>
          </button>
        </div>
      </Transition>
    </template>
    <ElUpload
      v-else
      drag
      accept="image/*"
      :disabled="disabled"
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
    >
      <slot name="trigger">
        <div class="px-2 py-10">
          <span class="material-symbols-outlined text-3xl">cloud_upload</span>
          <div class="el-upload__text !text-xs">
            將圖片拖到此處，或<em>點擊上傳</em>
          </div>
          <small class="text-gray-400">檔案大小限制：{{ limit }}MB</small>
        </div>
      </slot>
      <template #tip>
        <slot name="tips" :limit="limit" />
      </template>
    </ElUpload>
    <ElDialog v-model="dialogVisible" class="max-w-md" append-to-body>
      <img :src="modelValue" class="w-full" />
    </ElDialog>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-upload-dragger) {
  @apply p-0;
}
</style>
