<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElOption,
  ElPageHeader,
  ElPopover,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import numeral from "numeral";
import { computed, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import SerialUploadPrompt from "@/components/Prize/SerialUploadPrompt.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  prize_group_id: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const prizeGroupList = ref([]);
const prizeGroupLoading = ref(false);

const serialUploadPromptRef = ref();

const handleFetch = (query) => {
  loading.value = true;
  APIs.prize
    .getPrizes({
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      relationships: ["users"],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data.map((item) =>
        helpers.dataConverter(item, {
          redeemed_at: dayjs(item.redeemed_at).format(),
          expired_at: dayjs(item.expired_at).format(),
          created_at: dayjs(item.created_at).format(),
          updated_at: dayjs(item.updated_at).format(),
          content: JSON.parse,
          user_ids: item.users?.map?.((user) => user.id).join("\n"),
        }),
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const handleFetchOverview = () => {
  prizeGroupLoading.value = true;
  APIs.prize
    .getPrizeRecordsOverview()
    .then((res) => {
      prizeGroupList.value = res.result;
    })
    .finally(() => {
      prizeGroupLoading.value = false;
    });
};

onMounted(() => {
  handleFetch(params.value);
  handleFetchOverview();
});
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: '培育間摸彩管理',
          to: { name: enums.route.names.PRIZES },
        },
        { label: $route.meta.title },
      ]"
    />
    <ElCard class="!rounded-xl">
      <ElPageHeader
        class="mb-3"
        @back="$router.push({ name: enums.route.names.PRIZES })"
      >
        <template #content>
          <h1 class="text-2xl font-bold">{{ $route.meta.title }}</h1>
        </template>
      </ElPageHeader>
      <ElForm inline label-width="100">
        <ElFormItem label="項目:">
          <ElSelect
            v-model="searchParams.prize_group_id"
            :loading="prizeGroupLoading"
            filterable
            clearable
            @change="handleFetch(params)"
          >
            <ElOption
              v-for="item in prizeGroupList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </ElSelect>
        </ElFormItem>
      </ElForm>
      <ElPopover placement="right-start" :width="400" trigger="click">
        <template #reference>
          <ElButton type="info" round class="my-5">點數總覽</ElButton>
        </template>
        <ElTable
          v-loading="prizeGroupLoading"
          :data="prizeGroupList"
          width="100%"
        >
          <ElTableColumn width="100" prop="name" label="項目" fixed="left" />
          <ElTableColumn
            width="80"
            prop="prizes_count"
            label="總筆數"
            align="center"
          />
          <ElTableColumn
            width="80"
            prop="redeemed_prizes_count"
            label="使用數量"
            align="center"
          />
          <ElTableColumn
            width="80"
            prop="unredeemed_prizes_count"
            label="剩餘數量"
            align="center"
          />
        </ElTable>
      </ElPopover>
      <ElPaginationTable
        :data="list"
        v-model:pagination="paginationParams"
        v-model:sort="sortParams"
        :loading="loading"
        paginationBackground
        @page-change="(page) => handleFetch({ ...params, page })"
        @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
        @sort-change="
          (sort) =>
            handleFetch({
              ...params,
              order_by: sort.prop,
              order_direction: sort.order,
            })
        "
        @selection-change="
          (selection) => {
            multipleSelection = selection;
          }
        "
      >
        <ElTableColumn
          label="名稱"
          min-width="150"
          :formatter="
            ({ prize_group_id }) =>
              prizeGroupList.find((item) => item.id === prize_group_id)?.name ||
              '-'
          "
        />
        <ElTableColumn
          label="項目"
          min-width="150"
          :formatter="({ type }) => constants.prize.typeLabels[type]"
        />
        <ElTableColumn prop="content.serial_no" label="序號" min-width="300" />
        <ElTableColumn
          label="面額"
          align="center"
          min-width="100"
          :formatter="({ value }) => numeral(value).format()"
        />
        <ElTableColumn prop="expired_at" label="到期時間" min-width="150" />
        <ElTableColumn
          label="使用狀態"
          min-width="100"
          :formatter="({ redeemed_at }) => (redeemed_at ? '已使用' : '-')"
        />
        <ElTableColumn prop="content.note" label="活動備註" min-width="200" />
      </ElPaginationTable>
    </ElCard>
    <SerialUploadPrompt ref="serialUploadPromptRef" />
  </div>
</template>
