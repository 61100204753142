export const roles = {
  /** 超級管理者 */
  SUPER_ADMIN: "super_admin",
  /** 管理者 */
  ADMIN: "admin",
  /** 營運 */
  EDITOR: "editor",
  /** 兼職 */
  PART_TIME: "part_time",
  /** 玩家 */
  USER: "user",
};

export const level = {
  INITIAL: "initial",
  BRONZE: "bronze",
  SILVER: "silver",
  GOLD: "gold",
  PLATINUM: "platinum",
  DIAMOND: "diamond",
};

export const genders = {
  MALE: 0,
  FEMALE: 1,
  NOT_SPECIFIED: 2,
};
