<script setup>
import {
  ElButton,
  ElCard,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElNotification,
  ElSkeleton,
  ElSkeletonItem,
} from "element-plus";
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import VueDraggable from "vuedraggable";

import * as APIs from "@/APIs";

const props = defineProps({
  categoryId: String,
});

defineExpose({
  onBeforeTabLeave: () => {
    if (modified.value) {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(`尚有未保存的修改，確定離開嗎？`, "警告", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(resolve)
          .catch(reject);
      });
    }
    return Promise.resolve();
  },
});

const drag = ref(false);
const loading = ref(false);
const dialogVisible = ref(false);
const list = ref([]);
const formRef = ref(null);
const formData = ref({
  title: "",
  content: "",
});
const formRules = ref({
  title: [{ required: true, message: "請輸入問題" }],
  content: [{ required: true, message: "請輸入解答" }],
});
const cacheList = ref([]);
const modified = computed(() => list.value.some((item) => item.modified));

const mapCallback = (item) => ({
  id: item.id,
  faq_category_id: item.faq_category_id,
  title: item.title,
  content: item.content,
  order: item.order,
});

const cloneFromCache = () => {
  list.value = JSON.parse(JSON.stringify(cacheList.value));
};

const cloneToCache = () => {
  cacheList.value = JSON.parse(JSON.stringify(list.value));
};

const checkItemModified = (item) => {
  const cacheItem = cacheList.value.find((i) => i.id === item.id);
  if (item.title !== cacheItem?.title || item.content !== cacheItem?.content) {
    item.modified = true;
  } else {
    item.modified = false;
  }
};

const resetForm = () => {
  formRef.value.resetFields();
  dialogVisible.value = false;
};

/**
 * Promise handler for change data
 * @param {Promise} promise
 * @returns {Promise}
 */
const promiseHandler = (promise) => {
  loading.value = true;
  return promise
    .then((res) => {
      list.value.forEach((item) => {
        item.modified = false;
      });
      cloneToCache();
      return res;
    })
    .catch(() => {
      cloneFromCache();
      return Promise.reject();
    })
    .finally(() => {
      loading.value = false;
    });
};

const fetchList = (id) => {
  loading.value = true;
  APIs.FAQ.getFAQs({
    // columns: ["id", "faq_category_id", "title", "content", "order"],
    filters: [JSON.stringify(["faq_category_id", "=", id])],
    order_by: "order",
    order_direction: "asc",
  })
    .then((res) => {
      cacheList.value = res.result.map((item) => ({
        ...mapCallback(item),
        modified: false,
      }));
      cloneFromCache();
    })
    .finally(() => {
      loading.value = false;
    });
};

const onCreate = () => {
  formRef.value.validate((valid) => {
    const order = list.value.length
      ? list.value.sort((a, b) => b.order - a.order)[0].order + 1
      : 0;
    if (valid) {
      promiseHandler(
        APIs.FAQ.createFAQ({
          faq_category_id: props.categoryId,
          title: formData.value.title,
          content: formData.value.content,
          order,
        }).then((res) => {
          list.value.push({
            ...mapCallback(res.result),
            modified: false,
          });
          resetForm();
        }),
      ).then(() => {
        ElNotification({
          title: "新增成功",
          type: "success",
        });
      });
    }
  });
};

const onReOrder = () => {
  const orderedList = list.value
    .filter((item, i) => {
      if (item.order !== i) {
        item.order = i;
        return true;
      }
      return false;
    })
    .map((item) => ({
      id: item.id,
      order: item.order,
    }));
  promiseHandler(
    APIs.FAQ.updateFAQs({
      data: orderedList,
    }),
  ).then(() => {
    ElNotification({
      title: "排序成功",
      type: "success",
    });
  });
};

const onUpdate = () => {
  const updatedList = list.value
    .filter((item) => item.modified)
    .map((item) => ({
      id: item.id,
      title: item.title,
      content: item.content,
    }));
  promiseHandler(
    APIs.FAQ.updateFAQs({
      data: updatedList,
    }),
  ).then(() => {
    ElNotification({
      title: "更新成功",
      type: "success",
    });
  });
};

const onDelete = async (id) => {
  promiseHandler(
    APIs.FAQ.deleteFAQ(id).then(() => {
      list.value = list.value.filter((item) => item.id !== id);
      ElNotification({
        title: "刪除成功",
        type: "success",
      });
    }),
  );
};

onMounted(() => {
  fetchList(props.categoryId);
});

onBeforeRouteUpdate(async (to, __, next) => {
  fetchList(to.params.categoryId);
  next();
});
</script>

<template>
  <div class="max-w-screen-lg">
    <ElSkeleton :loading="loading" animated>
      <template #template>
        <template v-for="i in list.length || 5" :key="i">
          <ElCard class="mb-3" body-class="px-3">
            <ElSkeletonItem variant="h3" />
            <ElSkeletonItem variant="caption" />
            <ElSkeletonItem variant="caption" />
          </ElCard>
        </template>
      </template>
    </ElSkeleton>
    <VueDraggable
      v-if="!loading"
      v-model="list"
      item-key="id"
      ghostClass="opacity-30"
      handle=".material-symbols-outlined"
      :animation="150"
      :component-data="{
        tag: 'ul',
        type: 'transition-group',
      }"
      @start="drag = true"
      @end="
        () => {
          drag = false;
          onReOrder();
        }
      "
    >
      <template #item="{ element }">
        <li class="list-none">
          <ElCard class="mb-3" body-class="px-3">
            <div class="flex items-start gap-2">
              <span
                class="material-symbols-outlined cursor-grab text-3xl"
                :class="{
                  'cursor-grabbing': drag,
                }"
              >
                drag_indicator
              </span>
              <div class="flex flex-1 flex-col gap-2">
                <ElInput
                  v-model="element.title"
                  @input="checkItemModified(element)"
                />
                <ElInput
                  v-model="element.content"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                  @input="checkItemModified(element)"
                />
              </div>
              <span
                class="material-symbols-outlined cursor-pointer text-3xl text-danger"
                @click="
                  () => {
                    ElMessageBox.confirm(
                      `確定要刪除${element.title}嗎？`,
                      '警告',
                      {
                        confirmButtonText: '確定',
                        cancelButtonText: '取消',
                        type: 'warning',
                      },
                    )
                      .then(() => {
                        onDelete(element.id);
                      })
                      .catch(() => {});
                  }
                "
              >
                delete
              </span>
            </div>
          </ElCard>
        </li>
      </template>
    </VueDraggable>
    <ElButton
      type="success"
      circle
      class="mx-auto mb-3 block"
      @click="dialogVisible = true"
    >
      <span class="material-symbols-outlined">add</span>
    </ElButton>
    <ElButton
      type="primary"
      class="mx-auto block"
      :disabled="!modified"
      @click="onUpdate"
    >
      確認更新
    </ElButton>
    <ElDialog
      v-model="dialogVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <ElForm
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <ElFormItem prop="title" label="問題">
          <ElInput v-model="formData.title" />
        </ElFormItem>
        <ElFormItem prop="content" label="解答">
          <ElInput
            v-model="formData.content"
            type="textarea"
            :autosize="{ minRows: 2 }"
          />
        </ElFormItem>
        <ElFormItem>
          <div class="flex w-full justify-center">
            <ElButton type="info" :disabled="loading" @click="resetForm">
              取消
            </ElButton>
            <ElButton
              type="primary"
              :loading="loading"
              :disabled="loading"
              @click="onCreate"
            >
              確認
            </ElButton>
          </div>
        </ElFormItem>
      </ElForm>
    </ElDialog>
  </div>
</template>
