<script setup lang="ts">
import { ElForm, ElFormItem, ElMessage } from "element-plus";
import { ref } from "vue";

import * as APIs from "@/APIs";

import ElPrompt from "../ElPrompt.vue";

const loading = ref(false);
const promptRef = ref();
const promptFormRef = ref();
const promptFormData = ref({
  file: null,
});
const promptFormRules = {
  file: [{ required: true, message: "請選擇檔案" }],
};

const openPrompt = ({ id, name }) => {
  return new Promise((resolve) => {
    promptRef.value
      .open({
        title: `上傳序號到 ${name}`,
        beforeResolve: () =>
          promptFormRef.value
            .validate()
            .then(async () => {
              loading.value = true;
              await APIs.prize.uploadPrizeGroupSerials(
                id,
                promptFormData.value.file,
              );
              return true;
            })
            .catch(() => false),
      })
      .then(() => {
        ElMessage.success("上傳成功");
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      })
      .finally(() => {
        promptFormData.value.file = null;
        promptFormRef.value.resetFields();
        loading.value = false;
      });
  });
};

defineExpose({
  openPrompt,
});
</script>

<template>
  <ElPrompt ref="promptRef" width="500">
    <ElForm
      ref="promptFormRef"
      :model="promptFormData"
      :rules="promptFormRules"
      label-position="top"
      @submit.prevent
    >
      <ElFormItem prop="file" label="上傳檔案">
        <label class="block">
          <input
            name="file"
            type="file"
            accept=".csv"
            class="block w-full text-sm text-slate-500 file:mr-4 file:!rounded-full file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-violet-700 hover:file:bg-violet-100 file:disabled:cursor-not-allowed file:disabled:bg-gray-100 file:disabled:text-gray-400"
            @change="
              (e) => {
                promptFormData.file = e.target.files[0];
              }
            "
          />
        </label>
      </ElFormItem>
    </ElForm>
  </ElPrompt>
</template>

<style lang="scss" scoped></style>
