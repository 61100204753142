<script setup>
import { ElButton, ElDialog } from "element-plus";
import { h, ref, shallowRef } from "vue";

import { devConsole } from "@/libs/helpers";

defineProps({
  width: [String, Number],
  closable: {
    type: Boolean,
    default: true,
  },
});

const dialogVisible = ref(false);
const loading = ref(false);
const title = ref("");
const message = shallowRef("");
const beforeResolve = ref(() => Promise.resolve(true));
const showCancelButton = ref(true);
const showConfirmButton = ref(true);
const type = ref("");

const promptResolve = ref(null);
const promptReject = ref(null);

/**
 * @param {string|import("vue").VNode} message
 */
const messageRenderFunction = (message) => ({
  render() {
    return h("p", { style: "margin-bottom: 0.75rem" }, message);
  },
});

const open = (configs) => {
  devConsole.log("prompt opened", configs);
  if (configs.title) title.value = configs.title;
  if (configs.message) message.value = messageRenderFunction(configs.message);
  if (configs.beforeResolve) beforeResolve.value = configs.beforeResolve;
  if ("showCancelButton" in configs)
    showCancelButton.value = configs.showCancelButton;
  if ("showConfirmButton" in configs)
    showConfirmButton.value = configs.showConfirmButton;
  if (configs.type) type.value = configs.type;
  dialogVisible.value = true;

  return new Promise((resolve, reject) => {
    promptResolve.value = resolve;
    promptReject.value = reject;
  });
};

const close = () => {
  dialogVisible.value = false;
};

const confirm = async () => {
  try {
    loading.value = true;
    const pass = await beforeResolve.value();
    if (pass) {
      close();
      promptResolve.value();
    }
  } catch (error) {
    cancel(error);
  } finally {
    loading.value = false;
  }
};

const cancel = (error) => {
  close();
  promptReject.value(error);
};

defineExpose({
  open,
  close,
  confirm,
  cancel,
});
</script>

<template>
  <ElDialog
    :model-value="dialogVisible"
    :width="width"
    destroy-on-close
    append-to-body
    class="max-w-full"
    :before-close="
      (done) => {
        if (loading || !closable) return;
        done();
      }
    "
    @close="cancel"
  >
    <template #header>
      <slot name="header">
        <h3 v-if="title" class="text-xl font-bold">{{ title }}</h3>
      </slot>
    </template>
    <component v-if="message" :is="message" />
    <slot />
    <template #footer>
      <slot name="footer" :cancel="cancel" :confirm="confirm">
        <div class="flex justify-end">
          <ElButton
            v-if="showCancelButton"
            type="info"
            :disabled="loading || !closable"
            @click="cancel"
          >
            取消
          </ElButton>
          <ElButton
            v-if="showConfirmButton"
            type="primary"
            :disabled="loading || !closable"
            :loading="loading"
            @click="confirm"
          >
            確定
          </ElButton>
        </div>
      </slot>
    </template>
  </ElDialog>
</template>
