<script setup>
import { ElMenuItem, ElSubMenu } from "element-plus";
import { RouterLink } from "vue-router";

import { useAuthStore } from "@/stores/auth";

import ElRecursiveMenuItem from "./ElRecursiveMenuItem.vue";

const authStore = useAuthStore();

defineProps({
  data: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <RouterLink
    v-if="authStore.hasRoles(data.roles)"
    custom
    :to="data.goto"
    v-slot="{ href, isActive }"
  >
    <ElSubMenu v-if="data.children" :index="href">
      <template #title>
        <span
          class="material-symbols-outlined mb-px mr-2 mt-1"
          :class="{
            'text-info': !isActive,
          }"
          v-text="isActive ? 'folder_open' : 'folder'"
        />
        {{ data.title }}
      </template>
      <ElRecursiveMenuItem
        v-for="(children, i) in data.children"
        :data="children"
        :key="i"
      />
    </ElSubMenu>
    <ElMenuItem
      v-else
      :index="isActive ? $route.path : href"
      class="!leading-none"
    >
      <slot name="title" v-bind="data">
        <span
          v-if="data.icon"
          class="material-symbols-outlined mb-px mr-2 mt-1"
          :class="{
            'text-info': !isActive,
          }"
          v-text="data.icon"
        />
        {{ data.title }}
      </slot>
    </ElMenuItem>
  </RouterLink>
</template>

<style lang="scss" scoped>
.el-sub-menu :deep(.el-sub-menu__title),
.el-menu-item {
  @apply font-bold text-neutral-600;
}
.el-sub-menu.is-active :deep(.el-sub-menu__title),
.el-menu-item.is-active {
  @apply text-primary;
}
.el-menu-item.is-active {
  @apply bg-primary-50;
}
</style>
