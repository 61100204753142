import * as enums from "@/enums";

export const devMenu = [
  {
    icon: "construction",
    title: "Playground",
    goto: { name: enums.route.names.PLAYGROUND },
  },
];

export default [
  {
    icon: "build",
    title: "工具箱",
    goto: { name: enums.route.names.TOOLBOX },
  },
  // {
  //   roles: [
  //     enums.user.roles.SUPER_ADMIN,
  //     enums.user.roles.ADMIN,
  //     enums.user.roles.EDITOR,
  //   ],
  //   icon: "space_dashboard",
  //   title: "總覽",
  //   goto: { name: enums.route.names.DASHBOARD },
  // },
  {
    roles: [enums.user.roles.SUPER_ADMIN, enums.user.roles.ADMIN],
    title: "權限管理",
    goto: { name: enums.route.names.ADMIN },
    children: [
      {
        roles: [enums.user.roles.SUPER_ADMIN],
        icon: "admin_panel_settings",
        title: "管理者設定",
        goto: { name: enums.route.names.MANAGERS },
      },
      {
        icon: "account_circle",
        title: "會員管理",
        goto: { name: enums.route.names.USERS },
      },
    ],
  },
  {
    title: "任務管理",
    goto: { name: enums.route.names.TASKS_TABS },
    children: [
      {
        icon: "task",
        title: "任務管理",
        goto: { name: enums.route.names.TASKS_TABS },
      },
      // {
      //   roles: [
      //     enums.user.roles.SUPER_ADMIN,
      //     enums.user.roles.ADMIN,
      //     enums.user.roles.EDITOR,
      //   ],
      //   icon: "task_alt",
      //   title: "題庫管理",
      //   goto: { name: enums.route.names.TASKABLES_TABS },
      // },
    ],
  },
  {
    roles: [enums.user.roles.SUPER_ADMIN, enums.user.roles.ADMIN],
    title: "培育間管理",
    goto: { name: enums.route.names.CULTIVATE },
    children: [
      {
        icon: "raven",
        title: "種族管理",
        goto: { name: enums.route.names.SPECIES_LIST },
      },
      {
        icon: "pets",
        title: "妖獸管理",
        goto: { name: enums.route.names.SUBSPECIES_LIST },
      },
    ],
  },
  {
    roles: [
      enums.user.roles.SUPER_ADMIN,
      enums.user.roles.ADMIN,
      enums.user.roles.EDITOR,
    ],
    title: "獎勵區管理",
    goto: { name: enums.route.names.AWARD },
    children: [
      {
        icon: "store",
        title: "商品管理",
        goto: { path: "/award/products" },
      },
      {
        icon: "package_2",
        title: "訂單管理",
        goto: { name: enums.route.names.ORDERS },
      },
    ],
  },
  {
    title: "抽獎管理",
    goto: { name: enums.route.names.LOTTERY },
    children: [
      {
        icon: "redeem",
        title: "抽獎管理",
        goto: { name: enums.route.names.DRAWS },
      },
      {
        roles: [
          enums.user.roles.SUPER_ADMIN,
          enums.user.roles.ADMIN,
          enums.user.roles.EDITOR,
        ],
        icon: "confirmation_number",
        title: "培育間摸彩",
        goto: { name: enums.route.names.PRIZES },
      },
    ],
  },
  {
    roles: [
      enums.user.roles.SUPER_ADMIN,
      enums.user.roles.ADMIN,
      enums.user.roles.EDITOR,
    ],
    icon: "chat",
    title: "訊息管理",
    goto: { name: enums.route.names.MESSAGES },
  },
  {
    icon: "contact_support",
    title: "QA管理",
    goto: { name: enums.route.names.FAQS_TABS },
  },
  {
    roles: [enums.user.roles.SUPER_ADMIN],
    icon: "paid",
    title: "資金池管理",
    goto: { name: enums.route.names.COIN_POOL },
  },
  {
    roles: [
      enums.user.roles.SUPER_ADMIN,
      enums.user.roles.ADMIN,
      enums.user.roles.EDITOR,
    ],
    title: "廠商管理",
    goto: { name: enums.route.names.VENDORS },
    children: [
      {
        icon: "storefront",
        title: "品牌商管理",
        goto: { name: enums.route.names.BRANDS },
      },
      {
        icon: "inventory_2",
        title: "供應商管理",
        goto: { name: enums.route.names.SUPPLIERS },
      },
    ],
  },
];
