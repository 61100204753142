<script setup>
import { ElCard } from "element-plus";
import numeral from "numeral";
import { ref } from "vue";

import Chart from "@/components/ChartJs.vue";

const newUserAmount = ref(110030);
const totalUserAmount = ref(230000);
</script>

<template>
  <div>
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <div class="grid gap-5 md:grid-cols-2">
      <ElCard class="!rounded-xl">
        <h3 class="mb-3 text-xl font-bold">會員數</h3>
        <div class="relative aspect-square max-w-xs">
          <Chart
            type="doughnut"
            :data="{
              datasets: [
                {
                  label: '# of Votes',
                  backgroundColor: ['#ebeff4', '#a660a3'],
                  data: [totalUserAmount - newUserAmount, newUserAmount],
                },
              ],
            }"
            :options="{
              cutout: '70%',
            }"
          />
          <svg
            viewBox="0 0 100 100"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <foreignObject x="0" y="0" width="100" height="100">
              <div
                class="flex h-full flex-col items-center justify-center gap-2"
              >
                <p class="text-2xl font-bold">
                  {{ numeral(totalUserAmount).format() }}
                </p>
                <span class="text-xs">人</span>
              </div>
            </foreignObject>
          </svg>
        </div>
      </ElCard>
    </div>
  </div>
</template>

<style scoped></style>
