<script setup>
import { ElTag, ElPopover } from "element-plus";
import { computed } from "vue";

import ElContrastTag from "@/components/ElContrastTag.vue";

const props = defineProps({
  tags: {
    type: Array,
    required: true,
    validator: (tags) => tags?.every?.((tag) => tag.color && tag.name),
  },
  collapse: Boolean,
  collapseThreshold: {
    type: Number,
    default: 1,
  },
});

const tagsShown = computed(() => {
  return props.tags.splice.apply(
    props.tags,
    props.collapse ? [0, props.collapseThreshold] : [0],
  );
});
const tagsCollapsed = computed(() => {
  return props.tags.splice.apply(
    props.tags,
    props.collapse ? [props.collapseThreshold] : [0],
  );
});
</script>

<template>
  <div>
    <template
      v-for="(tag, i) in tagsShown"
      :key="`${i}-${tag.name}-${tag.color}`"
    >
      <slot>
        <ElContrastTag :color="tag.color" :name="tag.name" />
      </slot>
    </template>
    <ElPopover
      v-if="tagsCollapsed.length > 1"
      placement="right"
      width="fit-content"
    >
      <template #reference>
        <ElTag class="ml-1 cursor-default">+{{ tagsCollapsed.length }}</ElTag>
      </template>
      <div class="flex max-w-xs flex-wrap items-center gap-1">
        <template
          v-for="(tag, i) in tagsCollapsed"
          :key="`${i}-${tag.name}-${tag.color}`"
        >
          <slot>
            <ElContrastTag :color="tag.color" :name="tag.name" />
          </slot>
        </template>
      </div>
    </ElPopover>
  </div>
</template>

<style lang="scss" scoped></style>
