<script setup>
import {
  ElAvatar,
  ElButton,
  ElCard,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElNotification,
  ElOption,
  ElPopover,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import * as APIs from "@/APIs";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElImageUpload from "@/components/ElImageUpload.vue";
import TinyMCE from "@/components/TinyMCE.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const props = defineProps({
  id: String,
});

const router = useRouter();
const formRef = ref();
const createMode = computed(() => props.id === enums.common.CREATE_MODE);
const loading = ref(false);
const sendToAllFlag = ref(false);
const sendToList = ref([]);

const formData = ref({
  file: null,
  title: "",
  description: "",
  message: "",
  image: "",
  url: "",
  type: "",
  published_at: "",
  created_by: "",
  updated_by: "",
  created_at: "",
  updated_at: "",
});

const formRules = computed(() => ({
  file: { required: !sendToAllFlag.value, message: "請上傳傳送對象" },
  title: { required: true, message: "請輸入訊息大標" },
  description: [
    { required: true, message: "請輸入訊息簡述" },
    { max: 255, message: "訊息簡述最多 255 字" },
  ],
  message: { required: true, message: "請輸入訊息內文" },
  image: { required: true, message: "請上傳訊息圖片" },
  url: { type: "url", message: "請輸入正確的連結位置" },
  type: { required: true, message: "請選擇訊息類別" },
  published_at: { required: true, message: "請選擇發佈時間" },
}));

const isPublished = computed(
  () =>
    !createMode.value && dayjs(formData.value.published_at).isBefore(dayjs()),
);

const handleSubmit = () => {
  formRef.value?.validate(async (valid, rules) => {
    if (!valid)
      ElNotification({
        title: "表單驗證錯誤",
        type: "error",
        message: Object.values(rules)[0][0].message,
      });
    else {
      try {
        loading.value = true;
        const data = helpers.pick(formData.value, [
          "file",
          "title",
          "description",
          "message",
          "image",
          "url",
          "type",
          "published_at",
        ]);
        if (isPublished.value) delete data.file;
        else if (sendToAllFlag.value) data.file = "";
        if (createMode.value)
          await APIs.message.createMessage(data).then((res) => {
            router.replace({ params: { id: res.result.id } });
          });
        else await APIs.message.updateMessage(props.id, data);
        ElNotification({
          title: `${createMode.value ? "新增" : "更新"}成功`,
          type: "success",
        });
      } finally {
        loading.value = false;
      }
    }
  });
};

if (!createMode.value) {
  loading.value = true;
  APIs.message
    .getMessage(props.id, {
      relationships: ["users"],
    })
    .then((res) => {
      delete res.result.id;
      formData.value = res.result;
      sendToList.value = res.result.users || [];
      if (sendToList.value.length === 0) sendToAllFlag.value = true;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: `${$route.meta.title}列表`,
          to: { name: enums.route.names.MESSAGES },
        },
        { label: createMode ? `${$route.meta.title}` : formData.title },
      ]"
    />
    <ElCard v-loading="loading" class="!rounded-xl">
      <h1 class="mb-3 text-2xl font-bold">
        {{ createMode ? "新增" : $route.meta.title }}
      </h1>
      <ElForm
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
        class="max-w-screen-lg"
      >
        <ElFormItem prop="file" label="傳送對象">
          <template v-if="!isPublished">
            <label class="block">
              <input
                type="file"
                accept=".csv"
                class="block w-full text-sm text-slate-500 file:mr-4 file:!rounded-full file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-violet-700 hover:file:bg-violet-100 file:disabled:cursor-not-allowed file:disabled:bg-gray-100 file:disabled:text-gray-400"
                :disabled="sendToAllFlag"
                @change="
                  (e) => {
                    formData.file = e.target.files[0];
                  }
                "
              />
            </label>
            <ElCheckbox v-model="sendToAllFlag" label="傳送給全體會員" />
          </template>
          <template v-if="!createMode && sendToList.length > 0">
            <div class="my-1 w-full" />
            <ElPopover
              placement="bottom-start"
              title="名單"
              trigger="click"
              width="fit-content"
            >
              <template #reference>
                <ElButton type="info" round size="small">查看名單</ElButton>
              </template>
              <ElTable
                :data="sendToList"
                table-layout="auto"
                size="small"
                max-height="300px"
              >
                <ElTableColumn width="60">
                  <template #default="{ row }">
                    <ElAvatar :src="row.avatar" class="!h-8 !w-8">
                      <span class="material-symbols-outlined">
                        broken_image
                      </span>
                    </ElAvatar>
                  </template>
                </ElTableColumn>
                <ElTableColumn prop="id" label="ID" min-width="250" />
                <ElTableColumn prop="name" label="姓名" min-width="80">
                  <template #default="{ row }">
                    <span class="whitespace-nowrap">{{ row.name }}</span>
                  </template>
                </ElTableColumn>
                <ElTableColumn prop="nickname" label="暱稱" min-width="100">
                  <template #default="{ row }">
                    <span class="whitespace-nowrap">{{ row.nickname }}</span>
                  </template>
                </ElTableColumn>
              </ElTable>
            </ElPopover>
          </template>
        </ElFormItem>
        <ElFormItem prop="type" label="訊息類別">
          <ElSelect v-model="formData.type" :disabled="isPublished">
            <ElOption
              v-for="(label, key) in constants.message.typeLabels"
              :key="key"
              :label="label"
              :value="key"
            />
          </ElSelect>
        </ElFormItem>
        <ElFormItem prop="published_at" label="發佈時間">
          <ElDatePicker
            v-model="formData.published_at"
            type="datetime"
            :disabled-date="(time) => time.getTime() < Date.now()"
            :disabled="isPublished"
          />
        </ElFormItem>
        <ElFormItem prop="image" label="訊息圖片">
          <img
            v-if="isPublished"
            :src="formData.image"
            class="aspect-square w-52 object-cover"
          />
          <ElImageUpload v-else v-model="formData.image">
            <template #preview="{ src }">
              <img :src="src" class="aspect-square w-52 object-cover" />
            </template>
          </ElImageUpload>
        </ElFormItem>
        <ElFormItem prop="url" label="連結位置">
          <ElInput v-model="formData.url" :disabled="isPublished" />
        </ElFormItem>
        <ElFormItem prop="title" label="訊息大標">
          <ElInput v-model="formData.title" :disabled="isPublished" />
        </ElFormItem>
        <ElFormItem prop="description" label="訊息簡述">
          <ElInput
            v-model="formData.description"
            type="textarea"
            placeholder="請輸入通知簡述（通知欄上顯示的說明文字）"
            :autosize="{ minRows: 2 }"
            :disabled="isPublished"
          />
        </ElFormItem>
        <ElFormItem prop="message" label="訊息內文">
          <TinyMCE
            v-model="formData.message"
            placeholder="請輸入公告內文（如有輸入連結位置則不須填寫）"
            class="w-full"
            :disabled="isPublished"
          />
        </ElFormItem>
        <ElFormItem>
          <div class="flex w-full items-center justify-center gap-3">
            <RouterLink :to="{ name: enums.route.names.MESSAGES }">
              <ElButton type="info" plain>返回</ElButton>
            </RouterLink>
            <ElButton
              type="primary"
              :disabled="isPublished"
              @click="handleSubmit"
            >
              儲存
            </ElButton>
          </div>
        </ElFormItem>
      </ElForm>
    </ElCard>
  </div>
</template>

<style lang="scss" scoped></style>
