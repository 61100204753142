import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得所有任務列表
 * @param {PaginationParams} params
 */
export const getTasks = function (params) {
  return useErrorHandler(
    request({
      url: "/tasks",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得任務詳細資料
 * @param {string} id
 * @param {object} [params]
 */
export const getTask = function (id, params) {
  return useErrorHandler(
    request({
      url: `/tasks/${id}`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 建立任務
 * @param {object} data
 */
export const createTask = function (data) {
  return useErrorHandler(
    request({
      url: "/tasks",
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新任務
 * @param {string} id
 * @param {object} data
 */
export const updateTask = function (id, data) {
  return useErrorHandler(
    request({
      url: `/tasks/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除任務
 * @param {string} id
 */
export const deleteTask = function (id) {
  return useErrorHandler(
    request({
      url: `/tasks/${id}`,
      method: "DELETE",
    }),
  );
};

/**
 * 批量新增任務
 * @param {File} file
 */
export const importTasks = function (file) {
  const formData = new FormData();
  formData.append("file", file);
  return useErrorHandler(
    request({
      url: "/tasks/import",
      method: "POST",
      data: formData,
    }),
    {
      disableNotification: true,
    },
  );
};

/**
 * 指派子任務（題目）
 * @typedef {object} Taskable
 * @property {string} id
 * @property {string} type - taskable_type 類型（告訴後端要去哪一張表查詢）
 * @property {number} order
 *
 * @param {string} id
 * @param {Taskable[]} taskables
 */
export const assignTaskables = function (id, taskables) {
  return useErrorHandler(
    request({
      url: `/tasks/${id}/assign`,
      method: "POST",
      data: { taskables },
    }),
  );
};

/**
 *解除綁定子任務（題目）
 * @param {string} task_id
 * @param {string} taskable_type 類型（告訴後端要去哪一張表查詢）
 * @param {string} taskable_id
 */
export const unbindTaskables = function (task_id, taskable_type, taskable_id) {
  return useErrorHandler(
    request({
      url: `/tasks/${task_id}/${taskable_type}/${taskable_id}`,
      method: "DELETE",
    }),
  );
};
