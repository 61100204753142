import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";

export const typeLabels = {
  [enums.task.types.RECOMMEND]: "推薦任務",
  [enums.task.types.DAILY]: "今日任務",
  [enums.task.types.CHECK_IN]: "簽到任務",
};

export const publishStatusLabels = {
  [enums.task.publishStatus.DRAFT]: "草稿",
  [enums.task.publishStatus.PENDING]: "待上線",
  [enums.task.publishStatus.PUBLISHED]: "已上線",
  [enums.task.publishStatus.EXPIRED]: "已結束",
};

export const publishStatusColors = {
  [enums.task.publishStatus.DRAFT]: "info",
  [enums.task.publishStatus.PENDING]: "warning",
  [enums.task.publishStatus.PUBLISHED]: "success",
  [enums.task.publishStatus.EXPIRED]: "danger",
};

export const publishStatusDescription = {
  [enums.task.publishStatus.DRAFT]: "尚未啟用",
  [enums.task.publishStatus.PENDING]: "已啟用，但尚未開始",
  [enums.task.publishStatus.PUBLISHED]: "已啟用，且已上線",
  [enums.task.publishStatus.EXPIRED]: "已啟用，但已結束",
};

export const publishStatusFilters = {
  [enums.task.publishStatus.DRAFT]: {
    status: enums.common.status.DISABLED,
  },
  [enums.task.publishStatus.PENDING]: {
    status: enums.common.status.ENABLED,
    start_at: [dayjs().format(), null],
  },
  [enums.task.publishStatus.PUBLISHED]: {
    status: enums.common.status.ENABLED,
    start_at: [null, dayjs().format()],
    expired_at: [dayjs().format(), null],
  },
  [enums.task.publishStatus.EXPIRED]: {
    status: enums.common.status.ENABLED,
    expired_at: [null, dayjs().format()],
  },
};
