<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElPopover,
  ElTableColumn,
  ElTooltip,
} from "element-plus";
import { computed, ref } from "vue";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElStatusSwitchPrompt from "@/components/ElStatusSwitchPrompt.vue";
import * as helpers from "@/libs/helpers";

const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  name: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  APIs.species
    .getSpeciesList({
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams(searchParams.value),
      relationships: ["rune", "brand"],
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data?.map((item) =>
        helpers.dataConverter(item, {
          totalSubspecies: item.images.length, // 總共幾個培育階段
          image: item.images.pop?.(),
        }),
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

handleFetch(params.value);
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
    >
      <ElTableColumn prop="image" label="種子圖" width="100">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElTooltip placement="top" content="鼠標移至圖示上可查看大圖">
              <button class="material-symbols-outlined">info</button>
            </ElTooltip>
          </div>
        </template>
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <img :src="row.image" class="aspect-square w-10" />
            </template>
            <img :src="row.image" class="w-40" />
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="name" label="種族" min-width="150" />
      <ElTableColumn prop="brand.name" label="品牌商" min-width="150" />
      <ElTableColumn
        prop="rune.name"
        label="符文名稱"
        min-width="100"
        :formatter="(row) => row.rune?.name.toUpperCase?.() || '無'"
      />
      <ElTableColumn prop="rune.image" label="符文樣式" min-width="110">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElTooltip placement="top" content="鼠標移至圖示上可查看大圖">
              <button class="material-symbols-outlined">info</button>
            </ElTooltip>
          </div>
        </template>
        <template #default="{ row }">
          <ElPopover placement="left" width="fit-content">
            <template #reference>
              <img :src="row.rune?.image" class="ml-2 aspect-square w-10" />
            </template>
            <img :src="row.rune?.image" class="w-40" />
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="rune.color" label="符文顏色" min-width="120">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElTooltip placement="top" content="點擊色塊可以複製">
              <button class="material-symbols-outlined">info</button>
            </ElTooltip>
          </div>
        </template>
        <template #default="{ row }">
          <ElTooltip placement="left">
            <button
              class="ml-2 block aspect-square w-10 rounded-lg"
              :style="{ backgroundColor: row.rune?.color }"
              @click="
                () => {
                  helpers.copyToClipboard(row.rune?.color).then(() => {
                    ElMessage.success('已複製到剪貼簿');
                  });
                }
              "
            />
            <template #content>
              <div
                class="rounded-2xl bg-[linear-gradient(180deg,#2EFFA5_0%,#1C4B45_100%)] p-[2px] text-white"
              >
                <div class="rounded-2xl bg-black px-1 py-3">
                  <div class="flex items-center gap-1">
                    <div
                      class="glow flex flex-col items-center gap-3"
                      :style="{
                        '--glow-color': row.rune?.color,
                        '--glow-size': '5px',
                      }"
                    >
                      <div class="flex flex-col gap-2">
                        <span
                          v-for="i in 7"
                          :key="i"
                          class="glow aspect-video w-1 bg-white"
                          :style="{ '--glow-color': row.rune?.color }"
                        />
                      </div>
                      <p
                        class="glow font-sofia-sans font-bold uppercase !leading-none tracking-tighter"
                        :style="{
                          textOrientation: 'upright',
                          writingMode: 'vertical-rl',
                          '--glow-color': row.rune?.color,
                        }"
                      >
                        {{ row.rune?.name?.toUpperCase?.() }}
                      </p>
                    </div>
                    <div class="relative w-52">
                      <div
                        class="glow flex aspect-square w-full items-center justify-center rounded-[10%] border-2 border-solid border-white"
                      >
                        <span
                          class="glow text-xl text-white"
                          :style="{
                            '--glow-color': row.rune?.color,
                            '--glow-size': '1px',
                          }"
                        >
                          {{ row.rune?.color }}
                        </span>
                      </div>
                      <div
                        class="glow pointer-events-none !absolute inset-0 -m-[1px] bg-[url(@/assets/images/bg-rounded-corner-border.png)] bg-[length:100%_100%] bg-center bg-no-repeat"
                        :style="{ '--glow-color': row.rune?.color }"
                      />
                    </div>

                    <div
                      class="glow flex flex-col items-center gap-3"
                      :style="{
                        '--glow-color': row.rune?.color,
                        '--glow-size': '5px',
                      }"
                    >
                      <p
                        class="glow font-sofia-sans font-bold uppercase !leading-none tracking-tighter"
                        :style="{
                          textOrientation: 'upright',
                          writingMode: 'vertical-rl',
                          '--glow-color': row.rune?.color,
                        }"
                      >
                        {{ row.slug?.toUpperCase?.() }}
                      </p>
                      <div
                        class="glow h-5 w-[2px] bg-white"
                        :style="{ '--glow-color': row.rune?.color }"
                      />
                      <div class="flex flex-col gap-2">
                        <span
                          v-for="i in 7"
                          :key="i"
                          class="glow aspect-video w-1 bg-white"
                          :style="{ '--glow-color': row.rune?.color }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </ElTooltip>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="totalSubspecies" label="培育階段" min-width="110">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElTooltip placement="top" content="孵化成妖獸所需經歷的階段數">
              <button class="material-symbols-outlined">info</button>
            </ElTooltip>
          </div>
        </template>
        <template #default="{ row }">
          <span>&emsp;{{ row.totalSubspecies }} 階</span>
        </template>
      </ElTableColumn>
      <ElTableColumn label="狀態" width="110" fixed="right">
        <template #default="{ row }">
          <ElStatusSwitchPrompt
            v-model="row.status"
            :prompt-options="{
              targetName: row.name,
            }"
            :http-request="
              (status) => APIs.species.updateSpecies(row.id, { status })
            "
          />
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </ElCard>
</template>

<style lang="scss" scoped>
.glow {
  --glow-color: white;
  --glow-size: 3px;
  filter: drop-shadow(0 0 var(--glow-size) var(--glow-color));
}
</style>
