import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得訂單列表
 * @param {PaginationParams} params
 */
export const getOrders = function (params) {
  return useErrorHandler(
    request({
      url: "/orders",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一訂單
 * @param {string} id 訂單ID
 * @param {Object} [params]
 */
export const getOrder = function (id, params) {
  return useErrorHandler(
    request({
      url: `/orders/${id}`,
      method: "GET",
      params,
    }),
  );
};
