<script setup>
import {
  ElAvatar,
  ElCard,
  ElDivider,
  ElMessage,
  ElTabPane,
  ElTabs,
  ElTag,
} from "element-plus";
import { onMounted, ref, shallowRef } from "vue";
import { useRoute, useRouter } from "vue-router";

import * as APIs from "@/APIs";
import Breadcrumb from "@/components/Breadcrumb.vue";
import UserManageLogActivity from "@/components/UserManageLog/Activity.vue";
import UserManageLogVcion from "@/components/UserManageLog/Vcoin.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const props = defineProps({
  id: String,
});

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const tabLoading = ref(false);
const userData = ref({
  address: "",
  affiliate_id: "",
  avatar: "",
  birthday: "",
  country: "",
  county: "",
  created_at: "",
  crypto_address: "",
  deleted_at: null,
  district: "",
  email: "",
  email_verified_at: "",
  gender: "",
  id: "",
  last_transaction_at: null,
  locale: "",
  messages: [],
  mobile: "",
  name: "",
  nickname: "",
  notifications: [],
  referred_by: null,
  subspecies: [],
  tasks: [],
  updated_at: "",
  status: enums.common.status.ENABLED,
});

const tabs = ref([
  {
    label: "活動紀錄",
    name: "activity",
    component: shallowRef(UserManageLogActivity),
  },
  {
    label: "Ｖ幣紀錄",
    name: "vcoin",
    component: shallowRef(UserManageLogVcion),
  },
]);
const currentTab = ref(route.query.tab ?? tabs.value[0].name);
const logViewRef = ref(null);

const fetchData = async (id) => {
  loading.value = true;
  try {
    const { result } = await APIs.user.getUser(id);
    userData.value = result;
  } catch (error) {
    helpers.devConsole.error(error);
  } finally {
    loading.value = false;
  }
};

const onTabChange = async (name) => {
  tabLoading.value = true;
  try {
    router.push({ query: { tab: name } });
    const currentTabRef = logViewRef.value?.find(
      (tab) => tab.$attrs.name === name,
    );
    if (currentTabRef?.onTabChange) await currentTabRef.onTabChange();
  } catch (error) {
    helpers.devConsole.error(error);
  } finally {
    tabLoading.value = false;
  }
};

const copyID = async (id) => {
  await helpers.copyToClipboard(id);
  ElMessage.success("已複製會員ID");
};

onMounted(() => {
  fetchData(props.id);
});
</script>

<template>
  <Breadcrumb
    :breadcrumbs="[
      {
        label: `${$route.meta.title}列表`,
        to: { name: enums.route.names.USERS },
      },
      { label: userData.nickname },
    ]"
  />
  <ElCard class="!rounded-xl">
    <div v-loading="loading">
      <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
      <div class="flex items-center gap-4 text-lg">
        <ElAvatar :src="userData.avatar" :size="80">
          <img
            src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
          />
        </ElAvatar>
        <strong>{{ userData.nickname }}</strong>
      </div>
      <ElDivider class="border !border-primary" />
      <table class="w-full border-separate border-spacing-4">
        <tbody>
          <tr>
            <th class="whitespace-nowrap text-right">會員 ID：</th>
            <td>
              {{ userData.id }}
              <button class="inline text-primary" @click="copyID(userData.id)">
                <span class="material-symbols-outlined">content_copy</span>
                複製
              </button>
            </td>
            <th />
            <td />
          </tr>
          <tr>
            <th class="whitespace-nowrap text-right">會員狀態：</th>
            <td>
              <ElTag :type="constants.common.statusColors[userData.status]">
                {{ constants.common.statusLabels[userData.status] }}
              </ElTag>
            </td>
            <th class="whitespace-nowrap text-right">加入會員：</th>
            <td>{{ dayjs(userData.created_at).format() }}</td>
          </tr>
          <tr>
            <th class="whitespace-nowrap text-right">姓名：</th>
            <td>{{ userData.name }}</td>
            <th class="whitespace-nowrap text-right">電話：</th>
            <td>{{ userData.mobile }}</td>
          </tr>
          <tr>
            <th class="whitespace-nowrap text-right">生日：</th>
            <td>{{ userData.birthday }}</td>
            <th class="whitespace-nowrap text-right">電子郵件：</th>
            <td>{{ userData.email }}</td>
          </tr>
          <tr>
            <th class="whitespace-nowrap text-right">國家：</th>
            <td>{{ userData.country }}</td>
            <th class="whitespace-nowrap text-right">地址：</th>
            <td>{{ userData.address }}</td>
          </tr>
          <tr>
            <th class="whitespace-nowrap text-right">性別：</th>
            <td>{{ constants.user.genderLabels[userData.gender] }}</td>
            <th class="whitespace-nowrap text-right">邀請碼：</th>
            <td>{{ userData.affiliate_id }}</td>
          </tr>
        </tbody>
      </table>
      <ElDivider class="border !border-primary" />
    </div>
    <ElCard class="!rounded-xl">
      <ElTabs v-model="currentTab" @tab-change="onTabChange">
        <ElTabPane
          v-for="tab in tabs"
          v-loading="tabLoading"
          :key="tab.name"
          :name="tab.name"
          :label="tab.label"
        >
          <component ref="logViewRef" :is="tab.component" :id="id" />
        </ElTabPane>
      </ElTabs>
    </ElCard>
  </ElCard>
</template>

<style lang="scss" scoped></style>
