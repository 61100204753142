<script setup>
import { ElDivider, ElImage } from "element-plus";
import { computed } from "vue";

import ColorBlockViewer from "@/components/ColorBlockViewer.vue";
import IconSprite from "@/components/IconSprite.vue";

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const onlyText = computed(() =>
  props.data?.question_options?.every((option) => !option.image),
);
</script>

<template>
  <h3 class="text-center text-2xl font-bold" v-text="data.title" />
  <p v-if="data.question_options" class="text-center text-sm text-success">
    最少選擇{{ data.min_choices }}個，最多選擇{{ data.max_choices }}個
  </p>
  <ElDivider />
  <p class="whitespace-pre-line text-center" v-text="data.question" />
  <div
    class="flex flex-wrap justify-center"
    :class="{
      'mx-auto w-fit flex-col': onlyText,
    }"
  >
    <template
      v-for="item in data.question_options.filter((option) => option.content)"
      :key="item.id"
    >
      <label
        :for="item.id"
        class="flex cursor-pointer flex-col gap-2 p-2.5 md:gap-4"
        :class="{
          'w-1/2 items-center md:w-1/4': !onlyText,
        }"
      >
        <input
          :id="item.id"
          type="checkbox"
          :name="data.id"
          :value="item.id"
          class="peer invisible hidden h-0 w-0"
        />
        <ElImage
          v-if="!onlyText"
          :src="item.image"
          :alt="item.name"
          class="w-36 max-w-full rounded-3xl border-[6px] border-solid border-transparent peer-checked:border-green-400"
        >
          <template #error>
            <ColorBlockViewer :src="item.image" class="w-full" />
          </template>
        </ElImage>
        <div class="group flex items-center gap-5">
          <div
            v-if="onlyText"
            class="h-10 w-10 rounded-lg border-2 border-solid"
          >
            <IconSprite
              id="check"
              class="invisible h-full w-full translate-x-[10%] translate-y-[10%] scale-[2.2] peer-checked:group-[]:visible"
            />
          </div>
          <span class="peer-checked:group-[]:text-green-400">
            {{ item.content }}
          </span>
        </div>
      </label>
    </template>
  </div>
</template>

<style lang="scss" scoped></style>
