import papaparse from "papaparse";


import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";
import { devConsole } from "@/libs/helpers";

export const convertTaskPublishStatus = (task) =>
  task.status === enums.common.status.DISABLED
    ? enums.task.publishStatus.DRAFT
    : dayjs(task.start_at).isAfter(dayjs())
    ? enums.task.publishStatus.PENDING
    : dayjs(task.expired_at).isBefore(dayjs())
    ? enums.task.publishStatus.EXPIRED
    : enums.task.publishStatus.PUBLISHED;

export const convertProductPublishStatus = (product) =>
  product.status === enums.common.status.DISABLED
    ? enums.product.publishStatus.DRAFT
    : dayjs(product.start_at).isAfter(dayjs())
    ? enums.product.publishStatus.PENDING
    : dayjs(product.end_at).isBefore(dayjs())
    ? enums.product.publishStatus.EXPIRED
    : enums.product.publishStatus.PUBLISHED;

export const convertDrawPublishStatus = (draw) =>
  draw.status === 1 && dayjs(draw.start_at).isAfter(dayjs())
    ? enums.draw.publishStatus.PENDING
    : draw.status;

export const containsDomain = function (string, url) {
  const placeholderImageDomain = new URL(url).hostname;
  return !!string?.includes(placeholderImageDomain);
};

/**
 * 如果開始時間比當前時間早 10 分鐘內 => 調整為現在時間的 10 秒後
 * @param {string|Date} time 要調整的時間
 * @param {number} secondToAdjust 要調整的秒數
 */
export const adjustTimeBetweenNowAnd10MinutesAgo = (
  time,
  secondToAdjust = 10,
) => {
  const now = dayjs();
  const target = dayjs(time);

  if (import.meta.env.DEV) {
    devConsole.log("adjustTimeBetweenNowAnd10MinutesAgo");
    devConsole.log("now", now.format());
    devConsole.log("target", target.format());
    devConsole.log('now.diff(target, "minute")', now.diff(target, "minute"));
  }

  if (0 <= now.diff(target, "minute") && now.diff(target, "minute") < 10) {
    return now.add(secondToAdjust, "second").format("YYYY-MM-DD HH:mm:ss"); // 需要精準到秒
  }
  return time;
};

/**
 * 下載 CSV 檔案
 * @param {Array} csvData CSV 資料
 * @param {string} filename 檔案名稱
 */
export const downloadCSV = (csvData, filename) => {
  const csv = papaparse.unparse(csvData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 分享至 LINE
 * @param {Object} param
 * @param {String} [param.text] 分享的文字
 * @param {String} [param.url] 分享的連結
 */
export const shareToLINE = ({ text = "", url = "" }) => {
  devConsole.log("%c current device:", "color: yellow;", navigator.userAgent);
  devConsole.log("shareToLINE", { text, url });
  const encodedText = encodeURIComponent(text);
  const encodedUrl = encodeURIComponent(url);
  window.open(
    helpers.isMobile()
      ? `https://line.me/R/msg/text?${encodedText}${encodeURIComponent(
          "\r\n",
        )}${encodedUrl}`
      : `https://social-plugins.line.me/lineit/share?url=${encodedUrl}&text=${encodedText}`, // 手機文字帶不出來
    // `https://line.me/R/share?text=${encodedText}${encodeURIComponent("\r\n")}${encodedUrl}`, // 電腦版無法分享
    "_blank",
    "width=500,height=500,popup=1",
  );
};
