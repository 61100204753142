import { ElNotification } from "element-plus";

import * as enums from "@/enums";
import { getCookie } from "@/libs/cookie";
import { useAuthStore } from "@/stores/auth";

/**
 * @param {import("vue-router").Router} router
 */
export default function (router) {
  router.beforeEach(async (to) => {
    const authStore = useAuthStore();
    if (to.meta.title) {
      document.title = to.meta.title + " - OMG管理";
    }

    if (to.name === enums.route.names.LOGIN) return true;
    else if (!getCookie("token")) {
      authStore.user = null;
      return { name: enums.route.names.LOGIN };
    } else {
      try {
        if (!authStore.user) {
          await authStore.getUserInfo();
        }

        // 如果只有 USER 角色，則不允許訪問
        if (
          authStore.user.roles?.length === 1 &&
          authStore.user.roles[0].name === enums.user.roles.USER
        ) {
          throw new Error("您沒有權限訪問");
        }

        return true;
      } catch (error) {
        ElNotification.error({
          title: "抱歉",
          message: "您沒有權限訪問",
        });
        return to.name === enums.route.names.LOGIN
          ? true
          : { name: enums.route.names.LOGIN };
      }
    }
  });
}
