import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";

export const publishStatusLabels = {
  [enums.draw.publishStatus.DRAFT]: "草稿",
  [enums.draw.publishStatus.PENDING]: "待上線",
  [enums.draw.publishStatus.ONLINE]: "已上線",
  [enums.draw.publishStatus.FINISHED]: "已截止",
  [enums.draw.publishStatus.OFFLINE]: "已下架",
};

export const publishStatusColors = {
  [enums.draw.publishStatus.DRAFT]: "info",
  [enums.draw.publishStatus.PENDING]: "warning",
  [enums.draw.publishStatus.ONLINE]: "success",
  [enums.draw.publishStatus.FINISHED]: "primary",
  [enums.draw.publishStatus.OFFLINE]: "danger",
};

export const publishStatusDescription = {
  [enums.draw.publishStatus.DRAFT]: "尚未啟用",
  [enums.draw.publishStatus.PENDING]: "時間未到，暫未上線",
  [enums.draw.publishStatus.ONLINE]: "上線中",
  [enums.draw.publishStatus.FINISHED]: "時間已到，自動截止",
  [enums.draw.publishStatus.OFFLINE]: "已「手動」下架",
};

export const publishStatusFilters = {
  [enums.draw.publishStatus.DRAFT]: {
    status: enums.common.status.DISABLED,
  },
  [enums.draw.publishStatus.PENDING]: {
    status: enums.common.status.ENABLED,
    start_at: [dayjs().format(), null],
  },
  [enums.draw.publishStatus.ONLINE]: {
    status: enums.draw.publishStatus.ONLINE,
    // start_at: [null, dayjs().format()],
  },
  [enums.draw.publishStatus.FINISHED]: {
    status: enums.draw.publishStatus.FINISHED,
  },
  [enums.draw.publishStatus.OFFLINE]: {
    status: enums.draw.publishStatus.OFFLINE,
  },
};

export const typeLabels = {
  [enums.draw.types.LINE_POINT]: "LINE Point",
  [enums.draw.types.COUPON]: "優惠券",
  [enums.draw.types.GOODS]: "實體商品",
};

export const tagColors = {
  [enums.draw.tags.TIME_LIMIT]: "#B597FF",
  [enums.draw.tags.REACH_GOAL]: "#9B56FE",
};

export const resultStatusLabels = {
  [enums.draw.resultStatus.LOSE]: "未中獎",
  [enums.draw.resultStatus.WIN]: "中獎",
};

export const resultStatusColors = {
  [enums.draw.resultStatus.LOSE]: "info",
  [enums.draw.resultStatus.WIN]: "success",
};