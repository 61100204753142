<script setup>
import { ElTable } from "element-plus";
import { computed, ref } from "vue";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  onReachBottom: {
    type: Function,
    required: true,
  },
  disable: Boolean,
  height: [String, Number],
  distance: {
    type: Number,
    default: 0,
  },
});

const pending = ref(false);
const disabled = computed(() => props.disable || pending.value);

const onReachBottom = async () => {
  if (disabled.value) return;
  pending.value = true;
  await props.onReachBottom();
  setTimeout(() => {
    // 防止連續觸發
    pending.value = false;
  }, 100);
};
</script>

<template>
  <ElTable :data="data" :height="height">
    <slot />
    <template v-if="!disable" #append>
      <div
        v-infinite-scroll="onReachBottom"
        :infinite-scroll-disabled="disabled"
        :infinite-scroll-distance="distance"
        class="my-3 text-center text-primary"
      >
        <slot name="loading">載入中...</slot>
      </div>
    </template>
  </ElTable>
</template>

<style lang="scss" scoped>
:deep(.el-scrollbar__wrap) {
  @apply overscroll-contain;
}
</style>
