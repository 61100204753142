<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElMessageBox,
  ElPopover,
  ElTableColumn,
} from "element-plus";
import numeral from "numeral";
import { computed, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElRemoteSearchSelect from "@/components/ElRemoteSearchSelect.vue";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

defineExpose({
  onBeforeTabLeave: () => {
    const modified = list.value.some((item) => item.modified);
    if (modified) {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(`尚有未保存的修改，確定離開嗎？`, "警告", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(resolve)
          .catch(reject);
      });
    }
    return Promise.resolve();
  },
});

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  id: "",
  user_id: "",
  name: "",
  "product.brand": "",
  "product.supplier": "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const handleFetch = (query) => {
  loading.value = true;
  APIs.order
    .getOrders({
      page: query.page,
      per_page: query.per_page,
      relationships: ["product.brand", "product.supplier"],
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = (res.result.data || []).map((item) =>
        helpers.dataConverter(item, {
          price: (val) => numeral(val).format(),
          quantity: (val) => numeral(val).format(),
          created_at: (val) => dayjs(val).format(),
          redeemed_at: (val) => (val ? dayjs(val).format() : "-"),
        }),
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const copyID = async (id) => {
  await helpers.copyToClipboard(id);
  ElMessage.success("已複製ID");
};

onMounted(() => {
  handleFetch(params.value);
});
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '訂單編號', value: 'id' },
            { label: '兌換者ID', value: 'user_id' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElInput
              v-model="searchParams[key]"
              :formatter="(val) => val.replaceAll('%', '')"
              :parser="(val) => `%${val.replaceAll('%', '')}%`"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem label="供應商:">
        <ElRemoteSearchSelect
          v-model="searchParams['product.supplier']"
          :http-request="
            (query) =>
              APIs.supplier
                .getSuppliers({
                  filters: helpers.convertSearchParams({
                    name: `%${query}%`,
                    status: enums.common.status.ENABLED,
                  }),
                })
                .then((res) =>
                  res.result.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                  })),
                )
          "
        >
          <template #option="item">
            <div class="flex items-center">
              <img :src="item.image" class="w-5" />
              <span class="ml-2">{{ item.name }}</span>
            </div>
          </template>
        </ElRemoteSearchSelect>
      </ElFormItem>
      <ElFormItem label="品牌商:">
        <ElRemoteSearchSelect
          v-model="searchParams['product.brand']"
          :http-request="
            (query) =>
              APIs.brand
                .getBrands({
                  filters: helpers.convertSearchParams({
                    name: `%${query}%`,
                    status: enums.common.status.ENABLED,
                  }),
                })
                .then((res) =>
                  res.result.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                  })),
                )
          "
        >
          <template #option="item">
            <div class="flex items-center">
              <img :src="item.image" class="w-5" />
              <span class="ml-2">{{ item.name }}</span>
            </div>
          </template>
        </ElRemoteSearchSelect>
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[{ label: '訂單成立時間', value: 'created_at' }]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn prop="id" label="訂單編號" min-width="120">
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <span>{{
                row.id.slice(0, 4) + "****" + row.id.slice(row.id.length - 4)
              }}</span>
            </template>
            {{ row.id }}
            <button
              class="whitespace-nowrap text-primary"
              @click="copyID(row.id)"
            >
              <span class="material-symbols-outlined">content_copy</span>
              複製
            </button>
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="user_id" label="兌換者ID" min-width="120">
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <span>{{
                row.user_id.slice(0, 4) +
                "****" +
                row.user_id.slice(row.user_id.length - 4)
              }}</span>
            </template>
            {{ row.user_id }}
            <button
              class="whitespace-nowrap text-primary"
              @click="copyID(row.user_id)"
            >
              <span class="material-symbols-outlined">content_copy</span>
              複製
            </button>
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn
        prop="created_at"
        label="訂單成立時間"
        min-width="150"
        sortable
      />
      <ElTableColumn prop="product.brand.name" label="品牌商" min-width="150" />
      <ElTableColumn
        prop="product.supplier.name"
        label="供應商"
        min-width="150"
      />
      <ElTableColumn prop="product.name" label="商品名稱" min-width="250" />
      <ElTableColumn prop="product.image" label="商品圖片" min-width="100">
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <img :src="row.product.image" class="aspect-square w-12" />
            </template>
            <img :src="row.product.image" class="w-60" />
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="price" label="商品金額（Ｖ幣）" min-width="150" />
      <ElTableColumn
        prop="redeemed_at"
        label="訂單使用時間"
        min-width="150"
        sortable
      />
    </ElPaginationTable>
  </ElCard>
</template>
