export const taskableTypes = {
  VIDEO: "video",
  CLICK: "click",
  QUESTION: "question",
  SURVEY: "survey",
};

export const taskableTypeMap = {
  "App\\Models\\Video": taskableTypes.VIDEO,
  "App\\Models\\Click": taskableTypes.CLICK,
  "App\\Models\\Question": taskableTypes.QUESTION,
  "App\\Models\\Survey": taskableTypes.SURVEY,
};
