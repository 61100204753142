<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElOption,
  ElSelect,
  ElTableColumn,
  ElTag,
} from "element-plus";
import { computed, ref } from "vue";
import { RouterLink } from "vue-router";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  name: "",
  status: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  APIs.brand
    .getBrands({
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

const deleteBrand = (id) => {
  loading.value = true;
  APIs.brand
    .deleteBrand(id)
    .then(() => handleFetch(params.value))
    .finally(() => {
      loading.value = false;
    });
};

handleFetch(params.value);
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem label="狀態:">
        <ElSelect v-model="searchParams.status" clearable>
          <ElOption
            v-for="(label, key) in constants.common.statusLabels"
            :key="key"
            :label="label"
            :value="Number(key)"
          >
            <ElTag :type="constants.common.statusColors[key]">
              {{ label }}
            </ElTag>
          </ElOption>
        </ElSelect>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <RouterLink
      :to="{
        name: enums.route.names.BRAND,
        params: { id: enums.common.CREATE_MODE },
      }"
      class="mb-3 ml-auto !block w-fit"
    >
      <ElButton type="success">＋新增</ElButton>
    </RouterLink>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
    >
      <ElTableColumn prop="name" label="品牌商名稱" min-width="200" />
      <ElTableColumn
        prop="created_at"
        label="建立時間"
        min-width="150"
        sortable
        :formatter="({ created_at }) => dayjs(created_at).format()"
      />
      <ElTableColumn prop="status" label="狀態" min-width="100">
        <template #default="{ row }">
          <ElTag :type="constants.common.statusColors[row.status]">
            {{ constants.common.statusLabels[row.status] }}
          </ElTag>
        </template>
      </ElTableColumn>
      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{
              name: enums.route.names.BRAND,
              params: { id: row.id },
            }"
          >
            <ElButton type="primary" link>編輯</ElButton>
          </RouterLink>
          <ElButton
            type="danger"
            link
            @click="
              () => {
                ElMessageBox.confirm(`確定要刪除「${row.name}」嗎？`, '警告', {
                  confirmButtonText: '確定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => {
                    deleteBrand(row.id);
                  })
                  .catch(() => {});
              }
            "
          >
            刪除
          </ElButton>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </ElCard>
</template>

<style lang="scss" scoped></style>
