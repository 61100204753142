import { request, useErrorHandler } from "@/libs/axios";
import * as helpers from "@/libs/helpers";

/**
 * 上傳圖片
 * @typedef {Object} Config
 * @property {Function} onUploadProgress
 * @property {AbortSignal} signal
 * @param {File} file
 * @param {Config} [config]
 */
export const uploadImage = function (file, config) {
  return useErrorHandler(
    request({
      url: "/upload/image",
      method: "POST",
      data: helpers.convertToFormData({
        file,
      }),
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: config?.onUploadProgress,
      signal: config?.signal,
    }),
  );
};
