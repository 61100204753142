<script setup>
import { ElButton } from "element-plus";
import { ref } from "vue";

import EditableTableCell from "@/components/EditableTableCell.vue";

const loading = ref(false);
const editable = ref(false);
const formData = ref({
  day1: {
    exp: 0,
    coin: 0,
  },
  day2: {
    exp: 0,
    coin: 0,
  },
  day3: {
    exp: 0,
    coin: 0,
  },
  day4: {
    exp: 0,
    coin: 0,
  },
  day5: {
    exp: 0,
    coin: 0,
  },
  day6: {
    exp: 0,
    coin: 0,
  },
  day7: {
    exp: 0,
    coin: 0,
  },
});
</script>

<template>
  <div class="max-w-screen-lg">
    <ElButton
      class="mb-5 ml-auto !block"
      :type="editable ? 'success' : 'primary'"
      :loading="loading"
      @click="editable = !editable"
    >
      {{ editable ? "儲存" : "修改獎勵" }}
    </ElButton>
    <div class="w-full overflow-x-auto overflow-y-hidden overscroll-contain">
      <table class="w-full border-collapse">
        <thead>
          <tr class="sticky top-0 z-50 bg-primary">
            <th class="sticky left-0">天數</th>
            <th>第一天</th>
            <th>第二天</th>
            <th>第三天</th>
            <th>第四天</th>
            <th>第五天</th>
            <th>第六天</th>
            <th>第七天</th>
          </tr>
        </thead>
        <tbody v-loading="loading">
          <tr>
            <th class="sticky left-0 z-40">培育值</th>
            <EditableTableCell
              v-model.number="formData.day1.exp"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day2.exp"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day3.exp"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day4.exp"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day5.exp"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day6.exp"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day7.exp"
              :disabled="!editable"
            />
          </tr>
          <tr>
            <th class="sticky left-0 z-40">Ｖ幣</th>
            <EditableTableCell
              v-model.number="formData.day1.coin"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day2.coin"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day3.coin"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day4.coin"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day5.coin"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day6.coin"
              :disabled="!editable"
            />
            <EditableTableCell
              v-model.number="formData.day7.coin"
              :disabled="!editable"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
th,
:deep(td) {
  @apply whitespace-nowrap border border-gray-300;
}
th {
  @apply px-3 py-1;
}
thead th {
  @apply border-primary bg-primary text-white;
}
tbody th {
  @apply bg-white;
}
</style>
