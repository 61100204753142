/**
 * @param {string} name cookie name
 * @param {any} value cookie value
 * @param {number} expireDays cookie expire days
 */
export function setCookie(name, value, expireDays) {
  const d = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + (typeof value === 'string' ? value : JSON.stringify(value)) + ";" + expires + ";path=/";
}

/**
 * @param {string} name cookie name
 * @returns {string | null} cookie value
 */
export function getCookie(name) {
  const decodedCookie = decodeURIComponent(document.cookie);
  return decodedCookie
    ? decodedCookie
        .split(";")
        .map((str) => str.trim())
        .reduce((o, str) => {
          const [key, val] = str.split("=");
          try {
            o[key] = JSON.parse(val);
          } catch (error) {
            o[key] = val;
          }
          return o;
        }, {})[name]
    : null;
}

/**
 * @param {string} name cookie name
 */
export function deleteCookie(name) {
  const d = new Date();
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=;" + expires + ";path=/";
}
