<script setup>
import { Chart } from "chart.js/auto";
import { onMounted, ref } from "vue";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  options: Object,
  plugins: Array,
});

const emit = defineEmits(["resize", "click", "hover"]);

const chartRef = ref(null);

onMounted(() => {
  const ctx = chartRef.value.getContext("2d");
  new Chart(ctx, {
    type: props.type,
    data: props.data,
    options: {
      ...props.options,
      onResize: emit("resize"),
      onClick: emit("click"),
      onHover: emit("hover"),
    },
    plugins: props.plugins,
  });
});
</script>

<template>
  <canvas ref="chartRef" />
</template>
