export const publishStatus = {
  /** 草稿 */
  DRAFT: 0,
  /** 待上線 */
  PENDING: "PENDING",
  /** 已上線 */
  ONLINE: 1,
  /** 已截止 */
  FINISHED: 2,
  /** 已下線 */
  OFFLINE: 3,
};

export const types = {
  /** LINE point */
  LINE_POINT: "LINE_POINT",
  /** 優惠券 */
  COUPON: "COUPON",
  /** 實體商品 */
  GOODS: "GOODS",
};

export const tags = {
  /** 限時抽 */
  TIME_LIMIT: "限時抽",
  /** 達標抽 */
  REACH_GOAL: "達標抽",
};

export const resultStatus = {
  /** 未中獎 */
  LOSE: 0,
  /** 中獎 */
  WIN: 1,
};
