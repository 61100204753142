import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得所有題目列表
 * @param {string} taskableType 題目類別
 * @param {PaginationParams} params
 */
export const getTaskables = function (taskableType, params) {
  return useErrorHandler(
    request({
      url: `/${taskableType}s`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得題目詳細資料
 * @param {string} taskableType 題目類別
 * @param {string} id
 */
export const getTaskable = function (taskableType, id) {
  return useErrorHandler(
    request({
      url: `/${taskableType}s/${id}`,
      method: "GET",
    }),
  );
};

/**
 * 新增題目
 * @param {string} taskableType 題目類別
 * @param {object} data
 */
export const createTaskable = function (taskableType, data) {
  return useErrorHandler(
    request({
      url: `/${taskableType}s`,
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新題目
 * @param {string} taskableType 題目類別
 * @param {string} id
 * @param {object} data
 */
export const updateTaskable = function (taskableType, id, data) {
  return useErrorHandler(
    request({
      url: `/${taskableType}s/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除題目
 * @param {string} taskableType 題目類別
 * @param {string} id
 */
export const deleteTaskable = function (taskableType, id) {
  return useErrorHandler(
    request({
      url: `/${taskableType}s/${id}`,
      method: "DELETE",
    }),
  );
};
