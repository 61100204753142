<script setup>
import { ElButton, ElForm, ElFormItem, ElInput } from "element-plus";
import { reactive, ref } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useRouter } from "vue-router";

import * as enums from "@/enums";
import { useAuthStore } from "@/stores/auth";

/** Composables */
const recaptcha = useReCaptcha();
const router = useRouter();
const authStore = useAuthStore();

/** Variables */
const loading = ref(false);

const formData = ref({
  email: "",
  password: "",
});

const formRules = reactive({
  email: [
    { required: true, message: "請輸入電子信箱" },
    { type: "email", message: "請確認信箱格式" },
  ],
  password: { required: true, message: "請輸入密碼" },
});

/** Methods */
const login = async () => {
  loading.value = true;
  await recaptcha.recaptchaLoaded();
  await recaptcha.executeRecaptcha("login");
  authStore
    .login(formData.value.email, formData.value.password)
    .then(() => {
      router.replace({ name: enums.route.names.HOME });
    })
    .finally(() => (loading.value = false));
};
</script>

<template>
  <ElForm
    :model="formData"
    :rules="formRules"
    class="el-form mx-auto w-full rounded-2xl p-5 max-sm:h-screen sm:mt-20 sm:min-h-[500px] sm:max-w-md"
    label-position="top"
  >
    <h1 class="mb-5 text-center text-3xl font-bold">登入</h1>
    <ElFormItem prop="email" label="電子信箱">
      <ElInput
        v-model="formData.email"
        name="email"
        autocomplete="email"
        @keyup.enter="login"
      />
    </ElFormItem>
    <ElFormItem prop="password" label="密碼" class="mb-5">
      <ElInput
        v-model="formData.password"
        show-password
        name="password"
        autocomplete="current-password"
        @keyup.enter="login"
      />
    </ElFormItem>
    <ElFormItem>
      <ElButton
        type="primary"
        auto-insert-space
        class="w-full"
        name="submit"
        :loading="loading"
        @click="login"
      >
        登入
      </ElButton>
    </ElFormItem>
  </ElForm>
</template>

<style lang="scss" scoped>
.el-form {
  background-color: #ecf0f3;
  box-shadow:
    13px 13px 20px #cbced1,
    -13px -13px 20px #fff;
}
</style>
