import { devConsole } from "@/libs/helpers";

/**
 * @example
 * ```
 * type User = {
 *   name: string;
 *   age: number;
 *   email: string;
 * };
 *
 * type UserAuthEventMap = {
 *   login: [user: User];
 *   logout: [];
 * };
 *
 * const userAuthEmitter = new EventEmitter<UserAuthEventMap>();
 *
 * userAuthEmitter.on("login", ({ name, age, email }) => {});
 * userAuthEmitter.on("logout", () => {});
 *
 * userAuthEmitter.emit("login", {
 *   name: "John",
 *   age: 42,
 *   email: "test@gmail.com",
 * });
 * userAuthEmitter.emit("logout");
 * ```
 */
class EventEmitter {
  events;
  constructor() {
    this.events = {};
  }

  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  }

  emit(event, ...args) {
    devConsole.log(`Event: ${event}`, args);
    this.events[event]?.forEach((listener) => listener(...args));
  }

  off(event) {
    delete this.events[event];
  }
}

export default new EventEmitter();
