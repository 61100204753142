<script setup>
const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  modelModifiers: Object,
  formatter: {
    type: Function,
    default: (v) => v,
  },
  disabled: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (e) => {
  const target = e.target;
  let value = target.value;
  if (props.modelModifiers?.number === true) {
    if (isNaN(Number(value))) {
      target.value = props.modelValue;
      emit("update:modelValue", props.formatter(props.modelValue));
      return;
    }
    value = Number(value);
  }
  emit("update:modelValue", props.formatter(value));
};
</script>

<template>
  <td class="p-0">
    <input
      :value="modelValue"
      :disabled="disabled"
      :class="{
        'cursor-not-allowed': disabled,
      }"
      class="w-full px-3 py-1"
      @input="onInput"
    />
    <div class="h-0 appearance-none px-3 opacity-0">
      {{ modelValue }}
    </div>
  </td>
</template>

<style lang="scss" scoped></style>
