<script setup>
import {
  ElButton,
  ElCard,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElNotification,
  ElOption,
  ElSelect,
} from "element-plus";
import { computed, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import * as APIs from "@/APIs";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ElContrastTag from "@/components/ElContrastTag.vue";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElImageUpload from "@/components/ElImageUpload.vue";
import ElRemoteSearchSelect from "@/components/ElRemoteSearchSelect.vue";
import ElStatusSwitchPrompt from "@/components/ElStatusSwitchPrompt.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

const router = useRouter();

const props = defineProps({
  typeId: String,
  id: String,
});

const brandSelectRef = ref();
const supplierSelectRef = ref();

const formRef = ref();
const createMode = computed(() => props.id === enums.common.CREATE_MODE);
const loading = ref(false);

const formData = ref({
  id: "",
  name: "",
  image: "",
  supplier_id: "",
  brand_id: "",
  type: "",
  price: 0,
  quantity: 0,
  personal_limit: 0,
  start_at: "",
  end_at: "",
  condition: "",
  status: enums.common.status.ENABLED,
  order: 1,
  description: "",
  attention: "",
  information: "",
  meta: {
    storeId: "",
    productId: "",
    price: 0,
    code_type: enums.systex.QRURLPW.NORMAL,
  },
  created_at: "",
  updated_at: "",
  tags: [],
});

const formRules = computed(() => ({
  name: [{ required: true, message: "請輸入商品名稱" }],
  supplier_id: [{ required: true, message: "請選擇供應商" }],
  brand_id: [{ required: true, message: "請選擇品牌商" }],
  start_at: [{ required: true, message: "請選擇開始時間" }],
  end_at: [
    { required: !isInfiniteExpiredAt.value, message: "請選擇結束時間" },
    {
      validator: (rule, value, callback) => {
        if (dayjs(value).isBefore(dayjs(formData.value.start_at))) {
          callback(new Error("下架時間不能早於上架時間"));
        } else if (
          !createMode.value &&
          dayjs(value).isBefore(dayjs().subtract(1, "second"))
        ) {
          callback(new Error("下架時間不能早於當前時間"));
        } else callback();
      },
    },
  ],
  quantity: [{ required: true, message: "請輸入商品數量" }],
  price: [{ required: true, message: "請輸入兌換金額（Ｖ幣）" }],
  personal_limit: [{ required: true, message: "請輸入可兌換上限（單人）" }],
  tags: [{ required: true, message: "請選擇角標" }],
  status: [{ required: true, message: "請選擇啟用狀態" }],
  "meta.productId": [{ required: true, message: "請輸入商品編號" }],
  "meta.price": [{ required: true, message: "請輸入商品成本（台幣）" }],
  description: [{ required: true, message: "請輸入商品介紹" }],
  attention: [{ required: true, message: "請輸入注意事項" }],
  information: [{ required: true, message: "請輸入客服資訊" }],
}));

const isInfiniteExpiredAt = computed({
  get: () => !formData.value.end_at,
  set: (val) => {
    if (val) formData.value.end_at = null;
  },
});

const handleSubmit = () => {
  formRef.value?.validate(async (valid, rules) => {
    if (!valid)
      ElNotification({
        title: "表單驗證錯誤",
        type: "error",
        message: Object.values(rules)[0][0].message,
      });
    else {
      try {
        loading.value = true;
        const data = helpers.dataConverter(formData.value, {
          meta: JSON.stringify,
          type: props.typeId,
          condition: "權限?", // TODO: 要帶什麼值？
          // 開始時間比當前時間早 10 分鐘內 => 調整為現在時間的 10 秒後
          start_at: utils.adjustTimeBetweenNowAnd10MinutesAgo,
          // 結束時間比當前時間早 10 分鐘內 => 調整為現在時間的 20 秒後
          end_at: (val) => utils.adjustTimeBetweenNowAnd10MinutesAgo(val, 20),
        });
        if (createMode.value)
          await APIs.product.createProduct(data).then((res) => {
            router.replace({ params: { id: res.result.id } });
          });
        else
          await APIs.product.updateProduct(
            props.id,
            helpers.omit(
              data,
              [].concat(
                Object.entries({
                  start_at: dayjs(data.start_at).isBefore(dayjs()), // 開始時間早於當前時間 => 忽略
                  end_at: dayjs(data.end_at).isBefore(dayjs()), // 結束時間早於當前時間 => 忽略
                })
                  .filter(([, value]) => value)
                  .map(([key]) => key),
              ),
            ),
          );
        ElNotification({
          title: `${createMode.value ? "新增" : "更新"}成功`,
          type: "success",
        });
      } finally {
        loading.value = false;
      }
    }
  });
};

if (!createMode.value) {
  loading.value = true;
  APIs.product
    .getProduct(props.id, {
      relationships: ["tags", "brand", "supplier"],
    })
    .then(async (res) => {
      formData.value = helpers.dataConverter(res.result, {
        meta: JSON.parse,
        tags: (tags) => tags.map((tag) => tag.name),
      });
      brandSelectRef.value?.list.push({
        ...res.result.brand,
        value: res.result.brand.id,
        label: res.result.brand.name,
      });
      supplierSelectRef.value?.list.push({
        ...res.result.supplier,
        value: res.result.supplier.id,
        label: res.result.supplier.name,
      });
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: `${$route.meta.title}列表`,
          to: { name: enums.route.names.PRODUCTS },
        },
        { label: createMode ? `${$route.meta.title}` : formData.name },
      ]"
    />
    <ElCard v-loading="loading" class="!rounded-xl">
      <h1 class="mb-3 text-2xl font-bold">
        {{ createMode ? "新增" : $route.meta.title }}
      </h1>
      <ElForm
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
        inline
      >
        <ElFormItem prop="image" label="商品圖片">
          <ElImageUpload v-model="formData.image">
            <template #preview="{ src }">
              <img :src="src" class="aspect-square w-52 object-cover" />
            </template>
          </ElImageUpload>
        </ElFormItem>
        <ElFormItem prop="name" label="商品名稱" class="w-full">
          <ElInput v-model="formData.name" />
        </ElFormItem>
        <ElFormItem prop="supplier_id" label="供應商">
          <ElRemoteSearchSelect
            ref="supplierSelectRef"
            v-model="formData.supplier_id"
            :http-request="
              (query) =>
                APIs.supplier
                  .getSuppliers({
                    filters: helpers.convertSearchParams({
                      name: `%${query}%`,
                      status: enums.common.status.ENABLED,
                    }),
                  })
                  .then((res) =>
                    res.result.map((item) => ({
                      ...item,
                      value: item.id,
                      label: item.name,
                    })),
                  )
            "
          >
            <template #option="item">
              <div class="flex items-center">
                <img :src="item.image" class="w-5" />
                <span class="ml-2">{{ item.name }}</span>
              </div>
            </template>
          </ElRemoteSearchSelect>
        </ElFormItem>
        <ElFormItem prop="brand_id" label="品牌商">
          <ElRemoteSearchSelect
            ref="brandSelectRef"
            v-model="formData.brand_id"
            :http-request="
              (query) =>
                APIs.brand
                  .getBrands({
                    filters: helpers.convertSearchParams({
                      name: `%${query}%`,
                      status: enums.common.status.ENABLED,
                    }),
                  })
                  .then((res) =>
                    res.result.map((item) => ({
                      ...item,
                      value: item.id,
                      label: item.name,
                    })),
                  )
            "
          >
            <template #option="item">
              <div class="flex items-center">
                <img :src="item.image" class="w-5" />
                <span class="ml-2">{{ item.name }}</span>
              </div>
            </template>
          </ElRemoteSearchSelect>
        </ElFormItem>
        <div class="w-full" />
        <ElFormItem label="可兌換時間" required>
          <div class="flex flex-wrap gap-1">
            <ElFormItem prop="start_at" class="!mr-0">
              <ElDatePicker
                v-model="formData.start_at"
                type="datetime"
                :disabled-date="(time) => time.getTime() < Date.now()"
              />
            </ElFormItem>
            至
            <ElFormItem prop="end_at">
              <ElDatePicker
                v-model="formData.end_at"
                type="datetime"
                :disabled-date="
                  (time) =>
                    time.getTime() <
                    [new Date(formData.start_at).getTime(), Date.now()]
                      .sort()
                      .pop()
                "
              />
              <ElCheckbox
                v-model="isInfiniteExpiredAt"
                label="無期限"
                class="ml-3"
              />
            </ElFormItem>
          </div>
        </ElFormItem>
        <div class="w-full" />
        <ElFormItem prop="quantity" label="商品數量">
          <ElInputNumber
            v-model="formData.quantity"
            :controls="false"
            :min="0"
            step-strictly
          />
        </ElFormItem>
        <ElFormItem prop="price" label="兌換金額（Ｖ幣）">
          <ElInputNumber
            v-model="formData.price"
            :controls="false"
            :min="0"
            step-strictly
          />
        </ElFormItem>
        <ElFormItem prop="personal_limit" label="可兌換上限（單人）">
          <ElInputNumber
            v-model="formData.personal_limit"
            :controls="false"
            :min="0"
            step-strictly
          />
        </ElFormItem>
        <div class="w-full" />
        <ElFormItem prop="tags" label="角標" class="min-w-[200px] max-w-xs">
          <ElSelect v-model="formData.tags" multiple filterable clearable>
            <ElOption
              v-for="(label, key) in enums.product.tags"
              :key="key"
              :label="label"
              :value="label"
            >
              <ElContrastTag :color="constants.product.tagColors[label]">
                {{ label }}
              </ElContrastTag>
            </ElOption>
            <template #tag>
              <ElContrastTag
                v-for="tag in formData.tags"
                :key="tag"
                :color="constants.product.tagColors[tag]"
                :name="tag"
              />
            </template>
          </ElSelect>
        </ElFormItem>
        <ElFormItem prop="status" label="啟用狀態">
          <ElStatusSwitchPrompt
            v-model="formData.status"
            :prompt-options="{
              disabled: createMode,
              targetName: formData.name,
            }"
            :http-request="
              (status) => APIs.product.updateProduct(id, { status })
            "
          />
        </ElFormItem>
        <div class="w-full" />
        <ElFormItem prop="meta.productId" label="商品編號">
          <ElInput v-model="formData.meta.productId" />
        </ElFormItem>
        <ElFormItem prop="meta.price" label="商品成本（台幣）">
          <ElInputNumber
            v-model="formData.meta.price"
            :controls="false"
            :min="0"
            step-strictly
          />
        </ElFormItem>
        <ElFormItem prop="meta.code_type" label="票券種類">
          <ElSelect
            v-model="formData.meta.code_type"
            :empty-values="[null, undefined]"
            :value-on-clear="null"
          >
            <ElOption
              v-for="(label, key) in constants.systex.QRURLPW_Labels"
              :key="key"
              :label="label"
              :value="key"
            />
          </ElSelect>
        </ElFormItem>
        <ElFormItem prop="description" label="商品介紹" class="w-full">
          <TinyMCE v-model="formData.description" class="w-full" />
        </ElFormItem>
        <ElFormItem prop="attention" label="注意事項" class="w-full">
          <TinyMCE v-model="formData.attention" class="w-full" />
        </ElFormItem>
        <ElFormItem prop="information" label="客服資訊" class="w-full">
          <TinyMCE v-model="formData.information" class="w-full" />
        </ElFormItem>
        <div class="flex w-full items-center justify-center gap-3">
          <RouterLink :to="{ name: enums.route.names.PRODUCTS }">
            <ElButton type="info" plain>返回</ElButton>
          </RouterLink>
          <ElButton type="primary" @click="handleSubmit">儲存</ElButton>
        </div>
      </ElForm>
    </ElCard>
  </div>
</template>

<style lang="scss" scoped></style>
