<script setup>
import { ElForm, ElFormItem, ElInput, ElInputNumber } from "element-plus";
import { computed, ref } from "vue";

const formRef = ref();

const formData = ref({
  id: "",
  video_url: "",
  video_length: 0,
  video_id1: "",
  video_id2: "",
});

const formRules = computed(() => ({
  video_url: [
    { required: true, message: "請輸入影片連結" },
    { type: "url", message: "請輸入正確的URL" },
  ],
  video_length: { required: true, message: "請輸入影片長度" },
  video_id1: { required: true, message: "請輸入影片ID1" },
  video_id2: { required: true, message: "請輸入影片ID2" },
}));

const validate = () => {
  return new Promise((resolve, reject) => {
    formRef.value.validate((valid, rules) => {
      if (valid) {
        resolve(formData.value);
      } else {
        reject(
          new Error(Object.values(rules)[0][0].message, {
            cause: "表單驗證錯誤",
          }),
        );
      }
    });
  });
};

const initFormData = (payload = {}) => {
  formData.value.id = payload?.id ?? "";
  formData.value.video_url = payload?.video_url ?? "";
  formData.value.video_length = payload?.video_length ?? 0;
  formData.value.video_id1 = payload?.video_id1 ?? "";
  formData.value.video_id2 = payload?.video_id2 ?? "";
};

defineExpose({
  validate,
  initFormData,
});
</script>

<template>
  <ElForm ref="formRef" :model="formData" :rules="formRules" label-width="90px">
    <ElFormItem prop="video_url" label="影片連結">
      <ElInput v-model="formData.video_url" />
    </ElFormItem>
    <ElFormItem prop="video_length" label="影片長度">
      <ElInputNumber
        v-model="formData.video_length"
        :controls="false"
        :min="0"
      />
    </ElFormItem>
    <ElFormItem label="影片ID" required>
      <div class="flex gap-2">
        <ElFormItem prop="video_id1">
          <ElInput v-model="formData.video_id1" placeholder="ID1" />
        </ElFormItem>
        <ElFormItem prop="video_id2">
          <ElInput v-model="formData.video_id2" placeholder="ID2" />
        </ElFormItem>
        <button type="button" class="text-xs text-primary underline">
          獲取方式參照
        </button>
      </div>
    </ElFormItem>
  </ElForm>
</template>

<style lang="scss" scoped>
:deep(.el-form-item__error) {
  left: unset;
  right: 0;
}
</style>
