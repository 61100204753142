<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElMessageBox,
  ElTableColumn,
} from "element-plus";
import numeral from "numeral";
import { computed, h, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElPrompt from "@/components/ElPrompt.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";

const systemUserId = ref("");

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const summary = ref({
  total_budget: 0,
  balance: 0,
});
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const promptRef = ref();
const promptFormRef = ref();
const promptFormData = ref({
  delta: 0,
  reason: "",
});
const promptFormRules = {
  delta: [
    { required: true, message: "請輸入數字" },
    {
      validator: (rule, value, callback) => {
        if (value <= 0) callback(new Error("Ｖ幣數量需大於 0"));
        else callback();
      },
    },
  ],
  reason: { required: true, message: "請輸入原因" },
};

const fetchSummary = () => {
  return APIs.vcoin.getPoolInfo().then(({ result }) => {
    summary.value = result;
    systemUserId.value = result.system_id;
  });
};

const handleFetch = (query) => {
  loading.value = true;
  APIs.user
    .getUser(systemUserId.value, {
      page: query.page,
      per_page: query.per_page,
      columns: ["id"],
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      filters:
        constants.transaction.typeFilters[
          enums.transaction.Type.SYSTEM_DEPOSIT
        ],
      relationships: ["transactions"],
    })
    .then((res) => {
      const { relation } = res.result;
      paginationParams.value.total = relation.total;
      list.value = relation?.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

const addPool = () => {
  return new Promise((resolve) => {
    promptRef.value
      ?.open({
        title: "添加Ｖ幣",
        message: h("span", [
          h("span", null, "現有Ｖ幣數量："),
          h("br"),
          h(
            "strong",
            {
              style:
                "color: var(--color-info); padding-left: 1em; line-height: 2; font-size: 1.5em;",
            },
            numeral(summary.value.total_budget).format(),
          ),
        ]),
        beforeResolve: () =>
          promptFormRef.value
            .validate()
            .then(async () => {
              const { delta, reason } = promptFormData.value;
              await APIs.vcoin
                .addPool({
                  coins: delta,
                  reason,
                })
                .then(() => {
                  summary.value.total_budget = numeral(
                    summary.value.total_budget,
                  )
                    .add(delta)
                    .value();
                  summary.value.balance = numeral(summary.value.balance)
                    .add(delta)
                    .value();
                  handleFetch(params.value);
                });
              return true;
            })
            .catch(() => false),
      })
      .then(() => {
        ElMessageBox.alert("已添加Ｖ幣", "添加Ｖ幣成功", {
          type: "success",
        });
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      })
      .finally(() => {
        promptFormData.value.delta = 0;
        promptFormData.value.reason = "";
        promptFormRef.value.resetFields();
      });
  });
};

onMounted(async () => {
  loading.value = true;
  await fetchSummary();
  handleFetch(params.value);
});
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElDivider />
    <div class="grid gap-5 sm:grid-cols-2">
      <span>總Ｖ幣：{{ numeral(summary.total_budget).format() }}</span>
      <span>庫存數：{{ numeral(summary.balance).format() }}</span>
      <span>
        已發放：{{
          numeral(summary.total_budget).subtract(summary.balance).format()
        }}
      </span>
      <span>
        庫存率：{{
          numeral(summary.balance).divide(summary.total_budget).format("0,0.0%")
        }}
      </span>
    </div>
    <ElDivider class="!border-t-primary" />
    <div class="mb-5 flex items-center justify-between gap-5">
      <h2 class="text-2xl">資金池添加紀錄</h2>
      <ElButton type="success" @click="addPool()">＋添加</ElButton>
    </div>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn
        prop="amount"
        label="添加數量"
        min-width="150"
        :formatter="({ amount }) => numeral(amount).format()"
      />
      <ElTableColumn prop="description" label="添加原因" min-width="250" />
      <ElTableColumn
        prop="created_at"
        label="添加時間"
        min-width="150"
        :formatter="({ created_at }) => dayjs(created_at).format()"
      />
      <ElTableColumn
        prop="meta.action_user_name"
        label="操作者（姓名）"
        min-width="150"
      />
    </ElPaginationTable>
    <ElPrompt ref="promptRef" width="500">
      <ElForm
        ref="promptFormRef"
        :model="promptFormData"
        :rules="promptFormRules"
        label-position="top"
        @submit.prevent
      >
        <ElFormItem prop="delta" label="添加數量">
          <ElInputNumber
            v-model="promptFormData.delta"
            placeholder="請輸入欲添加之Ｖ幣數量"
            step-strictly
            min="0"
            :controls="false"
          />
        </ElFormItem>
        <ElFormItem prop="reason" label="添加原因">
          <ElInput
            v-model="promptFormData.reason"
            placeholder="請輸入添加原因"
            type="textarea"
            :autosize="{ minRows: 2 }"
          />
        </ElFormItem>
      </ElForm>
    </ElPrompt>
  </ElCard>
</template>
