import { request, useErrorHandler } from "@/libs/axios";

/**
 * 新增QA
 * @typedef {Object} CreateFAQData
 * @property {string} faq_category_id - QA類別ID
 * @property {string} title - QA問題
 * @property {string} content - QA答案
 * @property {string} [order] - 排序
 * @param {CreateFAQData} data
 */
export const createFAQ = function (data) {
  return useErrorHandler(
    request({
      url: "/faqs",
      method: "POST",
      data,
    }),
  );
};

/** 取得QA類別 */
export const getFAQcategories = function () {
  return useErrorHandler(
    request({
      url: "/faq-categories",
      method: "GET",
    }),
  );
};

/**
 * 取得QA列表
 * @param {PaginationParams} params
 */
export const getFAQs = function (params) {
  return useErrorHandler(
    request({
      url: "/faqs",
      method: "GET",
      params,
    }),
  );
};

/**
 * 刪除QA
 * @param {string} id - QA ID
 */
export const deleteFAQ = function (id) {
  return useErrorHandler(
    request({
      url: `/faqs/${id}`,
      method: "DELETE",
    }),
  );
};

/**
 * 更新QA（多筆）
 * @typedef {Object} UpdateFAQData
 * @property {string} id - QA ID
 * @property {string} [faq_category_id] - QA類別ID
 * @property {string} [title] - QA問題
 * @property {string} [content] - QA答案
 * @property {string} [order] - 排序
 * @param {UpdateFAQData} data
 */
export const updateFAQs = function (data) {
  return useErrorHandler(
    request({
      url: "/faqs",
      method: "PATCH",
      data,
    }),
  );
};
