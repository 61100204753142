export const types = {
  /** 優惠券 */
  COUPON: "COUPON",
  /** 數位商品（NFT） */
  NFT: "NFT",
  /** 圖片 */
  IMAGE: "IMAGE",
  /** 實體商品 */
  GOODS: "GOODS",
};

export const publishStatus = {
  /** 草稿 */
  DRAFT: 0,
  /** 待上線 */
  PENDING: 1,
  /** 已上線 */
  PUBLISHED: 2,
  /** 已結束 */
  EXPIRED: 3,
};

export const tags = {
  /** 限時兌換 */
  LIMITED_TIME_EXCHANGE: "限時兌換",
  /** 強打商品 */
  FEATURED_PRODUCT: "強打商品",
  /** 限換一次 */
  LIMITED_EXCHANGE_ONCE: "限換一次",
  /** 現金券 */
  CASH_VOUCHER: "現金券",
  /** 新品上市 */
  NEW_PRODUCT_LAUNCH: "新品上市",
  /** 熱門限量 */
  POPULAR_LIMITED_EDITION: "熱門限量",
};
