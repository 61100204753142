import { request, useErrorHandler } from "@/libs/axios";
import * as helpers from "@/libs/helpers";

/**
 * 取得抽獎列表
 * @param {PaginationParams} params
 */
export const getDraws = function (params) {
  return useErrorHandler(
    request({
      url: "/draws",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一抽獎
 * @param {string} id 抽獎ID
 * @param {Object} [params]
 */
export const getDraw = function (id, params) {
  return useErrorHandler(
    request({
      url: `/draws/${id}`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 新增抽獎
 * @param {Object} data
 */
export const createDraw = function (data) {
  return useErrorHandler(
    request({
      url: "/draws",
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新抽獎
 * @param {string} id 抽獎ID
 * @param {Object} data
 */
export const updateDraw = function (id, data) {
  return useErrorHandler(
    request({
      url: `/draws/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除抽獎
 * @param {string} id 抽獎ID
 */
export const deleteDraw = function (id) {
  return useErrorHandler(
    request({
      url: `/draws/${id}`,
      method: "DELETE",
    }),
  );
};

/**
 * 上傳抽獎結果
 * @param {string} id 抽獎ID
 * @param {File} file 檔案（CSV）
 */
export const uploadDrawResults = function (id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return useErrorHandler(
    request({
      url: `/draws/${id}/mark-ticket-as-winner`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }),
  );
};

/**
 * 取得抽獎結果
 * @param {string} id 抽獎ID
 * @param {PaginationParams} [params]
 */
export const getDrawResults = function (id, params) {
  return useErrorHandler(
    request({
      url: "/tickets",
      method: "GET",
      params: {
        ...params,
        filters: helpers.convertSearchParams({
          draw_id: id,
        }),
        relationships: ["user"],
      },
    }),
  );
};

/**
 * 更新抽獎票券（單筆）
 * @param {string} ticketId 票券ID
 * @param {Object} data
 */
export const updateDrawTicket = function (ticketId, data) {
  return useErrorHandler(
    request({
      url: `/tickets/${ticketId}`,
      method: "PATCH",
      data,
    }),
  );
};
