import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { dayjs } from "element-plus";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(function (option, dayjsClass, dayjsFactory) {
  /** Add a default format string to the format method */
  const oldFormat = dayjsClass.prototype.format;
  dayjsClass.prototype.format = function (args) {
    return this.isValid()
      ? oldFormat.bind(this)(args ?? "YYYY/MM/DD HH:mm")
      : "";
  };
});

dayjs.tz.setDefault("Asia/Taipei");

export default dayjs;
