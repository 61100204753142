<script setup>
import { ElDatePicker } from "element-plus";
import { computed } from "vue";

import dayjs from "@/libs/dayjs";

const props = defineProps(["modelValue"]);

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (val) => {
    if (Array.isArray(val)) {
      emit(
        "update:modelValue",
        val.map((v) => dayjs(v).format()),
      );
    } else if (dayjs(val).isValid()) {
      emit("update:modelValue", dayjs(val).format());
    } else {
      emit("update:modelValue", val);
    }
  },
});
</script>

<template>
  <ElDatePicker v-model="value" />
</template>
