<script setup>
import {
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElTableColumn,
} from "element-plus";
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const props = defineProps({
  taskableType: String,
});

defineExpose({
  onBeforeTabLeave: () => {
    const modified = list.value.some((item) => item.modified);
    if (modified) {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(`尚有未保存的修改，確定離開嗎？`, "警告", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(resolve)
          .catch(reject);
      });
    }
    return Promise.resolve();
  },
});

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "updated_at",
  order: "desc",
});
const searchParams = ref({});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const handleFetch = (id, query) => {
  loading.value = true;
  APIs.taskable
    .getTaskables(id, {
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      list.value = res.result.data;
      paginationParams.value.total = res.result.total;
    })
    .finally(() => {
      loading.value = false;
    });
};

const deleteTaskable = (taskableType, id) => {
  loading.value = true;
  APIs.taskable
    .deleteTaskable(taskableType, id)
    .then(() => handleFetch(props.taskableType, params.value))
    .finally(() => {
      loading.value = false;
    });
};

const deleteTaskables = () => {
  loading.value = true;
  Promise.all(
    multipleSelection.value.map((item) =>
      APIs.taskable.deleteTaskable(props.taskableType, item.id),
    ),
  )
    .then(() => handleFetch(props.taskableType, params.value))
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(props.taskableType, params.value);
});

onBeforeRouteUpdate(async (to, __, next) => {
  paginationParams.value.page = 1;
  handleFetch(to.params.taskableType, params.value);
  next();
});
</script>

<template>
  <div>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.title"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[{ label: '建立時間', value: 'created_at' }]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(taskableType, params)">
          搜尋
        </ElButton>
      </ElFormItem>
    </ElForm>
    <div class="mb-3 flex justify-end gap-2">
      <RouterLink
        :to="{
          name: enums.route.names.TASKABLE,
          params: { id: enums.common.CREATE_MODE },
        }"
        class="mb-3 ml-auto !block w-fit"
      >
        <ElButton type="success">＋新增</ElButton>
      </RouterLink>
      <ElButton
        type="danger"
        :disabled="
          !multipleSelection.length ||
          multipleSelection.length > constants.common.MAX_MULTIPLE_DELETE
        "
        @click="deleteTaskables"
      >
        <span class="material-symbols-outlined">delete</span>&nbsp;刪除
      </ElButton>
    </div>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch(taskableType, { ...params, page })"
      @page-size-change="
        (size) => handleFetch(taskableType, { ...params, per_page: size })
      "
      @sort-change="
        (sort) =>
          handleFetch(taskableType, {
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn type="selection" width="55" />
      <ElTableColumn prop="title" label="題目名稱" min-width="150" />
      <ElTableColumn prop="question" label="問題" min-width="150" />
      <ElTableColumn
        prop="created_at"
        label="建立時間"
        min-width="150"
        sortable
        :formatter="({ created_at }) => dayjs(created_at).format()"
      />
      <ElTableColumn
        prop="updated_at"
        label="更新時間"
        min-width="150"
        sortable
        :formatter="({ updated_at }) => dayjs(updated_at).format()"
      />
      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{
              name: enums.route.names.TASKABLE,
              params: { id: row.id },
            }"
          >
            <ElButton type="primary" link>編輯</ElButton>
          </RouterLink>
          <ElButton
            type="danger"
            link
            @click="
              () => {
                ElMessageBox.confirm(`確定要刪除「${row.title}」嗎？`, '警告', {
                  confirmButtonText: '確定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => {
                    deleteTaskable(taskableType, row.id);
                  })
                  .catch(() => {});
              }
            "
          >
            刪除
          </ElButton>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </div>
</template>
