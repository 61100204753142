import { createRouter, createWebHistory } from "vue-router";

import * as enums from "@/enums";
import AdminLayout from "@/layouts/Admin.vue";
import EmptyLayout from "@/layouts/Empty.vue";
import BrandsView from "@/views/BrandsView.vue";
import BrandView from "@/views/BrandView.vue";
import CoinPoolView from "@/views/CoinPoolView.vue";
import DashboardView from "@/views/DashboardView.vue";
import DrawResultsView from "@/views/DrawResultsView.vue";
import DrawsView from "@/views/DrawsView.vue";
import DrawView from "@/views/DrawView.vue";
import FAQsTabsView from "@/views/FAQsTabsView.vue";
import FAQsView from "@/views/FAQsView.vue";
import LoginView from "@/views/LoginView.vue";
import ManagersView from "@/views/ManagersView.vue";
import MessagesView from "@/views/MessagesView.vue";
import MessageView from "@/views/MessageView.vue";
import OrdersView from "@/views/OrdersView.vue";
import PlaygroundView from "@/views/PlaygroundView.vue";
import PrizeGroupsView from "@/views/PrizeGroupsView.vue";
import PrizeRecordsView from "@/views/PrizeRecordsView.vue";
import PrizesView from "@/views/PrizesView.vue";
import ProductsTabsView from "@/views/ProductsTabsView.vue";
import ProductsView from "@/views/ProductsView.vue";
import ProductView from "@/views/ProductView.vue";
import SpeciesListView from "@/views/SpeciesListView.vue";
import SubspeciesListView from "@/views/SubspeciesListView.vue";
import SuppliersView from "@/views/SuppliersView.vue";
import SupplierView from "@/views/SupplierView.vue";
import TaskablesTabsView from "@/views/TaskablesTabsView.vue";
import TaskablesView from "@/views/TaskablesView.vue";
import TaskableView from "@/views/TaskableView.vue";
import TaskInfoView from "@/views/TaskInfoView.vue";
import TasksCheckInView from "@/views/TasksCheckInView.vue";
import TasksTabsView from "@/views/TasksTabsView.vue";
import TasksView from "@/views/TasksView.vue";
import TaskTaskablesView from "@/views/TaskTaskablesView.vue";
import TaskView from "@/views/TaskView.vue";
import ToolboxView from "@/views/ToolboxView.vue";
import UsersView from "@/views/UsersView.vue";
import UserView from "@/views/UserView.vue";

import installAuthRouter from "./routerAuth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;
    return to.path === from.path ? false : { top: 0, left: 0 };
  },
  routes: [
    {
      path: "/login",
      name: enums.route.names.LOGIN,
      meta: { title: "登入" },
      component: LoginView,
    },
    {
      path: "/",
      component: AdminLayout,
      children: [
        {
          path: "",
          name: enums.route.names.HOME,
          redirect: { name: enums.route.names.TASKS_TABS },
        },
        {
          path: "dashboard",
          name: enums.route.names.DASHBOARD,
          meta: { title: "總覽" },
          component: DashboardView,
        },
        {
          path: "admin",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.ADMIN,
              redirect: { name: enums.route.names.MANAGERS },
            },
            {
              path: "managers",
              name: enums.route.names.MANAGERS,
              meta: { title: "管理者設定" },
              component: ManagersView,
            },
            {
              path: "users",
              component: EmptyLayout,
              children: [
                {
                  path: "",
                  name: enums.route.names.USERS,
                  meta: { title: "會員管理" },
                  component: UsersView,
                },
                {
                  path: ":id",
                  name: enums.route.names.USER,
                  meta: { title: "會員資訊" },
                  props: true,
                  component: UserView,
                },
              ],
            },
          ],
        },
        {
          path: "FAQs",
          name: enums.route.names.FAQS_TABS,
          component: FAQsTabsView,
          children: [
            {
              path: ":categoryId",
              name: enums.route.names.FAQS,
              meta: { title: "QA管理" },
              props: true,
              component: FAQsView,
            },
          ],
        },
        {
          path: "messages",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.MESSAGES,
              meta: { title: "訊息列表" },
              component: MessagesView,
            },
            {
              path: ":id",
              name: enums.route.names.MESSAGE,
              meta: { title: "訊息管理" },
              props: true,
              component: MessageView,
            },
          ],
        },
        {
          path: "vendors",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.VENDORS,
              redirect: { name: enums.route.names.BRANDS },
            },
            {
              path: "brands",
              component: EmptyLayout,
              children: [
                {
                  path: "",
                  name: enums.route.names.BRANDS,
                  meta: { title: "品牌商列表" },
                  component: BrandsView,
                },
                {
                  path: ":id",
                  name: enums.route.names.BRAND,
                  meta: { title: "品牌商管理" },
                  props: true,
                  component: BrandView,
                },
              ],
            },
            {
              path: "suppliers",
              component: EmptyLayout,
              children: [
                {
                  path: "",
                  name: enums.route.names.SUPPLIERS,
                  meta: { title: "供應商列表" },
                  component: SuppliersView,
                },
                {
                  path: ":id",
                  name: enums.route.names.SUPPLIER,
                  meta: { title: "供應商管理" },
                  props: true,
                  component: SupplierView,
                },
              ],
            },
          ],
        },
        {
          path: "tasks",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.TASKS_TABS,
              component: TasksTabsView,
              children: [
                {
                  path: enums.task.types.CHECK_IN,
                  name: enums.route.names.TASKS_CHECK_IN,
                  meta: { title: "簽到任務" },
                  component: TasksCheckInView,
                },
                {
                  path: ":typeId",
                  name: enums.route.names.TASKS,
                  meta: { title: "任務列表" },
                  props: true,
                  component: TasksView,
                },
              ],
            },
            {
              path: ":typeId/:id",
              meta: { title: "任務管理" },
              props: true,
              component: TaskView,
              children: [
                {
                  path: "",
                  name: enums.route.names.TASK_INFO,
                  props: true,
                  component: TaskInfoView,
                },
                {
                  path: "taskables",
                  name: enums.route.names.TASK_TASKABLES,
                  props: true,
                  component: TaskTaskablesView,
                },
              ],
            },
          ],
        },
        {
          path: "taskables",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.TASKABLES_TABS,
              component: TaskablesTabsView,
              children: [
                {
                  path: ":taskableType",
                  name: enums.route.names.TASKABLES,
                  meta: { title: "題庫列表" },
                  props: true,
                  component: TaskablesView,
                },
              ],
            },
            {
              path: ":taskableType/:id",
              name: enums.route.names.TASKABLE,
              meta: { title: "題目管理" },
              props: true,
              component: TaskableView,
            },
          ],
        },
        {
          path: "award",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.AWARD,
              redirect: { name: enums.route.names.PRODUCTS_TABS },
            },
            {
              path: "products",
              component: EmptyLayout,
              children: [
                {
                  path: "",
                  name: enums.route.names.PRODUCTS_TABS,
                  component: ProductsTabsView,
                  children: [
                    {
                      path: ":typeId",
                      name: enums.route.names.PRODUCTS,
                      meta: { title: "商品列表" },
                      props: true,
                      component: ProductsView,
                    },
                  ],
                },
                {
                  path: ":typeId/:id",
                  name: enums.route.names.PRODUCT,
                  meta: { title: "商品管理" },
                  props: true,
                  component: ProductView,
                },
              ],
            },
            {
              path: "orders",
              name: enums.route.names.ORDERS,
              meta: { title: "訂單列表" },
              component: OrdersView,
            },
          ],
        },
        {
          path: "lottery",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.LOTTERY,
              redirect: { name: enums.route.names.DRAWS },
            },
            {
              path: "draws",
              component: EmptyLayout,
              children: [
                {
                  path: "",
                  name: enums.route.names.DRAWS,
                  meta: { title: "抽獎列表" },
                  component: DrawsView,
                },
                {
                  path: ":id",
                  name: enums.route.names.DRAW,
                  meta: { title: "抽獎管理" },
                  props: true,
                  component: DrawView,
                },
                {
                  path: ":id/results",
                  name: enums.route.names.DRAW_RESULTS,
                  meta: { title: "抽獎名單" },
                  props: true,
                  component: DrawResultsView,
                },
              ],
            },
            {
              path: "prizes",
              component: EmptyLayout,
              children: [
                {
                  path: "",
                  name: enums.route.names.PRIZES,
                  meta: { title: "培育間摸彩" },
                  component: PrizesView,
                },
                {
                  path: "groups",
                  name: enums.route.names.PRIZE_GROUPS,
                  meta: { title: "設定機率" },
                  component: PrizeGroupsView,
                },
                {
                  path: "records",
                  name: enums.route.names.PRIZE_RECORDS,
                  meta: { title: "獎池紀錄" },
                  component: PrizeRecordsView,
                },
              ],
            },
          ],
        },
        {
          path: "cultivate",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: enums.route.names.CULTIVATE,
              redirect: { name: enums.route.names.SPECIES_LIST },
            },
            {
              path: "species",
              name: enums.route.names.SPECIES_LIST,
              meta: { title: "種族列表" },
              component: SpeciesListView,
            },
            {
              path: "subspecies",
              name: enums.route.names.SUBSPECIES_LIST,
              meta: { title: "妖獸列表" },
              component: SubspeciesListView,
            },
          ],
        },
        {
          path: "coins",
          name: enums.route.names.COIN_POOL,
          meta: { title: "資金池管理" },
          component: CoinPoolView,
        },
        {
          path: "playground",
          name: enums.route.names.PLAYGROUND,
          meta: { title: "Playground" },
          component: PlaygroundView,
        },
        {
          path: "toolbox",
          name: enums.route.names.TOOLBOX,
          meta: { title: "工具箱" },
          component: ToolboxView,
        },
      ],
    },
  ],
});

installAuthRouter(router);

export default router;
