<script setup>
import { ElPagination, ElTable } from "element-plus";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: Boolean,
  pagination: {
    type: Object,
    default: () => ({
      page: 1,
      per_page: 10,
      total: 0,
    }),
  },
  paginationSizeOptions: {
    type: Array,
    default: () => [10, 20, 50, 100],
  },
  paginationLayout: {
    type: Array,
    default: () => ["total", "sizes", "->", "prev", "pager", "next", "jumper"],
  },
  paginationBackground: Boolean,
  sort: {
    type: Object,
    default: () => ({
      prop: "",
      order: "",
    }),
  },
});

const emit = defineEmits([
  "page-change",
  "page-size-change",
  "next-click",
  "prev-click",
  "update:pagination",
  "sort-change",
  "update:sort",
  "selection-change",
]);

const pageChange = (page) => {
  emit("page-change", page);
  emit("update:pagination", { ...props.pagination, page });
};

const sortChange = (sort) => {
  emit("sort-change", sort);
  emit("update:sort", sort);
};
</script>

<template>
  <div>
    <ElTable
      v-loading="loading"
      :data="data"
      :default-sort="{
        order: sort?.order?.startsWith('desc') ? 'descending' : 'ascending',
        prop: sort?.prop,
      }"
      @sort-change="sortChange"
      @selection-change="(selection) => emit('selection-change', selection)"
    >
      <slot />
    </ElTable>
    <ElPagination
      class="mt-5"
      small
      :total="pagination.total"
      :layout="paginationLayout.join(',')"
      :page-sizes="paginationSizeOptions"
      :page-size="pagination.per_page"
      :current-page="pagination.page"
      :background="paginationBackground"
      @current-change="pageChange"
      @size-change="
        (size) => {
          $emit('page-size-change', size);
          $emit('update:pagination', { ...pagination, per_page: size });
        }
      "
      @next-click="(page) => $emit('next-click', page)"
      @prev-click="(page) => $emit('prev-click', page)"
    />
  </div>
</template>
