<script setup>
import { ElButton, ElInput } from "element-plus";
import { ref } from "vue";

import FacebookSDKButton from "@/components/FacebookSDKButton.vue";
import IconSprite from "@/components/IconSprite.vue";
import PlaygroundPlaceholder from "@/components/Playground/Placeholder.vue";
import * as enums from "@/enums";
import * as utils from "@/libs/utils";

const shareLink = ref("https://omg.bleu.tw");
const messageToShare = ref(
  "好康一起分！用我的連結加入五告妖獸\n你有50V幣，我有100V幣\n完成任務、培育妖獸還可以換實體好禮喔～\n\n#OMG #五告妖獸",
);
</script>

<template>
  <fieldset>
    <legend>色塊</legend>
    <PlaygroundPlaceholder />
  </fieldset>
  <fieldset>
    <legend>Icons</legend>
    <IconSprite id="vcoin-outlined" class="inline-block w-10" />
    <IconSprite id="pet" class="inline-block w-10" />
    <IconSprite id="seed" class="inline-block w-10" />
    <IconSprite id="check" class="inline-block w-10" />
    <IconSprite id="edit-square" class="inline-block w-10" />
    <IconSprite id="sync" class="inline-block w-10 animate-spin opacity-70" />
    <IconSprite id="arrow-right-rounded" class="inline-block w-10" />
    <IconSprite id="double-arrow-right" class="inline-block w-10" />
    <IconSprite id="earphone-customer-service" class="inline-block w-10" />
    <IconSprite id="earphone" class="inline-block w-10" />
    <IconSprite id="back-to-top" class="inline-block w-10" />
    <IconSprite id="send-message" class="inline-block w-10" />
  </fieldset>
  <fieldset class="grid justify-items-start gap-2">
    <legend>社群分享</legend>
    <label>貼上連結</label>
    <ElInput v-model="shareLink" />
    <label>輸入訊息</label>
    <ElInput
      v-model="messageToShare"
      type="textarea"
      placeholder="目前只支援 LINE"
      :autosize="{ minRows: 2 }"
    />
    <FacebookSDKButton
      :params="{
        display: 'popup',
        method: enums.common.FacebookShareType.SHARE,
        href: shareLink,
        // source: shareLink,
        // link: shareLink,
        hashtag: messageToShare,
      }"
    >
      <template #default="{ loading, ready }">
        <ElButton type="primary" :disabled="!ready" :loading="loading">
          分享到 Facebook
        </ElButton>
      </template>
    </FacebookSDKButton>
    <ElButton
      type="primary"
      @click="
        utils.shareToLINE({
          text: messageToShare,
          url: shareLink,
        })
      "
    >
      分享到 LINE
    </ElButton>
  </fieldset>
</template>

<style lang="scss" scoped>
fieldset {
  @apply mb-4 max-w-screen-lg rounded-md border border-gray-300 p-4;
}
legend {
  @apply text-xl font-bold;
}
</style>
