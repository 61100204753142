import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得供應商列表
 * @param {PaginationParams} params
 */
export const getSuppliers = function (params) {
  return useErrorHandler(
    request({
      url: "/suppliers",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一供應商
 * @param {string} id 供應商ID
 */
export const getSupplier = function (id) {
  return useErrorHandler(
    request({
      url: `/suppliers/${id}`,
      method: "GET",
    }),
  );
};

/**
 * 新增供應商
 * @param {Object} data
 */
export const createSupplier = function (data) {
  return useErrorHandler(
    request({
      url: "/suppliers",
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新供應商
 * @param {string} id 供應商ID
 * @param {Object} data
 */
export const updateSupplier = function (id, data) {
  return useErrorHandler(
    request({
      url: `/suppliers/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除供應商
 * @param {string} id 供應商ID
 */
export const deleteSupplier = function (id) {
  return useErrorHandler(
    request({
      url: `/suppliers/${id}`,
      method: "DELETE",
    }),
  );
};
