<script setup>
import {
  ElForm,
  ElFormItem,
  ElNotification,
  ElOption,
  ElSelect,
  ElTag,
} from "element-plus";
import { computed, ref } from "vue";

import QuestionForm from "@/components/Task/TaskableForms/QuestionForm.vue";
import SurveyForm from "@/components/Task/TaskableForms/SurveyForm.vue";
import VideoForm from "@/components/Task/TaskableForms/VideoForm.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) =>
      value === "" ||
      Object.values(enums.taskable.taskableTypes).includes(value),
  },
  taskableTypeChangeable: {
    type: Boolean,
    default: true,
  },
  availableTaskableTypes: {
    type: Array,
    default: () => Object.values(enums.taskable.taskableTypes),
  },
});

const formRef = ref();
const subFormRef = ref();

const formData = ref({
  type: props.type,
});

const formRules = computed(() => ({
  type: { required: true, message: "請選擇題型" },
}));

const validate = () => {
  return new Promise((resolve, reject) => {
    formRef.value?.validate?.(async (valid, rules) => {
      try {
        if (!valid)
          throw new Error(Object.values(rules)[0][0].message, {
            cause: "表單驗證錯誤",
          });
        const data = await subFormRef.value?.validate?.();
        resolve({
          type: formData.value.type,
          data,
        });
      } catch (error) {
        ElNotification({
          title: error.cause ?? "錯誤",
          type: "error",
          message: error.message,
        });
        reject(error);
      }
    });
  });
};

const initFormData = (payload) => {
  subFormRef.value?.initFormData?.(payload);
};

defineExpose({
  validate,
  initFormData,
});
</script>

<template>
  <ElForm ref="formRef" :model="formData" :rules="formRules" label-width="90px">
    <ElFormItem label="題型" prop="type">
      <ElSelect
        v-if="taskableTypeChangeable"
        v-model="formData.type"
        placeholder="請選擇題型"
      >
        <ElOption
          v-for="(label, value) in constants.taskable.taskableTypeLabels"
          :key="value"
          :label="label"
          :value="value"
          :disabled="
            !availableTaskableTypes.includes(value) && value !== formData.type
          "
        />
      </ElSelect>
      <ElTag v-else class="!text-base font-bold" size="large">
        {{ constants.taskable.taskableTypeLabels[formData.type] }}
      </ElTag>
    </ElFormItem>
    <SurveyForm
      v-if="formData.type === enums.taskable.taskableTypes.SURVEY"
      ref="subFormRef"
    />
    <QuestionForm
      v-else-if="formData.type === enums.taskable.taskableTypes.QUESTION"
      ref="subFormRef"
    />
    <VideoForm
      v-else-if="formData.type === enums.taskable.taskableTypes.VIDEO"
      ref="subFormRef"
    />
  </ElForm>
</template>

<style lang="scss" scoped></style>
