<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElTableColumn,
} from "element-plus";
import numeral from "numeral";
import { computed, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  user_id: "",
  "content.note": "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const handleFetch = (query) => {
  loading.value = true;
  APIs.prize
    .getPrizes({
      page: query.page,
      per_page: query.per_page,
      filters: helpers
        .convertSearchParams({
          ...searchParams.value,
        })
        .concat([["redeemed_at", "!=", ""]]),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      relationships: ["users"],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data.map((item) =>
        helpers.dataConverter(item, {
          redeemed_at: dayjs(item.redeemed_at).format(),
          expired_at: dayjs(item.expired_at).format(),
          created_at: dayjs(item.created_at).format(),
          updated_at: dayjs(item.updated_at).format(),
          content: JSON.parse,
          user_ids: item.users?.map?.((user) => user.id).join("\n"),
        }),
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(params.value);
});
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="100">
      <ElFormItem label="得獎人UID:">
        <ElInput
          v-model="searchParams.user_id"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem label="活動備註:">
        <ElInput
          v-model="searchParams['content.note']"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <div class="mb-3 flex justify-end gap-2">
      <RouterLink :to="{ name: enums.route.names.PRIZE_GROUPS }">
        <ElButton type="primary">設定機率</ElButton>
      </RouterLink>
      <RouterLink :to="{ name: enums.route.names.PRIZE_RECORDS }">
        <ElButton type="primary">查看獎池</ElButton>
      </RouterLink>
    </div>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn prop="redeemed_at" label="中獎日期" min-width="150" />
      <ElTableColumn prop="user_ids" label="得獎人UID" min-width="150" />
      <ElTableColumn prop="content" label="獎勵內容" min-width="250">
        <template #default="{ row }">
          {{ constants.prize.typeLabels[row.type] }}
          {{ numeral(row.value).format() }} 點 ({{ row.content.serial_no }})
        </template>
      </ElTableColumn>
      <ElTableColumn prop="content.note" label="活動備註" min-width="200" />
    </ElPaginationTable>
  </ElCard>
</template>
