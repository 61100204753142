<script setup>
import {
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElOption,
  ElPopover,
  ElSelect,
  ElTableColumn,
  ElTag,
} from "element-plus";
import numeral from "numeral";
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElOrderEditor from "@/components/ElOrderEditor.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElPrompt from "@/components/ElPrompt.vue";
import CSVUploadForm from "@/components/Task/CSVUploadForm.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

const props = defineProps({
  typeId: String,
});

defineExpose({
  onBeforeTabLeave: () => {
    const modified = list.value.some((item) => item.modified);
    if (modified) {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(`尚有未保存的修改，確定離開嗎？`, "警告", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(resolve)
          .catch(reject);
      });
    }
    return Promise.resolve();
  },
});

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "order",
  order: "asc",
});
const searchParams = ref({
  name: "",
  status: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const csvRef = ref(null);
const uploadCSVPromptRef = ref();

const handleFetch = (type, query) => {
  loading.value = true;
  APIs.task
    .getTasks({
      page: query.page,
      per_page: query.per_page,
      relationships: ["coin", "exp"],
      filters: helpers.convertSearchParams({
        ...constants.task.publishStatusFilters[searchParams.value.status],
        ...helpers.omit(searchParams.value, ["status"]),
        type,
      }),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = (res.result.data || []).map((item) => ({
        ...item,
        coin: numeral(item.coin?.[0]?.value || 0).format(),
        exp: numeral(item.exp?.[0]?.value || 0).format(),
      }));
    })
    .finally(() => {
      loading.value = false;
    });
};

const deleteTask = (id) => {
  loading.value = true;
  APIs.task
    .deleteTask(id)
    .then(() => handleFetch(props.typeId, params.value))
    .finally(() => {
      loading.value = false;
    });
};

const deleteTasks = () => {
  loading.value = true;
  Promise.all(
    multipleSelection.value.map((item) => APIs.task.deleteTask(item.id)),
  )
    .then(() => handleFetch(props.typeId, params.value))
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(props.typeId, params.value);
});

onBeforeRouteUpdate(async (to, __, next) => {
  paginationParams.value.page = 1;
  handleFetch(to.params.typeId, params.value);
  next();
});
</script>

<template>
  <div>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem label="狀態:">
        <ElSelect v-model="searchParams.status" clearable>
          <ElOption
            v-for="(label, key) in constants.task.publishStatusLabels"
            :key="key"
            :label="label"
            :value="key"
          >
            <ElTag :type="constants.task.publishStatusColors[key]">
              {{ label }}
            </ElTag>
          </ElOption>
        </ElSelect>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '上架時間', value: 'start_at' },
            { label: '建立時間', value: 'created_at' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(typeId, params)">
          搜尋
        </ElButton>
      </ElFormItem>
    </ElForm>
    <div class="mb-3 flex justify-end gap-2">
      <ElButton
        type="warning"
        @click="
          () => {
            uploadCSVPromptRef
              .open({
                title: '匯入任務題庫內容',
                beforeResolve: () =>
                  csvRef
                    ?.submit()
                    .then(() => true)
                    .catch(() => false),
              })
              .then(() => {
                handleFetch(typeId, params);
              })
              .catch(() => {});
          }
        "
      >
        <span class="material-symbols-outlined">upload</span>
        批量上傳
      </ElButton>
      <RouterLink
        :to="{
          name: enums.route.names.TASK_INFO,
          params: { id: enums.common.CREATE_MODE },
        }"
      >
        <ElButton type="success">＋新增</ElButton>
      </RouterLink>
      <ElButton
        type="danger"
        :disabled="
          !multipleSelection.length ||
          multipleSelection.length > constants.common.MAX_MULTIPLE_DELETE
        "
        @click="deleteTasks"
      >
        <span class="material-symbols-outlined">delete</span>&nbsp;刪除
      </ElButton>
    </div>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch(typeId, { ...params, page })"
      @page-size-change="
        (size) => handleFetch(typeId, { ...params, per_page: size })
      "
      @sort-change="
        (sort) =>
          handleFetch(typeId, {
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn type="selection" width="55" />
      <ElTableColumn prop="order" label="排序" width="90" sortable>
        <template #default="{ row }">
          <ElOrderEditor
            v-model="row.order"
            prompt-mode
            :http-request="
              (order) =>
                APIs.task
                  .updateTask(row.id, { order })
                  .then(() => handleFetch(typeId, params))
            "
          />
        </template>
      </ElTableColumn>
      <ElTableColumn prop="name" label="任務名稱" min-width="200" />
      <ElTableColumn
        prop="start_at"
        label="上架時間"
        :min-width="typeId === enums.task.types.DAILY ? 150 : 270"
        sortable
        :formatter="
          ({ start_at, expired_at }) =>
            dayjs(start_at).format() +
            (typeId === enums.task.types.DAILY
              ? ''
              : ` ~ ${dayjs(expired_at).format()}`)
        "
      />
      <ElTableColumn
        prop="created_at"
        label="建立時間"
        min-width="150"
        sortable
        :formatter="({ created_at }) => dayjs(created_at).format()"
      />
      <ElTableColumn prop="status" label="狀態" min-width="100">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElPopover
              placement="top-start"
              title="狀態說明"
              trigger="hover"
              width="fit-content"
            >
              <template #reference>
                <button class="material-symbols-outlined">info</button>
              </template>
              <div class="grid grid-cols-[auto,1fr] items-center gap-1">
                <template
                  v-for="status in enums.task.publishStatus"
                  :key="status"
                >
                  <ElTag :type="constants.task.publishStatusColors[status]">
                    {{ constants.task.publishStatusLabels[status] }}
                  </ElTag>
                  <span class="whitespace-nowrap">
                    {{ constants.task.publishStatusDescription[status] }}
                  </span>
                </template>
              </div>
            </ElPopover>
          </div>
        </template>
        <template #default="{ row }">
          <ElTag
            :type="
              constants.task.publishStatusColors[
                utils.convertTaskPublishStatus(row)
              ]
            "
          >
            {{
              constants.task.publishStatusLabels[
                utils.convertTaskPublishStatus(row)
              ]
            }}
          </ElTag>
        </template>
      </ElTableColumn>
      <ElTableColumn
        label="消耗Ｖ幣"
        min-width="100"
        :formatter="({ coin }) => numeral(coin).format()"
      />
      <ElTableColumn
        label="培育值"
        min-width="100"
        :formatter="({ exp }) => numeral(exp).format()"
      />
      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{ name: enums.route.names.TASK_INFO, params: { id: row.id } }"
          >
            <ElButton type="primary" link>編輯</ElButton>
          </RouterLink>
          <ElButton
            type="danger"
            link
            @click="
              () => {
                ElMessageBox.confirm(`確定要刪除「${row.name}」嗎？`, '警告', {
                  confirmButtonText: '確定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => {
                    deleteTask(row.id);
                  })
                  .catch(() => {});
              }
            "
          >
            刪除
          </ElButton>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
    <ElPrompt ref="uploadCSVPromptRef" width="700">
      <CSVUploadForm ref="csvRef" />
    </ElPrompt>
  </div>
</template>
