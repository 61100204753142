<template>
  <div class="flex flex-col">
    <img src="@/assets/images/frame-top.png" class="pointer-events-none w-full" />
    <div class="bg-[url(@/assets/images/frame-middle.png)] bg-contain bg-repeat-y px-[5%]">
      <div class="-mb-[2%] -mt-[10%]">
        <slot />
      </div>
    </div>
    <img src="@/assets/images/frame-bottom.png" class="pointer-events-none w-full" />
  </div>
</template>