import { request, useErrorHandler } from "@/libs/axios";

/**
 * 登入（帳號／密碼）
 * @typedef {object} LoginData
 * @property {string} email
 * @property {string} password
 * @param {LoginData} data
 */
export const login = function (data) {
  return useErrorHandler(
    request({
      url: "/login",
      method: "POST",
      data,
    }),
  );
};

/** 登出 */
export const logout = function () {
  return useErrorHandler(
    request({
      url: "/logout",
      method: "POST",
    }),
  );
};

/** 取得使用者資訊（已登入狀態） */
export const getUserInfo = function () {
  return useErrorHandler(
    request({
      url: "/authenticated-user",
      method: "GET",
      params: {
        relationships: ["roles"],
      },
    }),
  );
};

/** 登入（QUBIC）
 * @param {object} params
 */
export const loginWithQubic = function () {
  return useErrorHandler(
    request({
      url: "/auth/qubic/login",
      method: "GET",
    }),
  );
};
