import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";

export const typeLabels = {
  [enums.product.types.GOODS]: "精選獎勵",
};

export const publishStatusLabels = {
  [enums.product.publishStatus.DRAFT]: "草稿",
  [enums.product.publishStatus.PENDING]: "待上線",
  [enums.product.publishStatus.PUBLISHED]: "已上線",
  [enums.product.publishStatus.EXPIRED]: "已結束",
};

export const publishStatusColors = {
  [enums.product.publishStatus.DRAFT]: "info",
  [enums.product.publishStatus.PENDING]: "warning",
  [enums.product.publishStatus.PUBLISHED]: "success",
  [enums.product.publishStatus.EXPIRED]: "danger",
};

export const publishStatusDescription = {
  [enums.product.publishStatus.DRAFT]: "尚未啟用",
  [enums.product.publishStatus.PENDING]: "已啟用，但尚未開始",
  [enums.product.publishStatus.PUBLISHED]: "已啟用，且已上線",
  [enums.product.publishStatus.EXPIRED]: "已啟用，但已結束",
};

export const publishStatusFilters = {
  [enums.product.publishStatus.DRAFT]: {
    status: enums.common.status.DISABLED,
  },
  [enums.product.publishStatus.PENDING]: {
    status: enums.common.status.ENABLED,
    start_at: [dayjs().format(), null],
  },
  [enums.product.publishStatus.PUBLISHED]: {
    status: enums.common.status.ENABLED,
    start_at: [null, dayjs().format()],
    end_at: [dayjs().format(), null],
  },
  [enums.product.publishStatus.EXPIRED]: {
    status: enums.common.status.ENABLED,
    end_at: [null, dayjs().format()],
  },
};

export const tagColors = {
  [enums.product.tags.LIMITED_TIME_EXCHANGE]: "#9A56FD",
  [enums.product.tags.FEATURED_PRODUCT]: "#5620C3",
  [enums.product.tags.LIMITED_EXCHANGE_ONCE]: "#4100C7",
  [enums.product.tags.CASH_VOUCHER]: "#6268FF",
  [enums.product.tags.NEW_PRODUCT_LAUNCH]: "#B597FF",
  [enums.product.tags.POPULAR_LIMITED_EDITION]: "#6300FF",
}