<script setup>
import {
  ElButton,
  ElCard,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElOption,
  ElSelect,
  ElTableColumn,
} from "element-plus";
import { computed, ref } from "vue";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import * as constants from "@/constants";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

/** Components */

const initFormData = () => ({
  id: "",
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  roles: [],
  note: "",
  nickname: "",
  referred_by: "",
});

const formRef = ref(null);
const dialogVisible = ref(false);
const loading = ref(false);
const formData = ref(initFormData());
const tableData = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({});

const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const formRules = computed(() => ({
  name: [{ required: true, message: "請輸入姓名" }],
  email: [
    { required: true, message: "請輸入帳號" },
    { type: "email", message: "請輸入正確的電子郵件" },
  ],
  password: [
    { required: true, message: "請輸入密碼" },
    { min: 6, message: "密碼長度需為 6 個字元以上" },
  ],
  password_confirmation: [
    { required: true, message: "請輸入確認密碼" },
    {
      validator: (rule, value, callback) => {
        if (value !== formData.value.password) {
          callback(new Error("確認密碼與密碼不相符"));
        } else {
          callback();
        }
      },
    },
  ],
  roles: [{ required: true, message: "請選擇角色" }],
}));

const createMode = computed(() => !formData.value.id);

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  APIs.user
    .getUsers({
      page: query.page,
      per_page: query.per_page,
      relationships: ["roles"],
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      tableData.value = res.result.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

const resetFormData = () => {
  formData.value = initFormData();
  formRef.value?.resetFields();
};

const submit = () => {
  formRef.value.validate(async (valid) => {
    if (!valid) return;
    loading.value = true;
    try {
      const currentUser = tableData.value.find(
        (user) => user.id === formData.value.id,
      );
      if (currentUser) {
        // remove empty fields or fields that not changed
        const omitKeys = Object.keys(formData.value)
          .filter(
            (key) =>
              !formData.value[key] || formData.value[key] === currentUser[key],
          )
          // remove fields that not allowed to update
          .concat(["id", "password"]);
        await APIs.user
          .updateUser(formData.value.id, helpers.omit(formData.value, omitKeys))
          .then(async (res) => {
            Object.assign(currentUser, res.result);
            return Promise.resolve();
          });
      } else {
        await APIs.user
          .register(helpers.omit(formData.value, ["id"]))
          .then(async () => {
            await handleFetch(params.value);
            return Promise.resolve();
          });
      }
      dialogVisible.value = false;
    } finally {
      loading.value = false;
    }
  });
};

const deleteUser = (id) => {
  loading.value = true;
  APIs.user
    .deleteUser(id)
    .then(() => handleFetch(params.value))
    .finally(() => {
      loading.value = false;
    });
};

handleFetch(params.value);
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '姓名', value: 'name' },
            { label: '暱稱', value: 'nickname' },
            { label: '帳號', value: 'email' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElInput
              v-model="searchParams[key]"
              :formatter="(val) => val.replaceAll('%', '')"
              :parser="(val) => `%${val.replaceAll('%', '')}%`"
              @input="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[{ label: '角色', value: 'role' }]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElSelect
              v-model="searchParams[key]"
              clearable
              @change="deleteKeyWhenValueIsEmpty"
            >
              <ElOption
                v-for="(label, key) in constants.user.roleLabels"
                :key="key"
                :label="label"
                :value="key.toLocaleLowerCase()"
              />
            </ElSelect>
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[{ label: '加入時間', value: 'created_at' }]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <ElButton
      type="success"
      class="ml-auto !block"
      @click="
        () => {
          resetFormData();
          dialogVisible = true;
        }
      "
    >
      ＋新增
    </ElButton>
    <ElPaginationTable
      :data="tableData"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
    >
      <ElTableColumn prop="name" label="姓名" min-width="120" />
      <ElTableColumn prop="email" label="帳號" min-width="180" />
      <ElTableColumn
        prop="roles"
        label="角色"
        min-width="130"
        :formatter="
          ({ roles }) =>
            roles.map((role) => constants.user.roleLabels[role.name]).join(', ')
        "
      />
      <ElTableColumn
        prop="created_at"
        label="建立時間"
        min-width="150"
        sortable
        :formatter="({ created_at }) => dayjs(created_at).format()"
      />
      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <ElButton
            type="primary"
            link
            @click="
              () => {
                Object.assign(formData, {
                  ...row,
                  password: '**********',
                  roles: row.roles.map((role) => role.name),
                });
                dialogVisible = true;
              }
            "
          >
            編輯
          </ElButton>
          <ElButton
            type="danger"
            link
            @click="
              () => {
                ElMessageBox.confirm(`確定要刪除「${row.name}」嗎？`, '警告', {
                  confirmButtonText: '確定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => deleteUser(row.id))
                  .catch(() => {});
              }
            "
          >
            刪除
          </ElButton>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </ElCard>
  <ElDialog v-model="dialogVisible" width="500">
    <template #header>
      <h3 class="text-xl font-bold">{{ createMode ? "新增" : "更新" }}</h3>
    </template>
    <ElForm ref="formRef" :model="formData" :rules="formRules" label-width="80">
      <ElFormItem prop="name" label="姓名">
        <ElInput v-model="formData.name" />
      </ElFormItem>
      <ElFormItem prop="email" label="帳號">
        <ElInput v-model="formData.email" type="email" />
      </ElFormItem>
      <ElFormItem prop="password" label="密碼">
        <ElInput
          v-model="formData.password"
          :disabled="!createMode"
          type="password"
          show-password
        />
      </ElFormItem>
      <template v-if="createMode">
        <ElFormItem prop="password_confirmation" label="確認密碼">
          <ElInput
            v-model="formData.password_confirmation"
            type="password"
            show-password
          />
        </ElFormItem>
      </template>
      <ElFormItem prop="roles" label="角色">
        <ElSelect v-model="formData.roles" multiple>
          <ElOption
            v-for="(label, key) in constants.user.roleLabels"
            :key="key"
            :label="label"
            :value="key.toLocaleLowerCase()"
          />
        </ElSelect>
      </ElFormItem>
      <ElFormItem prop="nickname" label="暱稱">
        <ElInput v-model="formData.nickname" />
      </ElFormItem>
      <template v-if="createMode">
        <ElFormItem prop="referred_by" label="邀請碼">
          <ElInput v-model="formData.referred_by" />
        </ElFormItem>
      </template>
      <ElFormItem prop="note" label="備註">
        <ElInput
          v-model="formData.note"
          type="textarea"
          :autosize="{ minRows: 3 }"
        />
      </ElFormItem>
      <ElFormItem>
        <div class="flex w-full items-center justify-center">
          <ElButton @click="dialogVisible = false">取消</ElButton>
          <ElButton type="primary" :loading="loading" @click="submit">
            儲存
          </ElButton>
        </div>
      </ElFormItem>
    </ElForm>
  </ElDialog>
</template>

<style scoped></style>
