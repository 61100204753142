import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得品牌商列表
 * @param {PaginationParams} params
 */
export const getBrands = function (params) {
  return useErrorHandler(
    request({
      url: "/brands",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一品牌商
 * @param {string} id 品牌商ID
 */
export const getBrand = function (id) {
  return useErrorHandler(
    request({
      url: `/brands/${id}`,
      method: "GET",
    }),
  );
};

/**
 * 新增品牌商
 * @param {Object} data
 */
export const createBrand = function (data) {
  return useErrorHandler(
    request({
      url: "/brands",
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新品牌商
 * @param {string} id 品牌商ID
 * @param {Object} data
 */
export const updateBrand = function (id, data) {
  return useErrorHandler(
    request({
      url: `/brands/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除品牌商
 * @param {string} id 品牌商ID
 */
export const deleteBrand = function (id) {
  return useErrorHandler(
    request({
      url: `/brands/${id}`,
      method: "DELETE",
    }),
  );
};
