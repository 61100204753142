import * as enums from "@/enums";

export const MAX_MULTIPLE_DELETE = 10;

export const statusLabels = {
  [enums.common.status.ENABLED]: "啟用",
  [enums.common.status.DISABLED]: "停用",
};

export const statusColors = {
  [enums.common.status.ENABLED]: "success",
  [enums.common.status.DISABLED]: "info",
};

export const placeholderExtensions = ["jpg", "png"];

export const placeholderPreDefineColors = [
  "#BFEDD2",
  "#FBEEB8",
  "#F8CAC6",
  "#ECCAFA",
  "#C2E0F4",
  "#2DC26B",
  "#F1C40F",
  "#E03E2D",
  "#B96AD9",
  "#3598DB",
  "#169179",
  "#E67E23",
  "#BA372A",
  "#843FA1",
  "#236FA1",
  "#ECF0F1",
  "#CED4D9",
  "#95A5A6",
  "#7E8C8D",
  "#34495E",
  "#000000",
  "#FFFFFF",
];
