<script setup>
import { ElButton, ElInput, ElMessage } from "element-plus";
import { ref } from "vue";

import ColorBlockEditor from "@/components/ColorBlockEditor.vue";
import * as helpers from "@/libs/helpers";

const src = ref("");
const text = ref("");
</script>

<template>
  <div class="mb-3 flex flex-wrap items-end justify-between gap-3">
    <ColorBlockEditor v-model:src="src" :text="text" :width="500" />
    <ElButton
      type="primary"
      @click="
        helpers.copyToClipboard(src).then(() => ElMessage.success('已複製'))
      "
    >
      複製網址&nbsp;<span class="material-symbols-outlined">content_copy</span>
    </ElButton>
  </div>
  <ElInput
    v-model="text"
    placeholder="請輸入選項內容"
    type="textarea"
    :autosize="{ minRows: 2 }"
  />
</template>

<style lang="scss" scoped></style>
