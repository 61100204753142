<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElNotification,
  ElPageHeader,
  ElPopover,
  ElTableColumn,
  ElTag,
} from "element-plus";
import { computed, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElPrompt from "@/components/ElPrompt.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

const props = defineProps({
  id: String,
});

const promptRef = ref();
const formRef = ref();

const formData = ref({
  file: null,
  note: "",
});

/** @type {import("vue").Ref<"file" | "note">} */
const formType = ref("");

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const handleFetch = (query) => {
  loading.value = true;
  APIs.draw
    .getDrawResults(props.id, {
      page: query.page,
      per_page: query.per_page,
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

const handleSubmit = (promiseCallback) => {
  return new Promise((resolve, reject) => {
    formRef.value?.validate(async (valid) => {
      if (valid) {
        loading.value = true;
        await promiseCallback();
        loading.value = false;
        resolve();
      } else {
        reject();
      }
    });
  });
};

const copyID = async (id) => {
  await helpers.copyToClipboard(id);
  ElMessage.success("複製成功");
};

const downloadCSV = async () => {
  loading.value = true;
  APIs.draw
    .getDrawResults(props.id)
    .then((res) => {
      const data = res.result.map((item) => ({
        ["抽獎券ID"]: item.id,
        ["抽獎者ID"]: item.user.id,
        ["抽獎者暱稱"]: item.user.name,
        ["電話"]: item.user.mobile,
        ["信箱"]: item.user.email,
        ["備註"]: item.note,
        ["抽獎結果"]: constants.draw.resultStatusLabels[item.is_winner],
      }));
      helpers.devConsole.log(data)
      utils.downloadCSV(data, "抽獎名單.csv");
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(params.value);
});
</script>

<template>
  <div>
    <ElPageHeader
      class="mb-5"
      @back="
        () => {
          $router.push({ name: enums.route.names.DRAW });
        }
      "
    />
    <ElCard v-loading="loading" class="!rounded-xl">
      <div class="mb-3 flex items-center justify-between">
        <h1 class="text-2xl font-bold">
          {{ $route.meta.title }}
        </h1>
        <div>
          <ElButton
            type="primary"
            @click="
              () => {
                formType = 'file';
                promptRef?.open({
                  title: '匯入名單（.csv）',
                  beforeResolve: () =>
                    handleSubmit(() =>
                      APIs.draw
                        .uploadDrawResults(id, formData.file)
                        .then(() => {
                          ElNotification({
                            title: '匯入成功',
                            type: 'success',
                            message: '已成功匯入抽獎名單',
                          });
                        }),
                    )
                      .then(() => true)
                      .catch(() => false),
                });
              }
            "
          >
            匯入
            <span class="material-symbols-outlined">upload</span>
          </ElButton>
          <ElButton type="info" @click="downloadCSV">
            匯出
            <span class="material-symbols-outlined">download</span>
          </ElButton>
        </div>
      </div>
      <ElPaginationTable
        :data="list"
        v-model:pagination="paginationParams"
        v-model:sort="sortParams"
        :loading="loading"
        paginationBackground
        @page-change="(page) => handleFetch({ ...params, page })"
        @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
        @sort-change="
          (sort) =>
            handleFetch({
              ...params,
              order_by: sort.prop,
              order_direction: sort.order,
            })
        "
        @selection-change="
          (selection) => {
            multipleSelection = selection;
          }
        "
      >
        <ElTableColumn prop="is_winner" label="抽獎結果" min-width="100">
          <template #default="{ row }">
            <ElTag :type="constants.draw.resultStatusColors[row.is_winner]">
              {{ constants.draw.resultStatusLabels[row.is_winner] }}
            </ElTag>
          </template>
        </ElTableColumn>
        <ElTableColumn prop="id" label="抽獎券ID" min-width="150">
          <template #default="{ row }">
            <div class="flex items-center">
              <p class="line-clamp-1">{{ row.id }}</p>
              <button class="text-primary" @click="copyID(row.id)">
                <span class="material-symbols-outlined">content_copy</span>
              </button>
            </div>
          </template>
        </ElTableColumn>
        <ElTableColumn prop="user.id" label="抽獎者ID" min-width="150">
          <template #default="{ row }">
            <div class="flex items-center">
              <p class="line-clamp-1">{{ row.user.id }}</p>
              <button class="text-primary" @click="copyID(row.user.id)">
                <span class="material-symbols-outlined">content_copy</span>
              </button>
            </div>
          </template>
        </ElTableColumn>
        <ElTableColumn prop="user.name" label="抽獎者暱稱" min-width="120" />
        <ElTableColumn prop="user.mobile" label="電話" min-width="120" />
        <ElTableColumn prop="user.email" label="信箱" min-width="150" />
        <ElTableColumn prop="note" label="備註" min-width="250">
          <template #header="{ column }">
            <div class="flex items-center gap-1">
              <span>{{ column.label }}</span>
              <ElTooltip placement="top" content="鼠標移至欄位可以看完整文字">
                <button class="material-symbols-outlined">info</button>
              </ElTooltip>
            </div>
          </template>
          <template #default="{ row }">
            <ElPopover
              :content="row.note"
              width="fit-content"
              popper-class="whitespace-pre-line"
            >
              <template #reference>
                <p class="line-clamp-2 whitespace-pre-line" v-text="row.note" />
              </template>
            </ElPopover>
          </template>
        </ElTableColumn>
        <ElTableColumn min-width="80" fixed="right">
          <template #default="{ row }">
            <ElButton
              type="primary"
              link
              @click="
                () => {
                  formType = 'note';
                  formData.note = row.note;
                  promptRef
                    ?.open({
                      title: '修改備註',
                      beforeResolve: () =>
                        handleSubmit(() =>
                          APIs.draw
                            .updateDrawTicket(row.id, { note: formData.note })
                            .then(() => {
                              ElNotification({
                                title: '修改成功',
                                type: 'success',
                                message: '已成功修改備註',
                              });
                            }),
                        )
                          .then(() => true)
                          .catch(() => false),
                    })
                    .then(() => {
                      row.note = formData.note;
                    });
                }
              "
            >
              修改備註
            </ElButton>
          </template>
        </ElTableColumn>
      </ElPaginationTable>
    </ElCard>
    <ElPrompt ref="promptRef" :closable="!loading" width="700">
      <ElForm ref="formRef" :model="formData" @submit.prevent>
        <ElFormItem
          v-if="formType === 'file'"
          prop="file"
          label="上傳檔案"
          :rules="{ required: true, message: '請選擇檔案' }"
        >
          <label class="block">
            <input
              name="file"
              type="file"
              accept=".csv"
              class="block w-full text-sm text-slate-500 file:mr-4 file:!rounded-full file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-violet-700 hover:file:bg-violet-100 file:disabled:cursor-not-allowed file:disabled:bg-gray-100 file:disabled:text-gray-400"
              @change="
                (e) => {
                  formData.file = e.target.files[0];
                }
              "
            />
          </label>
        </ElFormItem>
        <ElFormItem
          v-if="formType === 'note'"
          prop="note"
          label="備註"
          :rules="{ required: true, message: '請填寫備註' }"
        >
          <ElInput
            v-model="formData.note"
            type="textarea"
            placeholder="請填寫備註"
            clearable
            :autosize="{ minRows: 2 }"
          />
        </ElFormItem>
      </ElForm>
    </ElPrompt>
  </div>
</template>

<style lang="scss" scoped></style>
