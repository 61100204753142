<script setup>
import { ElOption, ElSelect } from "element-plus";
import { ref } from "vue";

const props = defineProps({
  modelValue: [String, Number],
  httpRequest: Function,
  disabled: Boolean,
});

defineEmits(["update:modelValue", "change"]);

const list = ref([]);
const loading = ref(false);

const remoteMethod = (query) => {
  if (!query) return;
  loading.value = true;
  props
    .httpRequest(query)
    .then((res = []) => {
      list.value = res;
    })
    .finally(() => {
      loading.value = false;
    });
};

defineExpose({
  list,
  remoteMethod,
});
</script>

<template>
  <ElSelect
    :model-value="modelValue"
    filterable
    remote
    clearable
    reserve-keyword
    :remote-method="remoteMethod"
    :loading="loading"
    :disabled="disabled"
    @change="
      (val) => {
        $emit('update:modelValue', val);
        $emit(
          'change',
          list.find((item) => item.value === val),
        );
      }
    "
  >
    <template v-for="item in list" :key="item.value">
      <ElOption :value="item.value" :label="item.label">
        <slot name="option" v-bind="item">{{ item.label }}</slot>
      </ElOption>
    </template>
  </ElSelect>
</template>
