import { request, useErrorHandler } from "@/libs/axios";

/**
 * 取得抽獎列表
 * @param {PaginationParams} params
 */
export const getPrizes = function (params) {
  return useErrorHandler(
    request({
      url: "/prizes",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一抽獎
 * @param {string} id 抽獎ID
 * @param {Object} [params]
 */
export const getPrize = function (id, params) {
  return useErrorHandler(
    request({
      url: `/prizes/${id}`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 新增抽獎
 * @param {Object} data
 */
export const createPrize = function (data) {
  return useErrorHandler(
    request({
      url: "/prizes",
      method: "POST",
      data,
    }),
  );
};

/**
 * 更新抽獎
 * @param {string} id 抽獎ID
 * @param {Object} data
 */
export const updatePrize = function (id, data) {
  return useErrorHandler(
    request({
      url: `/prizes/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除抽獎
 * @param {string} id 抽獎ID
 */
export const deletePrize = function (id) {
  return useErrorHandler(
    request({
      url: `/prizes/${id}`,
      method: "DELETE",
    }),
  );
};

/**
 * 取得抽獎機率列表
 * @param {PaginationParams} params
 */
export const getPrizeGroups = function (params) {
  return useErrorHandler(
    request({
      url: "/prize-groups",
      method: "GET",
      params,
    }),
  );
};

/**
 * 更新抽獎機率
 * @param {string} id
 * @param {Object} data
 */
export const updatePrizeGroup = function (id, data) {
  return useErrorHandler(
    request({
      url: `/prize-groups/${id}`,
      method: "PATCH",
      data,
    }),
  );
};

/**
 * 刪除抽獎機率
 * @param {string} id
 */
export const deletePrizeGroup = function (id) {
  return useErrorHandler(
    request({
      url: `/prize-groups/${id}`,
      method: "DELETE",
    }),
  );
};

/**
 * 上傳序號
 * @param {string} id
 * @param {File} file
 */
export const uploadPrizeGroupSerials = function (id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return useErrorHandler(
    request({
      url: `/prizes/${id}/batch`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }),
  );
};

/**
 * 新增抽獎機率
 * @param {Object} data
 * @param {string} data.name
 * @param {number} data.probability
 * @param {number} data.status
 */
export const createPrizeGroup = function (data) {
  return useErrorHandler(
    request({
      url: "/prize-groups",
      method: "POST",
      data,
    }),
  );
};

/**
 * 取得獎池紀錄總覽
 */
export const getPrizeRecordsOverview = function () {
  return useErrorHandler(
    request({
      url: "/prize-groups/prize-count",
      method: "GET",
    }),
  );
};

/**
 * 取得抽獎未中獎機率 (0 ~ 1)
 */
export const getPrizeGroupNonWinningRate = function () {
  return useErrorHandler(
    request({
      url: "/prize-groups/non-winning-probability",
      method: "GET",
    }),
  );
}