<script setup>
import {
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessageBox,
  ElOption,
  ElPopover,
  ElSelect,
  ElTableColumn,
  ElTag,
} from "element-plus";
import numeral from "numeral";
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

import * as APIs from "@/APIs";
import ElDatePicker from "@/components/ElDatePicker.vue";
import ElDynamicKeyInput from "@/components/ElDynamicKeyInput.vue";
import ElOrderEditor from "@/components/ElOrderEditor.vue";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElRemoteSearchSelect from "@/components/ElRemoteSearchSelect.vue";
import ElTagsGroup from "@/components/ElTagsGroup.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

const props = defineProps({
  typeId: String,
});

defineExpose({
  onBeforeTabLeave: () => {
    const modified = list.value.some((item) => item.modified);
    if (modified) {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm(`尚有未保存的修改，確定離開嗎？`, "警告", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(resolve)
          .catch(reject);
      });
    }
    return Promise.resolve();
  },
});

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "order",
  order: "asc",
});
const searchParams = ref({
  name: "",
  status: "",
  brand_id: "",
  supplier_id: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const handleFetch = (type, query) => {
  loading.value = true;
  APIs.product
    .getProducts({
      page: query.page,
      per_page: query.per_page,
      relationships: ["tags", "brand", "supplier", "orders"],
      filters: helpers.convertSearchParams({
        ...constants.product.publishStatusFilters[searchParams.value.status],
        ...helpers.omit(searchParams.value, ["status"]),
        type,
      }),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = (res.result.data || []).map((item) =>
        helpers.dataConverter(item, {
          price: (val) => numeral(val).format(),
          quantity: (val) => numeral(val).format(),
          personal_limit: (val) => numeral(val).format(),
          start_at: (val) => dayjs(val).format(),
          end_at: (val) => dayjs(val).format(),
          created_at: (val) => dayjs(val).format(),
          tags: (val) => val.map((tag) => tag.name),
        }),
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const deleteProduct = (id) => {
  loading.value = true;
  APIs.product
    .deleteProduct(id)
    .then(() => handleFetch(props.typeId, params.value))
    .finally(() => {
      loading.value = false;
    });
};

const deleteProducts = () => {
  loading.value = true;
  Promise.all(
    multipleSelection.value.map((item) => APIs.product.deleteProduct(item.id)),
  )
    .then(() => handleFetch(props.typeId, params.value))
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(props.typeId, params.value);
});

onBeforeRouteUpdate(async (to, __, next) => {
  paginationParams.value.page = 1;
  handleFetch(to.params.typeId, params.value);
  next();
});
</script>

<template>
  <div>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem label="狀態:">
        <ElSelect v-model="searchParams.status" clearable>
          <ElOption
            v-for="(label, key) in constants.product.publishStatusLabels"
            :key="key"
            :label="label"
            :value="key"
          >
            <ElTag :type="constants.product.publishStatusColors[key]">
              {{ label }}
            </ElTag>
          </ElOption>
        </ElSelect>
      </ElFormItem>
      <div class="w-full" />
      <ElFormItem label="供應商:">
        <ElRemoteSearchSelect
          v-model="searchParams.supplier_id"
          :http-request="
            (query) =>
              APIs.supplier
                .getSuppliers({
                  filters: helpers.convertSearchParams({
                    name: `%${query}%`,
                    status: enums.common.status.ENABLED,
                  }),
                })
                .then((res) =>
                  res.result.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                  })),
                )
          "
        >
          <template #option="item">
            <div class="flex items-center">
              <img :src="item.image" class="w-5" />
              <span class="ml-2">{{ item.name }}</span>
            </div>
          </template>
        </ElRemoteSearchSelect>
      </ElFormItem>
      <ElFormItem label="品牌商:">
        <ElRemoteSearchSelect
          v-model="searchParams.brand_id"
          :http-request="
            (query) =>
              APIs.brand
                .getBrands({
                  filters: helpers.convertSearchParams({
                    name: `%${query}%`,
                    status: enums.common.status.ENABLED,
                  }),
                })
                .then((res) =>
                  res.result.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                  })),
                )
          "
        >
          <template #option="item">
            <div class="flex items-center">
              <img :src="item.image" class="w-5" />
              <span class="ml-2">{{ item.name }}</span>
            </div>
          </template>
        </ElRemoteSearchSelect>
      </ElFormItem>
      <ElFormItem>
        <ElDynamicKeyInput
          v-model:model-object="searchParams"
          :key-options="[
            { label: '上架時間', value: 'start_at' },
            { label: '下架時間', value: 'end_at' },
            { label: '建立時間', value: 'created_at' },
          ]"
        >
          <template #default="{ key, deleteKeyWhenValueIsEmpty }">
            <ElDatePicker
              v-model="searchParams[key]"
              type="daterange"
              @change="deleteKeyWhenValueIsEmpty"
            />
          </template>
        </ElDynamicKeyInput>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(typeId, params)">
          搜尋
        </ElButton>
      </ElFormItem>
    </ElForm>
    <div class="mb-3 flex justify-end gap-2">
      <RouterLink
        :to="{
          name: enums.route.names.PRODUCT,
          params: { id: enums.common.CREATE_MODE },
        }"
      >
        <ElButton type="success">＋新增</ElButton>
      </RouterLink>
      <ElButton
        type="danger"
        :disabled="
          !multipleSelection.length ||
          multipleSelection.length > constants.common.MAX_MULTIPLE_DELETE
        "
        @click="deleteProducts"
      >
        <span class="material-symbols-outlined">delete</span>&nbsp;刪除
      </ElButton>
    </div>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch(typeId, { ...params, page })"
      @page-size-change="
        (size) => handleFetch(typeId, { ...params, per_page: size })
      "
      @sort-change="
        (sort) =>
          handleFetch(typeId, {
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
      @selection-change="
        (selection) => {
          multipleSelection = selection;
        }
      "
    >
      <ElTableColumn type="selection" width="55" />
      <ElTableColumn prop="order" label="排序" width="90" sortable>
        <template #default="{ row }">
          <ElOrderEditor
            v-model="row.order"
            prompt-mode
            :http-request="
              (order) =>
                APIs.product
                  .updateProduct(row.id, { order })
                  .then(() => handleFetch(typeId, params))
            "
          />
        </template>
      </ElTableColumn>
      <ElTableColumn prop="name" label="商品名稱" min-width="200" />
      <ElTableColumn prop="image" label="商品圖片" min-width="100">
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <img :src="row.image" class="aspect-square w-10" />
            </template>
            <img :src="row.image" class="w-60" />
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="price" label="商品金額（Ｖ幣）" min-width="150" />
      <ElTableColumn
        prop="start_at"
        label="可兌換時間"
        sortable
        min-width="270"
        :formatter="({ start_at, end_at }) => start_at + ' ~ ' + end_at"
      />
      <ElTableColumn prop="status" label="狀態" min-width="100">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElPopover
              placement="top-start"
              title="狀態說明"
              trigger="hover"
              width="fit-content"
            >
              <template #reference>
                <button class="material-symbols-outlined">info</button>
              </template>
              <div class="grid grid-cols-[auto,1fr] items-center gap-1">
                <template
                  v-for="status in enums.product.publishStatus"
                  :key="status"
                >
                  <ElTag :type="constants.product.publishStatusColors[status]">
                    {{ constants.product.publishStatusLabels[status] }}
                  </ElTag>
                  <span class="whitespace-nowrap">
                    {{ constants.product.publishStatusDescription[status] }}
                  </span>
                </template>
              </div>
            </ElPopover>
          </div>
        </template>
        <template #default="{ row }">
          <ElTag
            :type="
              constants.product.publishStatusColors[
                utils.convertProductPublishStatus(row)
              ]
            "
          >
            {{
              constants.product.publishStatusLabels[
                utils.convertProductPublishStatus(row)
              ]
            }}
          </ElTag>
        </template>
      </ElTableColumn>
      <ElTableColumn
        prop="personal_limit"
        label="個人兌換上限"
        min-width="110"
      />
      <ElTableColumn prop="tags" label="角標" min-width="150">
        <template #default="{ row }">
          <ElTagsGroup
            :tags="
              row.tags.map((tagName) => ({
                name: tagName,
                color: constants.product.tagColors[tagName],
              }))
            "
            collapse
          />
        </template>
      </ElTableColumn>
      <ElTableColumn prop="brand.name" label="品牌商" min-width="150" />
      <ElTableColumn prop="supplier.name" label="供應商" min-width="150" />
      <ElTableColumn prop="created_at" label="建立時間" min-width="150" />

      <!-- TODO: 缺少欄位 -->
      <ElTableColumn prop="" label="已兌換次數" min-width="100" />

      <ElTableColumn prop="quantity" label="剩餘數量" min-width="100" />

      <!-- TODO: 缺少欄位 -->
      <ElTableColumn prop="" label="已使用數" min-width="100" />

      <ElTableColumn label="操作" min-width="110" fixed="right">
        <template #default="{ row }">
          <RouterLink
            :to="{ name: enums.route.names.PRODUCT, params: { id: row.id } }"
          >
            <ElButton type="primary" link>編輯</ElButton>
          </RouterLink>
          <ElButton
            type="danger"
            link
            @click="
              () => {
                ElMessageBox.confirm(`確定要刪除「${row.name}」嗎？`, '警告', {
                  confirmButtonText: '確定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => {
                    deleteProduct(row.id);
                  })
                  .catch(() => {});
              }
            "
          >
            刪除
          </ElButton>
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </div>
</template>
