export const types = {
  RECOMMEND: "RECOMMEND",
  DAILY: "DAILY",
  CHECK_IN: "CHECK_IN",
};

export const publishStatus = {
  /** 草稿 */
  DRAFT: 0,
  /** 待上線 */
  PENDING: 1,
  /** 已上線 */
  PUBLISHED: 2,
  /** 已結束 */
  EXPIRED: 3,
};
