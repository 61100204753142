import { defineStore } from "pinia";
import { ref } from "vue";

import * as APIs from "@/APIs";
import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import EventEmitter from "@/libs/event-emitter";
import * as helpers from "@/libs/helpers";

export const useTaskStore = defineStore("task", () => {
  const task = ref({
    id: "",
    type: enums.task.types.RECOMMEND,
    name: "",
    description: "",
    content: "",
    image: "",
    coin: 0,
    exp: 0,
    limit: 0,
    is_unlimit: false,
    start_at: null,
    expired_at: null,
    closed_at: null,
    status: enums.common.status.ENABLED,
    order: 1,
    brand_id: "",
    created_at: null,
    updated_at: null,
  });
  const brands = ref([]);
  const taskables = ref([]);

  const fetchData = (id) => {
    EventEmitter.emit(enums.emitter.events.TASK_FETCH_START, id);
    return APIs.task
      .getTask(id, {
        relationships: [
          "brands",
          "taskables",
          "exp",
          "coin",
          "videos",
          "clicks",
          "questions",
          "surveys",
        ],
      })
      .then(({ result }) => {
        if (result.brands?.length) brands.value = result.brands;
        if (result.taskables?.length)
          taskables.value = result.taskables?.map?.((item) => {
            const type = enums.taskable.taskableTypeMap[item.taskable_type];
            const taskableData = result[`${type}s`]?.find?.(
              (t) => t.id === item.taskable_id,
            );
            return {
              type,
              ...taskableData,
            };
          });
        task.value = helpers.dataConverter(result, {
          coin: (val) => val?.[0]?.value ?? 0,
          exp: (val) => val?.[0]?.value ?? 0,
          start_at: (value) => (value ? dayjs(value).format() : null),
          closed_at: (value) => (value ? dayjs(value).format() : null),
          expired_at: (value) => (value ? dayjs(value).format() : null),
          is_unlimit: (value) => !!value,
          brand_id: () => result.brands?.[0]?.id || "",
        });
        EventEmitter.emit(enums.emitter.events.TASK_FETCH_SUCCESS);
        return result;
      });
  };

  return { task, brands, taskables, fetchData };
});
