<script setup>
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElSwitch,
} from "element-plus";
import { computed, ref } from "vue";

import ColorBlockEditor from "@/components/ColorBlockEditor.vue";
import ElImageUpload from "@/components/ElImageUpload.vue";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";

const initOption = (payload = {}) => {
  return {
    id: payload?.id ?? helpers.getUniqueId(),
    content: payload?.content ?? "",
    image: payload?.image ?? "",
    survey_id: payload?.survey_id ?? "",
    order: payload?.order ?? 1,
  };
};

const formRef = ref();

const formData = ref({
  id: "",
  title: "",
  question: "",
  min_choices: 1,
  max_choices: 1,
  survey_options: Array(4)
    .fill(null)
    .map((__, index) => initOption({ order: index + 1 })),
});

const formRules = computed(() => ({
  title: { required: true, message: "請輸入題目" },
  question: { required: true, message: "請輸入題目說明" },
  min_choices: [
    { required: true, message: "請輸入最少需選題數" },
    {
      validator: (rule, value, callback) => {
        if (value > formData.value.max_choices)
          callback(new Error("不可大於最多可選題數"));
        else if (value < 1) callback(new Error("最少需選題數不可小於 1"));
        else callback();
      },
    },
  ],
  max_choices: [
    { required: true, message: "請輸入最多可選題數" },
    {
      validator: (rule, value, callback) => {
        if (value < 1) callback(new Error("最多可選題數不可小於 1"));
        else if (value < formData.value.min_choices)
          callback(new Error("不可小於最少需選題數"));
        else callback();
      },
    },
  ],
  survey_options: [
    { required: true, message: "請輸入選項" },
    {
      validator: (rule, value, callback) => {
        const availableOptions = value.filter?.((item) => item.content);
        if (availableOptions.length < formData.value.max_choices)
          callback(`至少需填寫 ${formData.value.max_choices} 題選項`);
        else callback();
      },
    },
  ],
}));

const isColorBlock = ref(false);

const validate = () => {
  return new Promise((resolve, reject) => {
    formRef.value.validate((valid, rules) => {
      if (valid) {
        resolve(formData.value);
      } else {
        reject(
          new Error(Object.values(rules)[0][0].message, {
            cause: "表單驗證錯誤",
          }),
        );
      }
    });
  });
};

const initFormData = (payload = {}) => {
  formData.value.id = payload?.id ?? "";
  formData.value.title = payload?.title ?? "";
  formData.value.question = payload?.question ?? "";
  formData.value.min_choices = payload?.min_choices ?? 0;
  formData.value.max_choices = payload?.max_choices ?? 0;
  formData.value.survey_options =
    payload?.survey_options?.map?.(initOption) ?? formData.value.survey_options;

  // 判斷是否使用顏色區塊
  isColorBlock.value = formData.value.survey_options?.every?.((item) =>
    utils.containsDomain(item.image, import.meta.env.VITE_PLACEHOLDER_IMAGE),
  );
};

defineExpose({
  validate,
  initFormData,
});
</script>

<template>
  <ElForm ref="formRef" :model="formData" :rules="formRules" label-width="90px">
    <ElFormItem prop="title" label="題目標題">
      <ElInput v-model="formData.title" />
    </ElFormItem>
    <ElFormItem prop="question" label="題目說明">
      <ElInput
        v-model="formData.question"
        type="textarea"
        :autosize="{ minRows: 3 }"
      />
    </ElFormItem>
    <ElFormItem prop="min_choices" label="最少需選">
      <div class="flex gap-1">
        <ElInputNumber
          v-model="formData.min_choices"
          :controls="false"
          :min="0"
          :max="formData.survey_options.length"
          step-strictly
          size="small"
        />
        <span>題</span>
      </div>
    </ElFormItem>
    <ElFormItem prop="max_choices" label="最多可選">
      <div class="flex gap-1">
        <ElInputNumber
          v-model="formData.max_choices"
          :controls="false"
          :min="0"
          :max="formData.survey_options.length"
          step-strictly
          size="small"
        />
        <span>題</span>
      </div>
    </ElFormItem>
    <ElFormItem label="顏色區塊">
      <ElSwitch
        v-model="isColorBlock"
        active-text="使用"
        inactive-text="不使用"
        @change="
          (value) => {
            if (!value) {
              formData.survey_options.forEach((item) => {
                item.image = '';
              });
            }
          }
        "
      />
    </ElFormItem>
    <ElFormItem prop="survey_options" label-width="0">
      <ElFormItem
        v-for="(item, i) in formData.survey_options"
        :key="i"
        :label="`題目${i + 1}`"
        class="w-full"
      >
        <div class="flex w-full flex-col gap-3">
          <template v-if="isColorBlock">
            <ColorBlockEditor v-model:src="item.image" :text="item.content" />
          </template>
          <template v-else>
            <ElImageUpload v-model="item.image" class="self-start">
              <template #trigger>
                <div
                  class="grid h-32 w-32 place-items-center text-xl text-primary"
                >
                  ＋
                </div>
              </template>
              <template #preview="{ src }">
                <div ratio="100%" class="aspect-square w-32">
                  <img :src="src" class="h-full w-full object-cover" />
                </div>
              </template>
            </ElImageUpload>
          </template>
          <ElFormItem :prop="`survey_options[${i}].content`" class="w-full">
            <ElInput
              v-model="item.content"
              placeholder="請輸入選項內容"
              :type="isColorBlock ? 'textarea' : 'text'"
              :autosize="{ minRows: 2 }"
            />
          </ElFormItem>
        </div>
      </ElFormItem>
    </ElFormItem>
  </ElForm>
</template>

<style lang="scss" scoped>
:deep(.el-form-item__error) {
  left: unset;
  right: 0;
}
</style>
