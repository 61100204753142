<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElMessage,
  ElMessageBox,
  ElPageHeader,
  ElTableColumn,
} from "element-plus";
import numeral from "numeral";
import { computed, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElPrompt from "@/components/ElPrompt.vue";
import SerialUploadPrompt from "@/components/Prize/SerialUploadPrompt.vue";
import * as enums from "@/enums";
import * as helpers from "@/libs/helpers";

const multipleSelection = ref([]);
const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  user_id: "",
  "content.note": "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

const nonWinningRate = ref(0);
const nonWinningRatePercentage = computed(() =>
  (nonWinningRate.value * 100).toFixed(1),
);

const serialUploadPromptRef = ref();

const createMode = ref(false);
const promptRef = ref();
const promptFormRef = ref();
const promptFormDataDefault = ref({
  name: "",
  probability: 0,
});
const promptFormRules = ref({
  name: [{ required: true, message: "請輸入名稱" }],
  probability: [
    { required: true, message: "請輸入機率" },
    {
      validator: (rule, value, callback) => {
        if (value < 0 || value > nonWinningRatePercentage.value) {
          callback(
            new Error(`需介於 0 ~ ${nonWinningRatePercentage.value} 之間`),
          );
        } else {
          callback();
        }
      },
    },
  ],
});

const handleFetch = async (query) => {
  loading.value = true;
  await APIs.prize
    .getPrizeGroups({
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams(searchParams.value),
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
      relationships: ["prizes.count"],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data;
    });
  await APIs.prize.getPrizeGroupNonWinningRate().then((res) => {
    nonWinningRate.value = res.result;
  });

  loading.value = false;
};

const openPromptDefault = (rowData) => {
  promptFormDataDefault.value.probability = rowData?.probability
    ? rowData?.probability * 100
    : 0;
  promptFormDataDefault.value.name = rowData?.name;
  return new Promise((resolve) => {
    promptRef.value
      .open({
        title: createMode.value ? "新增" : `修改${rowData?.name}機率`,
        beforeResolve: () =>
          promptFormRef.value
            .validate()
            .then(async () => {
              loading.value = true;
              const probability = numeral(
                promptFormDataDefault.value.probability,
              )
                .divide(100)
                .value();
              if (createMode.value) {
                await APIs.prize
                  .createPrizeGroup({
                    name: promptFormDataDefault.value.name,
                    probability,
                    status: enums.common.status.ENABLED,
                  })
                  .then(() => handleFetch(params.value));
              } else {
                await APIs.prize
                  .updatePrizeGroup(rowData?.id, {
                    name: promptFormDataDefault.value.name,
                    probability,
                  })
                  .then(() => {
                    const item = list.value.find(
                      (item) => item.id === rowData?.id,
                    );
                    item.probability = probability;
                    item.name = promptFormDataDefault.value.name;
                  });
              }
              return true;
            })
            .catch(() => false),
      })
      .then(() => {
        ElMessage.success("修改成功");
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      })
      .finally(() => {
        promptFormDataDefault.value.probability = 0;
        promptFormRef.value.resetFields();
        loading.value = false;
      });
  });
};

const deleteGroup = (id) => {
  loading.value = true;
  APIs.prize
    .deletePrizeGroup(id)
    .then(() => handleFetch(params.value))
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  handleFetch(params.value);
});
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: '培育間摸彩管理',
          to: { name: enums.route.names.PRIZES },
        },
        { label: $route.meta.title },
      ]"
    />
    <ElCard class="!rounded-xl">
      <ElPageHeader @back="$router.push({ name: enums.route.names.PRIZES })">
        <template #content>
          <h1 class="text-2xl font-bold">{{ $route.meta.title }}</h1>
        </template>
      </ElPageHeader>
      <div class="my-5 flex items-end justify-between">
        <div class="flex flex-col gap-2">
          <strong class="text-lg text-gray-600">
            銘謝惠顧 = {{ numeral(nonWinningRate).format("0,0.0%") }}
          </strong>
          <small class="text-gray-400">
            *機率相加須小於100%，設定的機率加總後剩餘數即為「銘謝惠顧」中獎率
          </small>
        </div>
        <ElButton
          :type="nonWinningRate <= 0 ? 'info' : 'success'"
          :disabled="nonWinningRate <= 0"
          @click="
            () => {
              createMode = true;
              openPromptDefault();
            }
          "
        >
          ＋新增
        </ElButton>
      </div>
      <ElPaginationTable
        :data="list"
        v-model:pagination="paginationParams"
        v-model:sort="sortParams"
        :loading="loading"
        paginationBackground
        @page-change="(page) => handleFetch({ ...params, page })"
        @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
        @sort-change="
          (sort) =>
            handleFetch({
              ...params,
              order_by: sort.prop,
              order_direction: sort.order,
            })
        "
        @selection-change="
          (selection) => {
            multipleSelection = selection;
          }
        "
      >
        <ElTableColumn prop="name" label="名稱" min-width="150" />
        <ElTableColumn
          prop="probability"
          label="機率"
          min-width="150"
          :formatter="({ probability }) => numeral(probability).format('0.0%')"
        />
        <ElTableColumn
          prop="prizes_count"
          label="剩餘數量"
          min-width="150"
          :formatter="({ prizes_count }) => numeral(prizes_count).format()"
        />
        <ElTableColumn label="操作" min-width="180" fixed="right">
          <template #default="{ row }">
            <ElButton
              type="warning"
              link
              @click="serialUploadPromptRef?.openPrompt?.(row)"
            >
              上傳序號
            </ElButton>
            <ElButton
              type="primary"
              link
              @click="
                () => {
                  createMode = false;
                  openPromptDefault(row);
                }
              "
            >
              編輯
            </ElButton>
            <ElButton
              type="danger"
              link
              @click="
                () => {
                  ElMessageBox.confirm(
                    `確定要刪除「${row.name}」嗎？`,
                    '警告',
                    {
                      confirmButtonText: '確定',
                      cancelButtonText: '取消',
                      type: 'warning',
                    },
                  )
                    .then(() => deleteGroup(row.id))
                    .catch(() => {});
                }
              "
            >
              刪除
            </ElButton>
          </template>
        </ElTableColumn>
      </ElPaginationTable>
    </ElCard>
    <ElPrompt ref="promptRef" width="500">
      <ElForm
        ref="promptFormRef"
        :model="promptFormDataDefault"
        :rules="promptFormRules"
        label-position="top"
        @submit.prevent
      >
        <ElFormItem prop="name" label="名稱">
          <ElInput
            v-model="promptFormDataDefault.name"
            placeholder="請輸入獎項名稱"
          />
        </ElFormItem>
        <ElFormItem prop="probability" label="修改機率">
          <ElInputNumber
            v-model="promptFormDataDefault.probability"
            placeholder="請輸入欲調整之機率"
            :controls="false"
            :max="100"
            :min="0"
            :precision="1"
          />%
        </ElFormItem>
      </ElForm>
    </ElPrompt>
    <SerialUploadPrompt ref="serialUploadPromptRef" />
  </div>
</template>
