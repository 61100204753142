import { request, useErrorHandler } from "@/libs/axios";
import * as helpers from "@/libs/helpers";

/**
 * 取得訊息列表
 * @param {PaginationParams} params
 */
export const getMessages = function (params) {
  return useErrorHandler(
    request({
      url: "/messages",
      method: "GET",
      params,
    }),
  );
};

/**
 * 取得單一訊息
 * @param {string} id 訊息ID
 * @param {Object} [params]
 */
export const getMessage = function (id, params) {
  return useErrorHandler(
    request({
      url: `/messages/${id}`,
      method: "GET",
      params,
    }),
  );
};

/**
 * 新增訊息
 * @param {Object} data
 */
export const createMessage = function (data) {
  return useErrorHandler(
    request({
      url: "/messages",
      method: "POST",
      data: helpers.convertToFormData(data),
    }),
  );
};

/**
 * 更新訊息
 * @param {string} id 訊息ID
 * @param {Object} data
 */
export const updateMessage = function (id, data) {
  return useErrorHandler(
    request({
      url: `/messages/${id}`,
      method: "POST",
      data: helpers.convertToFormData(data),
    }),
  );
};

/**
 * 刪除訊息
 * @param {string} id 訊息ID
 */
export const deleteMessage = function (id) {
  return useErrorHandler(
    request({
      url: `/messages/${id}`,
      method: "DELETE",
    }),
  );
};
