/** Dependencies */
import axios from "axios";
import { ElNotification } from "element-plus";
import { ref } from "vue";

import { deleteCookie, getCookie } from "@/libs/cookie";

/**
 * @param {import('axios').CreateAxiosDefaults} config
 * @returns
 */
const createService = (config) => {
  // create an axios instance
  const axiosInstance = axios.create(config);

  // request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = getCookie("token");
      return config;
    },
    (error) => {
      ElNotification.error({
        title: "錯誤",
        message: error.message,
      });
      return Promise.reject(error);
    },
  );

  // response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const response = error.response;
      if (response?.status === 401) {
        deleteCookie("token");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export const defaultService = createService({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
});

/**
 * @param {ReturnType<typeof createService>} service
 * @param {string} token
 */
export const setAuthorization = function (service, token) {
  if (token) service.defaults.headers.Authorization = token;
  else delete service.defaults.headers.Authorization;
};

/**
 * @param {ReturnType<typeof defaultRequest>} req
 * @typedef {object} FetchResult
 * @property {boolean} pending
 * @property {object} data
 * @property {string} status
 * @property {string} message
 * @property {object} error
 * @property {(onfulfilled?: (value: any) => any, onrejected?: (reason: any) => any) => Promise<any>} then
 * @property {(onrejected?: (reason: any) => any) => Promise<any>} catch
 * @property {(onfinally?: () => void) => Promise<any>} finally
 * @returns {FetchResult}
 */
export const useFetch = (req) => {
  const pending = ref(true);
  const data = ref();
  const status = ref();
  const statusCode = ref();
  const message = ref();
  const error = ref();

  req
    .then((res) => {
      pending.value = true;
      data.value = res.result;
      status.value = res.status;
      statusCode.value = res.statusCode;
      message.value = res.message;
    })
    .catch((err) => {
      error.value = err;
    })
    .finally(() => {
      setTimeout(() => {
        pending.value = false;
      }, 500);
    });

  return {
    pending,
    data,
    status,
    message,
    error,
    then: req.then.bind(req),
    catch: req.catch.bind(req),
    finally: req.finally.bind(req),
  };
};

/**
 * @param {Promise} request
 * @param {Object} options
 * @param {boolean} [options.disableNotification]
 * @return {Promise<{
 *   message: string,
 *   status: string,
 *   statusCode: number,
 *   result: object
 * }>}
 */
export const useErrorHandler = (request, options) => {
  return request.catch((error) => {
    const response = error.response;

    if (response?.status === 401) {
      ElNotification.error({
        title: "無法執行",
        message: "請先登入",
      });
    } else if (response?.status === 403) {
      ElNotification.error({
        title: "權限不足",
        message: "請聯絡管理員",
      });
    } else if (!options?.disableNotification) {
      ElNotification.error({
        title: "錯誤",
        message: response?.data?.message ?? error.message,
      });
    }

    return Promise.reject(error);
  });
};

/**
 * @param {import('axios').AxiosRequestConfig} config
 *
 * @return {Promise<{
 *  message: string,
 *  status: string,
 *  statusCode: number,
 *  result: object
 * }>}
 */
export const request = function (config) {
  return defaultService(config).then((res) => res.data);
};
